import React, { useState, useEffect } from "react";

import moment from "moment";
import Axios from "axios";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Iconoanimal from "../componentes/iconoanimal";
import Ticket from "../componentes/ticket";

const Formapuesta = ({ tipo2, addmensaje, arreglopasado, activarchequeo }) => {
  const [codigoanimal, setCodigoAnimal] = useState("");
  const [configlo, setConfiglo] = useState([]);
  const [ventadiaria, setVentadiaria] = useState([{ id: 0 }]);
  const [estatuschequeo, setEstatuschequeo] = useState(false);

  const [actualizarticket, setActualizarTicket] = useState("1");

  const msjsave = (mensajesave, tipodemensaje) => {
    if (tipodemensaje == "save") {
      toast.success(mensajesave, {});
    }
    if (tipodemensaje == "error") {
      toast.error(mensajesave, {});
    }
    if (tipodemensaje == "warning") {
      toast.warning(mensajesave, {});
    }
    if (tipodemensaje == "info") {
      toast.info(mensajesave, {});
    }
  };

  //   $("#txtcodigo").on("keyup", function (e) {
  //     var keycode = e.keyCode || e.which;
  //     if (keycode == 13) {
  //       document.getElementById("monto").focus();
  //       keycode = null;
  //       limpiarCampos();
  //     }
  //   });

  const enviarmensaje = () => {
    addmensaje("ESTE MENSAJE DEBE MOSTRARSE");
  };

  

  const obtenerminutosap = (horapasada) => {
    //TOMAR HORA ACTUAL
    var tomarfecha = "";
    tomarfecha = "" + moment.utc().format();
    tomarfecha = tomarfecha.replace("Z", "");
    var date3 = new Date(tomarfecha);
    date3.setHours(date3.getHours() - 4);
    //FIN DE HORA ACTUAL RESULT EN date3

    var dia;
    console.log(date3.getDate());

    if (parseInt(date3.getDate()) > 0 && parseInt(date3.getDate()) < 10) {
      dia = "0" + date3.getDate();
    } else {
      dia = date3.getDate();
    }

    //TOMA LA FECHA EN FORMATO YYYY-MM-DD
    var mes;
    if (date3.getMonth() + 1 > 9) {
      var date4 =
        date3.getFullYear() + "-" + (date3.getMonth() + 1) + "-" + dia;
    } else {
      mes = "0" + (date3.getMonth() + 1);
      var date4 = date3.getFullYear() + "-" + mes + "-" + dia;
    }
    //LA FECHA EN FORMATO YYYY-MM-DD GUARDADA EN LA VARIABLE data4

    //meter la hora en la segunda fecha
    var hora = horapasada;
    var date5 = new Date(date4 + "T" + hora);
    //CALCULANDO LOS MINUTOS QUE FALTAN
    var c = (date5 - date3) / 1000 / 60;

    //FIN DE CALCULO
    return Math.trunc(c);
  };

  const obtenerfecha = () => {
    //TOMAR HORA ACTUAL
    var tomarfecha = "";
    tomarfecha = "" + moment.utc().format();
    tomarfecha = tomarfecha.replace("Z", "");
    var date3 = new Date(tomarfecha);
    date3.setHours(date3.getHours() - 4);
    //FIN DE HORA ACTUAL RESULT EN date3

    var dia;

    if (parseInt(date3.getDate()) > 0 && parseInt(date3.getDate()) < 10) {
      dia = "0" + date3.getDate();
    } else {
      dia = date3.getDate();
    }

    //TOMA LA FECHA EN FORMATO YYYY-MM-DD
    var mes;
    if (date3.getMonth() + 1 > 9) {
      var date4 =
        date3.getFullYear() + "-" + (date3.getMonth() + 1) + "-" + dia;
    } else {
      mes = "0" + (date3.getMonth() + 1);
      var date4 = date3.getFullYear() + "-" + mes + "-" + dia;
    }
    //LA FECHA EN FORMATO YYYY-MM-DD GUARDADA EN LA VARIABLE data4

    return date4;
  };

  //ANIMA LA TABLA CUANDO NO SELECCIONA
  const animartabla = () => {
    setTimeout(function () {
      document.getElementById("fondolote").className =
        "pl-2 font-semibold text-blue-950 bg-red-500 w-full text-sm";
    }, 200);

    setTimeout(function () {
      document.getElementById("fondolote").className =
        "pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm";
    }, 400);

    setTimeout(function () {
      document.getElementById("fondolote").className =
        "pl-2 font-semibold text-blue-950 bg-red-500 w-full text-sm";
    }, 600);

    setTimeout(function () {
      document.getElementById("fondolote").className =
        "pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm";
    }, 800);

    setTimeout(function () {
      document.getElementById("fondolote").className =
        "pl-2 font-semibold text-blue-950 bg-red-500 w-full text-sm";
    }, 1000);

    setTimeout(function () {
      document.getElementById("fondolote").className =
        "pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm";
    }, 1200);

    setTimeout(function () {
      document.getElementById("fondolote").className =
        "pl-2 font-semibold text-blue-950 bg-red-500 w-full text-sm";
    }, 1400);

    setTimeout(function () {
      document.getElementById("fondolote").className =
        "pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm";
    }, 1600);

    setTimeout(function () {
      document.getElementById("fondolote").className =
        "pl-2 font-semibold text-blue-950 bg-red-500 w-full text-sm";
    }, 1800);

    setTimeout(function () {
      document.getElementById("fondolote").className =
        "pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm";
    }, 2000);
  };

  //PROCESO DE AGREGAR APUESTA
  //PASO 1: CHEQUEA SI HAY SELECCION, SI LA LOTERIA SE CERRO
  const chequearlote = () => {
    var hayseleccion = 0;
    console.log("ARREGLO PASADO");
    console.log(arreglopasado);
    for (let i = 0; i < arreglopasado.length; i++) {
      //COMPROBAR QUE EL ESTATUS DEL ARREGLO ESTA DESACTIVADO(0)
      if (arreglopasado[i].estatus == 0) {
      } else {
        //DESACTIVAR ESTILOS DE ERRORES
        document.getElementById("loter" + arreglopasado[i].id).className =
          "text-blue-950 font-semibold";
        document.getElementById("iconw" + arreglopasado[i].id).className = "";
        document.getElementById("iconw" + arreglopasado[i].id).title = "";
        document.getElementById("iconw" + arreglopasado[i].id).innerHTML = "";
        //PROCESA LA INFORMACION
        if (obtenerminutosap(arreglopasado[i].hora) < 5) {
          if (arreglopasado[i].estatus == 1) {
            //SI SE CUMPLE EL CONDICIONAL MENOR A 5MIN LO MARCA ROJO
            document.getElementById("loter" + arreglopasado[i].id).className =
              "text-red-500  font-semibold";
          }
        } else {
          //
          if (
            document.getElementById("loteria" + arreglopasado[i].id).checked
          ) {
            hayseleccion = hayseleccion + 1;
            console.log(
              document.getElementById("loteria" + arreglopasado[i].id).value
            );
            console.log(arreglopasado[i].id);
          }
        }
      }
      console.log(tipo2);
    }

    if (hayseleccion == 0) {
      animartabla();
    } else {
      verificarlaapuesta();
    }
  };
  //PASO 2: ACTUALIZA LAS VENTAS DEL DIA
  const verificarlaapuesta = () => {
    setEstatuschequeo(true);
    setVentadiaria({ id: 0 });
    //INICIO DE BUSQUEDA DE CUPOS
    var miapuesta = document.getElementById("txtcodigo").value;
    var wfecha = obtenerfecha();

    if (miapuesta.length > 1) {
      //SI ES MAYOR NO ES NECESARIO Y PASA AL SIGUIENTE CONDICIONAL
    } else {
      if (parseInt(miapuesta) > 0 && parseInt(miapuesta) < 10) {
        document.getElementById("txtcodigo").value = "0" + miapuesta;
        setCodigoAnimal(document.getElementById("txtcodigo").value); //IMPRIME EL CERO CON EL NUMERO ANTERIOR
      }
    }

    var wcodigo = document.getElementById("txtcodigo").value;
    const data = {
      codigo: wcodigo,
      fecha: wfecha,
      idagencia: localStorage.getItem("veloci"),
    };

    // enviar datos
    fetch(`${process.env.REACT_APP_API}/cuposdisponibles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("CHEQUEANDO LA VENTA");
        console.log(result);
        setVentadiaria(result);
      })
      .catch((error) => {
        console.log(error);
      });

    //fin de envio
  };
  //PASO 3: AL ACTUALIZAR LAS VENTAS SE EJECUTAN LO SIGUIENTE
  const verificatodo = () => {
    if (ventadiaria.length == 0) {
      chequearvalidaciones();
    } else {
      if (ventadiaria.id == 0) {
      } else {
        chequearvalidaciones();
      }
    }
  };
  //PASO 4: CHEQUEA LAS VALIDACIONES DE JUGADA
  const chequearvalidaciones = () => {
    // INICIO DEL CHEQUEO
    //////////////////////////////////
    var miapuesta = document.getElementById("txtcodigo").value;
    var codigot = "";
    //CHEQUEO DE CAMPO DE APUESTA CODIGO ANIMALITOS
    //ANTES DE REALIZAR LA APUESTA
    if (tipo2 == "ANIMALITOS") {
      //VERIFICA LA CANTIDAD DE CARACTERES Y SI ES 1 AGREGA EL 0 AL COMIENZO PARA COMPLETAR
      if (miapuesta.length > 1) {
        //SI ES MAYOR NO ES NECESARIO Y PASA AL SIGUIENTE CONDICIONAL
      } else {
        if (parseInt(miapuesta) > 0 && parseInt(miapuesta) < 10) {
          document.getElementById("txtcodigo").value = "0" + miapuesta;
          setCodigoAnimal(document.getElementById("txtcodigo").value); //IMPRIME EL CERO CON EL NUMERO ANTERIOR
        }
      }
      //FIN DE LA VERIFICACION DE CARACTERES
      if (miapuesta == "") {
        // SI NO HA ESCRITO NADA, MUESTRA QUE DEBE INGRESAR DATOS
        msjsave("Debes Escojer tu apuesta", "warning");
      } else {
        if (parseInt(miapuesta) > 75) {
          // SI ES MAYOR DE 75 SE SOBRE PASA A LA CANTIDAD DE ANIMALES QUE HAY
          msjsave("Rango de Animales (0-75)", "error");
        } else {
          if (miapuesta.length > 2) {
            if (parseInt(miapuesta) > 0 && parseInt(miapuesta) < 10) {
              document.getElementById("txtcodigo").value =
                "0" + parseInt(miapuesta);
              setCodigoAnimal(document.getElementById("txtcodigo").value);
              realizarapuesta();
            } else {
              document.getElementById("txtcodigo").value = parseInt(miapuesta);
              setCodigoAnimal(document.getElementById("txtcodigo").value);
              console.log("CORRECTO REDUCIENDO LOS NUMEROS A 2"); //
              realizarapuesta();
            }
          } else {
            console.log("CORRECTO"); //
            realizarapuesta();
          }
        }
      }
    }
    //FIN DEL CHEQUEO

    //CHEQUE DE TERMINALITO
    if (tipo2 == "TERMINALITO") {
      if (miapuesta == "") {
        //COMPRUEBA SI HA INGRESADO UN VALOR
        msjsave("Debes ingresar un Numero para Apostar", "error");
      } else {
        //VERIFICA LA CANTIDAD DE CARACTERES ES SUPERIOR o inferior A DOS CARACTERES
        if (miapuesta.length > 2 || miapuesta.length < 2) {
          msjsave("El Terminal corresponde a 2 Digitos", "error");
        } else {
          if (parseInt(miapuesta) > -1 && parseInt(miapuesta) < 100) {
            // VERIFICA QUE EL TERMINAL ESTE ENTRE 0 Y 99
            console.log("CORRECTO EL TERMINAL");
            realizarapuesta();
          } else {
            msjsave("Valor Incorrecto", "error");
          }
        }
      }
    }
    //fin de verifiacacion de terminal
    //cheque de TRIPLE
    if (tipo2 == "TRIPLE") {
      if (miapuesta == "") {
        //COMPRUEBA SI HA INGRESADO UN VALOR
        msjsave("Debes ingresar un Numero para Apostar", "error");
      } else {
        //VERIFICA LA CANTIDAD DE CARACTERES ES SUPERIOR o inferior A 3 CARACTERES
        if (miapuesta.length > 3 || miapuesta.length < 3) {
          msjsave("El Triple corresponde a 3 Digitos", "error");
        } else {
          if (parseInt(miapuesta) > -1 && parseInt(miapuesta) < 1000) {
            // VERIFICA QUE EL TERMINAL ESTE ENTRE 000 Y 990
            console.log("CORRECTO EL TRIPLE");
            realizarapuesta();
          } else {
            msjsave("Valor Incorrecto", "error");
          }
        }
      }
    }
    //FIN DE CHEQUEO DE TRIPLE
    //////////////////////////////////////
  };
  // PASO 5: CHEQUEA LOS LIMITES Y REALIZA LA APUESTA
  const realizarapuesta = () => {
    if (estatuschequeo == true) {
      const username = localStorage.getItem("velocy");
      var wloteria = "";
      var widloteria = 0;
      var widhorario = 0;
      var wtipo = tipo2;
      var wcodigo = document.getElementById("txtcodigo").value;
      var wproporcion = 0;
      var wmonto = document.getElementById("monto").value;
      var whora = "00:00:00";
      var wfecha = obtenerfecha();

      if (parseInt(wmonto) > 0) {
        //REVISAR EL ARREGLO
        for (let i = 0; i < arreglopasado.length; i++) {
          if (
            obtenerminutosap(arreglopasado[i].hora) < 5 &&
            arreglopasado[i].estatus == 1
          ) {
            document.getElementById("loter" + arreglopasado[i].id).className =
              "text-red-500  font-semibold";
          } else {
            if (arreglopasado[i].estatus == 1) {
              if (
                document.getElementById("loteria" + arreglopasado[i].id).checked
              ) {
                if (
                  limiteloteria(
                    arreglopasado[i].id,
                    arreglopasado[i].idloteria
                  ) == false
                ) {
                  widhorario = arreglopasado[i].id;
                  widloteria = arreglopasado[i].idloteria;
                  wloteria = arreglopasado[i].name;
                  wproporcion = arreglopasado[i].proporcion;
                  whora = arreglopasado[i].hora;

                  const data = {
                    username: username,
                    idagencia: localStorage.getItem("veloci"),
                    codigo: wcodigo,
                    tipo: wtipo,
                    loteria: wloteria,
                    proporcion: wproporcion,
                    idloteria: widloteria,
                    idhorario: widhorario,
                    monto: wmonto,
                    hora: whora,
                    fecha: wfecha,
                  };
                  //enviar datos
                  fetch(`${process.env.REACT_APP_API}/ticketnuevo`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      console.log(result);

                      if (result) {
                        console.log("SUS DATOS HAN SIDO GUARDADOS");
                      } else {
                        console.log("ERROR DE CONEXION");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  //fin de envio
                }
              }
            }
          }

          if (actualizarticket == "1") {
            setActualizarTicket("0");
          } else {
            setActualizarTicket("1");
          }

          setfocusonmonto();
        }
      } else {
        msjsave("Debes Ingresar el Monto Correcto", "error");
      }
      //FIN DEL REVISO
      setEstatuschequeo(false);
    }
  };
  //PASO 6: CHEQUEA LOS LIMITES MIN, MAX, CUPOS
  const limiteloteria = (id, idloteria, cupos) => {
    var titulo = "";
    var cuposdisponibles = 0;
    var hayerror = false;
    let lmonto = document.getElementById("monto").value;
    var codigo = document.getElementById("txtcodigo").value;
    var idagencia = localStorage.getItem("veloci");

    for (let i = 0; i < configlo.length; i++) {
      if (idloteria == configlo[i].idloteria) {
        if (parseInt(lmonto) < parseInt(configlo[i].min_jugada)) {
          titulo = "Jugada Minima es: " + configlo[i].min_jugada;
          hayerror = true;
        }
        if (parseInt(lmonto) > parseInt(configlo[i].max_jugada)) {
          titulo = "Jugada Maxima es: " + configlo[i].max_jugada;
          hayerror = true;
        }
        for (let i = 0; i < ventadiaria.length; i++) {
          if (
            id == ventadiaria[i].idhorario &&
            codigo == ventadiaria[i].codigo &&
            idagencia == ventadiaria[i].idagencia
          ) {
            console.log("REALIZANDO APUESTA EN: " + ventadiaria[i].id);
            cuposdisponibles =
              cuposdisponibles + parseInt(ventadiaria[i].monto);
          }
        }
        cuposdisponibles =
          parseInt(configlo[i].limite_cupos) - parseInt(cuposdisponibles);
      }
    }

    if (parseInt(lmonto) > parseInt(cuposdisponibles)) {
      titulo = "Cupos Disponibles: " + cuposdisponibles;
      hayerror = true;
    }

    //FIN DE BUSQUEDA DE CUPOS

    if (hayerror == true) {
      document.getElementById("loter" + id).className =
        "text-blue-500 font-semibold";

      document.getElementById("iconw" + id).innerHTML =
        '<svg class="w-6 h-6 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.008-3.018a1.502 1.502 0 0 1 2.522 1.159v.024a1.44 1.44 0 0 1-1.493 1.418 1 1 0 0 0-1.037.999V14a1 1 0 1 0 2 0v-.539a3.44 3.44 0 0 0 2.529-3.256 3.502 3.502 0 0 0-7-.255 1 1 0 0 0 2 .076c.014-.398.187-.774.48-1.044Zm.982 7.026a1 1 0 1 0 0 2H12a1 1 0 1 0 0-2h-.01Z" clip-rule="evenodd"/></svg>';
      document.getElementById("iconw" + id).title = titulo;
    }

    return hayerror;
  };
  //OBTIENE LOS DATOS DE CONFIGURACION DE LOTERIAS
  const obtenerconfiglo = () => {
    const data = {
      wid: localStorage.getItem("veloci"),
    };

    //enviar datos
    fetch(`${process.env.REACT_APP_API}/obtenerconfiglo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setConfiglo(result);
          console.log(result);
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  const limpiarCampos = () => {
    setCodigoAnimal("");
  };

  const setfocusonnumero = () => {
    document.getElementById("monto").focus();
    document.getElementById("monto").select();
  };

  const setfocusonmonto = () => {
    document.getElementById("txtcodigo").focus();
    document.getElementById("txtcodigo").select();
  };

  useEffect(() => {
    console.log(arreglopasado);
    chequearlote();
    obtenerconfiglo();
  }, [activarchequeo]);

  useEffect(() => {
    verificatodo();
    console.log(ventadiaria);
  }, [ventadiaria]);

  if (tipo2 === "ANIMALITOS") {
    return (
      <>
        <div className="flex flex-col">
          <span className="text-xl pt-4 pl-0 font-bold text-blue-950">
            Realiza tu Apuesta
          </span>
          <div className="flex flex-row">
            <label
              htmlFor="cliente_dni"
              className="bmd-label-floating text-sm mt-[5px]"
            >
              Codigo o Numero
            </label>
            <input
              type="number"
              class="bg-gray-50 ml-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-18 h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="txtcodigo"
              requerid="true"
              min="0"
              max="75"
              value={codigoanimal}
              onChange={(event) => {
                setCodigoAnimal(event.target.value);
              }}
              onFocus={(event) => {
                setCodigoAnimal(event.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") setfocusonnumero();
              }}
            />
            <label
              htmlFor="cliente_dni"
              className="bmd-label-floating  text-sm mt-[5px] ml-4"
            >
              Monto (Bs)
            </label>
            <input
              type="number"
              class="bg-gray-50 ml-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-18 h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="monto"
              requerid="true"
              min="0"
              max="10000"
              onKeyDown={(e) => {
                if (e.key === "Enter") enviarmensaje();
              }}
            />
            <button
              type="button"
              onClick={enviarmensaje}
              class="text-white ml-1 bg-[#2ba345] hover:bg-[#2ba345]/90 focus:ring-4 focus:outline-none focus:ring-[#2ba345]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#2ba345]/55 me-2 mb-2"
            >
              <svg
                class="w-6 h-6 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M12 14a3 3 0 0 1 3-3h4a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-4a3 3 0 0 1-3-3Zm3-1a1 1 0 1 0 0 2h4v-2h-4Z"
                  clip-rule="evenodd"
                />
                <path
                  fill-rule="evenodd"
                  d="M12.293 3.293a1 1 0 0 1 1.414 0L16.414 6h-2.828l-1.293-1.293a1 1 0 0 1 0-1.414ZM12.414 6 9.707 3.293a1 1 0 0 0-1.414 0L5.586 6h6.828ZM4.586 7l-.056.055A2 2 0 0 0 3 9v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2h-4a5 5 0 0 1 0-10h4a2 2 0 0 0-1.53-1.945L17.414 7H4.586Z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <Iconoanimal codigo={codigoanimal} />
          </div>
          <ToastContainer />

          <Ticket chequeoticket={actualizarticket} />
        </div>
      </>
    );
  }

  if (tipo2 === "TERMINALITO") {
    return (
      <>
        <div className="flex flex-col">
          <span className="text-xl pt-4 pl-0 font-bold text-blue-950">
            Realiza tu Apuesta
          </span>
          <div className="flex flex-row">
            <label
              htmlFor="cliente_dni"
              className="bmd-label-floating text-sm mt-[5px]"
            >
              Codigo o Numero
            </label>
            <input
              type="number"
              class="bg-gray-50 ml-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-18 h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="txtcodigo"
              requerid="true"
              min="0"
              max="99"
              value={codigoanimal}
              onChange={(event) => {
                setCodigoAnimal(event.target.value);
              }}
              onFocus={(event) => {
                setCodigoAnimal(event.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") setfocusonnumero();
              }}
            />
            <label
              htmlFor="cliente_dni"
              className="bmd-label-floating  text-sm mt-[5px] ml-4"
            >
              Monto (Bs)
            </label>
            <input
              type="number"
              class="bg-gray-50 ml-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-18 h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="monto"
              requerid="true"
              min="0"
              max="10000"
              onKeyDown={(e) => {
                if (e.key === "Enter") enviarmensaje();
              }}
            />
            <button
              type="button"
              onClick={enviarmensaje}
              class="text-white ml-1 bg-[#2ba345] hover:bg-[#2ba345]/90 focus:ring-4 focus:outline-none focus:ring-[#2ba345]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#2ba345]/55 me-2 mb-2"
            >
              <svg
                class="w-6 h-6 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M12 14a3 3 0 0 1 3-3h4a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-4a3 3 0 0 1-3-3Zm3-1a1 1 0 1 0 0 2h4v-2h-4Z"
                  clip-rule="evenodd"
                />
                <path
                  fill-rule="evenodd"
                  d="M12.293 3.293a1 1 0 0 1 1.414 0L16.414 6h-2.828l-1.293-1.293a1 1 0 0 1 0-1.414ZM12.414 6 9.707 3.293a1 1 0 0 0-1.414 0L5.586 6h6.828ZM4.586 7l-.056.055A2 2 0 0 0 3 9v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2h-4a5 5 0 0 1 0-10h4a2 2 0 0 0-1.53-1.945L17.414 7H4.586Z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
          <ToastContainer />

          <Ticket chequeoticket={actualizarticket} />
        </div>
      </>
    );
  }

  if (tipo2 === "TRIPLE") {
    return (
      <>
        <div className="flex flex-col">
          <span className="text-xl pt-4 pl-0 font-bold text-blue-950">
            Realiza tu Apuesta
          </span>
          <div className="flex flex-row">
            <label
              htmlFor="cliente_dni"
              className="bmd-label-floating text-sm mt-[5px]"
            >
              Codigo o Numero
            </label>
            <input
              type="number"
              class="bg-gray-50 ml-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-18 h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="txtcodigo"
              requerid="true"
              min="0"
              max="999"
              value={codigoanimal}
              onChange={(event) => {
                setCodigoAnimal(event.target.value);
              }}
              onFocus={(event) => {
                setCodigoAnimal(event.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") setfocusonnumero();
              }}
            />
            <label
              htmlFor="cliente_dni"
              className="bmd-label-floating  text-sm mt-[5px] ml-4"
            >
              Monto (Bs)
            </label>
            <input
              type="number"
              class="bg-gray-50 ml-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-18 h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="monto"
              requerid="true"
              min="0"
              max="10000"
              onKeyDown={(e) => {
                if (e.key === "Enter") enviarmensaje();
              }}
            />
            <button
              type="button"
              onClick={enviarmensaje}
              class="text-white ml-1 bg-[#2ba345] hover:bg-[#2ba345]/90 focus:ring-4 focus:outline-none focus:ring-[#2ba345]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#2ba345]/55 me-2 mb-2"
            >
              <svg
                class="w-6 h-6 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M12 14a3 3 0 0 1 3-3h4a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-4a3 3 0 0 1-3-3Zm3-1a1 1 0 1 0 0 2h4v-2h-4Z"
                  clip-rule="evenodd"
                />
                <path
                  fill-rule="evenodd"
                  d="M12.293 3.293a1 1 0 0 1 1.414 0L16.414 6h-2.828l-1.293-1.293a1 1 0 0 1 0-1.414ZM12.414 6 9.707 3.293a1 1 0 0 0-1.414 0L5.586 6h6.828ZM4.586 7l-.056.055A2 2 0 0 0 3 9v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2h-4a5 5 0 0 1 0-10h4a2 2 0 0 0-1.53-1.945L17.414 7H4.586Z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
          <ToastContainer />

          <Ticket chequeoticket={actualizarticket} />
        </div>
      </>
    );
  }
};

export default Formapuesta;
