import React, { useState } from "react";

const Iconoanimal = (props) => {
  const numeros = [
    "0",
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "57",
    "58",
    "59",
    "60",
    "61",
    "62",
    "63",
    "64",
    "65",
    "66",
    "67",
    "68",
    "69",
    "70",
    "71",
    "72",
    "73",
    "74",
    "75",
  ];

  var icono = "";
  if (numeros.includes(props.codigo) == true) {
    if (props.codigo == "0") {
      icono = "Delfin";
    }
    if (props.codigo == "00") {
      icono = "Ballena";
    }
    if (props.codigo == "01") {
      icono = "Camero";
    }
    if (props.codigo == "02") {
      icono = "Toro";
    }
    if (props.codigo == "03") {
      icono = "Ciempies";
    }
    if (props.codigo == "04") {
      icono = "Alacran";
    }
    if (props.codigo == "05") {
      icono = "Leon";
    }
    if (props.codigo == "06") {
      icono = "Rana";
    }
    if (props.codigo == "07") {
      icono = "Perico";
    }
    if (props.codigo == "08") {
      icono = "Raton";
    }
    if (props.codigo == "09") {
      icono = "Aguila";
    }
    if (props.codigo == "10") {
      icono = "Tigre";
    }
    if (props.codigo == "11") {
      icono = "Gato";
    }
    if (props.codigo == "12") {
      icono = "Caballo";
    }
    if (props.codigo == "13") {
      icono = "Mono";
    }
    if (props.codigo == "14") {
      icono = "Paloma";
    }
    if (props.codigo == "15") {
      icono = "Zorro";
    }
    if (props.codigo == "16") {
      icono = "Oso";
    }
    if (props.codigo == "17") {
      icono = "Pavo";
    }
    if (props.codigo == "18") {
      icono = "Burro";
    }
    if (props.codigo == "19") {
      icono = "Chivo";
    }
    if (props.codigo == "20") {
      icono = "Cochino";
    }

    if (props.codigo == "21") {
      icono = "Gallo";
    }

    if (props.codigo == "22") {
      icono = "Camello";
    }

    if (props.codigo == "23") {
      icono = "Zebra";
    }

    if (props.codigo == "24") {
      icono = "Iguana";
    }

    if (props.codigo == "25") {
      icono = "Gallina";
    }

    if (props.codigo == "26") {
      icono = "Vaca";
    }

    if (props.codigo == "27") {
      icono = "Perro";
    }

    if (props.codigo == "28") {
      icono = "Zamuro";
    }

    if (props.codigo == "29") {
      icono = "Elefante";
    }

    if (props.codigo == "30") {
      icono = "Caiman";
    }

    if (props.codigo == "31") {
      icono = "Lapa";
    }

    if (props.codigo == "32") {
      icono = "Ardilla";
    }

    if (props.codigo == "33") {
      icono = "Pescado";
    }

    if (props.codigo == "34") {
      icono = "Venado";
    }

    if (props.codigo == "35") {
      icono = "Jirafa";
    }

    if (props.codigo == "36") {
      icono = "Culebra";
    }

    if (props.codigo == "37") {
      icono = "Tortuga";
    }
    if (props.codigo == "38") {
      icono = "Bufalo";
    }
    if (props.codigo == "39") {
      icono = "Lechuza";
    }
    if (props.codigo == "40") {
      icono = "Avispa";
    }
    if (props.codigo == "41") {
      icono = "Canguro";
    }
    if (props.codigo == "42") {
      icono = "Tucan";
    }
    if (props.codigo == "43") {
      icono = "Mariposa";
    }
    if (props.codigo == "44") {
      icono = "Chiguire";
    }
    if (props.codigo == "45") {
      icono = "Garza";
    }
    if (props.codigo == "46") {
      icono = "Puma";
    }
    if (props.codigo == "47") {
      icono = "Pavo Real";
    }
    if (props.codigo == "48") {
      icono = "Puercoespin";
    }
    if (props.codigo == "49") {
      icono = "Pereza";
    }
    if (props.codigo == "50") {
      icono = "Canario";
    }
    if (props.codigo == "51") {
      icono = "Pelicano";
    }
    if (props.codigo == "52") {
      icono = "Pulpo";
    }
    if (props.codigo == "53") {
      icono = "Caracol";
    }
    if (props.codigo == "54") {
      icono = "Grillo";
    }
    if (props.codigo == "55") {
      icono = "Oso Hormiguero";
    }
    if (props.codigo == "56") {
      icono = "Tiburon";
    }
    if (props.codigo == "57") {
      icono = "Pato";
    }
    if (props.codigo == "58") {
      icono = "Hormiga";
    }
    if (props.codigo == "59") {
      icono = "Pantera";
    }
    if (props.codigo == "60") {
      icono = "Camaleon";
    }
    if (props.codigo == "61") {
      icono = "Panda";
    }
    if (props.codigo == "62") {
      icono = "Cachicamo";
    }
    if (props.codigo == "63") {
      icono = "Cangrejo";
    }
    if (props.codigo == "64") {
      icono = "Gavilan";
    }
    if (props.codigo == "65") {
      icono = "Araña";
    }
    if (props.codigo == "66") {
      icono = "Lobo";
    }
    if (props.codigo == "67") {
      icono = "Avestruz";
    }
    if (props.codigo == "68") {
      icono = "Jaguar";
    }
    if (props.codigo == "69") {
      icono = "Conejo";
    }
    if (props.codigo == "70") {
      icono = "Bisonte";
    }
    if (props.codigo == "71") {
      icono = "Guacamaya";
    }
    if (props.codigo == "72") {
      icono = "Gorila";
    }
    if (props.codigo == "73") {
      icono = "Hipopotamo";
    }
    if (props.codigo == "74") {
      icono = "Turpial";
    }
    if (props.codigo == "75") {
      icono = "Doble";
    }
  } else {
    icono = "No existe";
  }

  if (icono == "No existe") {
    return (
      <div align="center" className="mt-[-20px]">
        <div className="animal-icono centraricono flex flex-row">
          <img
            src={`${process.env.REACT_APP_HOST}/src/animalitos/erroricon.png`}
            className="animalito ml-2 w-14 h-14 border-2 rounded-lg border-blue-950 "
          />
          <div className="flex flex-col place-content-center ml-2">
            <label className="tamano-letra bg-cyan-800 rounded-full text-white">
              <span className="ml-0 p-2 text-sm">{icono}</span>
            </label>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div align="center" className="mt-[-20px]">
        <div className="animal-icono centraricono flex flex-row">
          <img
            src={`${process.env.REACT_APP_HOST}/src/animalitos/${props.codigo}.png`}
            className="animalito ml-2 w-14 h-14 border-2 rounded-lg border-blue-950 "
          />
          <div className="flex flex-col place-content-center">
            <div>
              <label className="tamano-letra bg-cyan-800 rounded-full text-white">
                <span className="ml-0 p-2">{props.codigo}</span>
              </label>
            </div>
            <div className="ml-1">
              <label className="tamano-letra text-blue-900 text-sm">
                {icono}
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Iconoanimal;
