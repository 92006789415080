import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { useState, useEffect } from "react";
import Axios from "axios";

const Formureports = ({
  addmensaje,
  formutipo,
  valorid,
  parte1,
  parte2,
  fecha,
  fecha2,
  addtipo,
  resultados,
  configag,
  listacajas,
  activarchequeo,
}) => {
  const [listalo2, setListalo2] = useState([]);
  const [mostrarhorario, setMostrarhorario] = useState([]);
  const [datoid, setDatoid] = useState([]);

  const [nombreloteria, setNombreloteria] = useState("");
  const [tipoloteria, setTipoloteria] = useState("");
  const [proporcion, setProporcion] = useState("");

  const [p1, setP1] = useState([]);
  const [p2, setP2] = useState([]);

  const [total, setTotal] = useState(0);
  const [premios, setPremios] = useState(0);
  const [ppagados, setPpagados] = useState(0);
  const [npagados, setNpagados] = useState(0);
  const [balance, setBalance] = useState(0);
  const [comision, setComision] = useState(0);
  const [porcentaje, setPorcentaje] = useState(0);

  const [caja, setCaja] = useState(0);

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);

  const [listapremios, setListapremios] = useState([]);
  const [listaventas, setListaventas] = useState([]);
  const [listapagados, setListapagados] = useState([]);
  const [listapagadosfinal, setListapagadosfinal] = useState([]);

  const [listalo, setListalo] = useState([]);

  const getloterias = () => {
    Axios.get(`${process.env.REACT_APP_API}/listaloterias/`).then((response) => {
      setListalo(response.data);
    });
  };

  var contador = 0;

  const getloterias2 = () => {
    Axios.get(`${process.env.REACT_APP_API}/listaloterias/`).then((response) => {
      setListalo2(response.data);
      console.log(response.data);
    });
  };

  const getnombres = (valorid) => {
    var lunes = false;
    var martes = false;
    var miercoles = false;
    var jueves = false;
    var viernes = false;
    var sabado = false;
    var domingo = false;
    for (let i = 0; i < listalo2.length; i++) {
      if (listalo2[i].id == valorid) {
        console.log("SE CUMPLIO");
        console.log(listalo2[i].name);
        setNombreloteria(listalo2[i].name);
        setTipoloteria(listalo2[i].tipo);
        setProporcion(listalo2[i].proporcion);
        if (listalo2[i].hlunes == 1) {
          lunes = true;
        }
        if (listalo2[i].hmartes == 1) {
          martes = true;
        }
        if (listalo2[i].hmiercoles == 1) {
          miercoles = true;
        }
        if (listalo2[i].hjueves == 1) {
          jueves = true;
        }
        if (listalo2[i].hviernes == 1) {
          viernes = true;
        }
        if (listalo2[i].hsabado == 1) {
          sabado = true;
        }
        if (listalo2[i].hdomingo == 1) {
          domingo = true;
        }
        setDatoid([
          {
            name: listalo2[i].name,
            tipo: listalo2[i].tipo,
            proporcion: listalo2[i].proporcion,
            hlunes: lunes,
            hmartes: martes,
            hmiercoles: miercoles,
            hjueves: jueves,
            hviernes: viernes,
            hsabado: sabado,
            hdomingo: domingo,
          },
        ]);
        console.log(datoid);
      }
    }
  };

  const enviarmensaje = (mensaje) => {
    addmensaje(mensaje);
  };

  const imprimircuadre = () => {};

  const verdetalle = () => {};

  const realizarcuadre = (para) => {
    //enviar parametros
    var totalt = 0;
    var premiost = 0;
    setTotal(0);
    setPremios(0);
    setPpagados(0);
    setNpagados(0);
    setBalance(0);
    setComision(0);

    var totalcomisiones = 0;
    var data;
    if (para == "caja") {
      data = {
        wfecha: fecha,
        widagencia: localStorage.getItem("veloci"),
        widcaja: localStorage.getItem("velocy"),
      };
    } else {
      data = {
        wfecha: fecha,
        widagencia: localStorage.getItem("velocy"),
        widcaja: caja,
      };
      if (configag.length != 0) {
        var comisiones = configag[0].min_agencia;
        setPorcentaje(comisiones);
      }

      console.log(caja);
    }

    console.log(localStorage.getItem("veloci"));
    console.log(localStorage.getItem("velocy"));
    //enviar datos
    fetch(`${process.env.REACT_APP_API}/obtenercuadre`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          console.log("CONSULTA DE CUADRE DE CAJA");
          setListaventas(result);
          if (result.length > 0) {
            for (let i = 0; i < result.length; i++) {
              console.log("TOTALLLL:" + result[i].total);
              totalt = parseInt(totalt) + result[i].total;
            }
            var totalcomision = (parseInt(totalt) * parseInt(comisiones)) / 100;
            setComision(totalcomision);

            if (para == "agencia") {
              realizarcuadre2(para, parseInt(totalt) - parseInt(totalcomision));
            } else {
              realizarcuadre2(para, totalt);
            }
            setTotal(totalt);
          } else {
            setTotal(totalt);
            realizarcuadre2(para, totalt);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  const realizarcuadre2 = (para, balance) => {
    //enviar parametros
    var premiost = 0;
    var premiosp = 0;
    var premiosn = 0;
    var data;
    console.log(balance);
    if (para == "caja") {
      data = {
        wfecha: fecha,
        widagencia: localStorage.getItem("veloci"),
        widcaja: localStorage.getItem("velocy"),
      };
      var idcaja = localStorage.getItem("velocy");
    } else {
      data = {
        wfecha: fecha,
        widagencia: localStorage.getItem("velocy"),
        widcaja: caja,
      };
      var idcaja = caja;
    }
    console.log(idcaja);
    //enviar datos
    fetch(`${process.env.REACT_APP_API}/obtenercuadret`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          console.log("CONSULTA DE CUADRE DE CAJA");
          console.log(result);
          setListapagados(result);
          var array2 = [];

          setListapagadosfinal(array2);
          if (result.length > 0) {
            for (let i = 0; i < result.length; i++) {
              if (fecha == result[i].fecha && result[i].idusuario == idcaja) {
                premiost = parseInt(premiost) + parseInt(result[i].premio);
              }

              console.log(result);
              if (
                result[i].estatus == "pagado" &&
                fecha == result[i].fechapago &&
                result[i].idusuariop == idcaja
              ) {
                premiosp = parseInt(premiosp) + parseInt(result[i].premio);
                array2.push({
                  idticket: result[i].idticket,
                  idusuario: result[i].idusuario,
                  fecha: result[i].fecha,
                  fechapago: result[i].fechapago,
                  premio: result[i].premio,
                });
              }

              if (
                result[i].estatus == "normal" &&
                result[i].fechapago == "" &&
                result[i].idusuario == idcaja
              ) {
                premiosn = parseInt(premiosn) + parseInt(result[i].premio);
                console.log(premiosn);
              }
            }
            console.log(balance);
            setBalance(parseInt(balance) - parseInt(premiosp));
            setPremios(premiost);
            setNpagados(premiosn);
            setPpagados(premiosp);
            setListapagadosfinal(array2);
          } else {
            console.log(balance);
            setBalance(parseInt(balance) - parseInt(premiosp));
            setPremios(premiost);
            setNpagados(premiosn);
            setPpagados(premiosp);
            setListapagadosfinal(array2);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  const buscarpremios = () => {
    //enviar parametros
    var premiost = 0;
    var premiosp = 0;
    var premiosn = 0;
    const data = {
      wfecha: fecha,
      widagencia: localStorage.getItem("velocy"),
    };

    //enviar datos
    fetch(`${process.env.REACT_APP_API}/obtenerresultadosagencia`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          if (result.length > 0) {
            console.log(result);
            setListapremios(result);
            for (let i = 0; i < result.length; i++) {
              premiost = parseInt(premiost) + result[i].premio;

              if (
                result[i].estatus == "pagado" &&
                fecha == result[i].fechapago
              ) {
                premiosp = parseInt(premiosp) + parseInt(result[i].premio);
              }

              if (
                result[i].estatus == "pagado" &&
                fecha != result[i].fechapago
              ) {
                premiosp = parseInt(premiosp) + parseInt(result[i].premio);
              }

              if (
                result[i].estatus == "normal" &&
                fecha != result[i].fechapagado
              ) {
                premiosn = parseInt(premiosn) + parseInt(result[i].premio);
              }
            }

            setPremios(premiost);
            setNpagados(premiosn);
            setPpagados(premiosp);
          }
        } else {
          setPremios(premiost);
          setNpagados(premiosn);
          setPpagados(premiosp);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  const msjsave = (mensajesave, tipodemensaje) => {
    if (tipodemensaje == "save") {
      toast.success(mensajesave, {});
    }
    if (tipodemensaje == "error") {
      toast.error(mensajesave, {});
    }
    if (tipodemensaje == "warning") {
      toast.warning(mensajesave, {});
    }
    if (tipodemensaje == "info") {
      toast.info(mensajesave, {});
    }
  };

  const limpiarcampos = () => {
    document.getElementById("campotipo").value = "";
    document.getElementById("campoloteria").value = "";
    document.getElementById("campoproporcion").value = "";
  };
  const guardarloteria = () => {
    var wname = document.getElementById("campoloteria").value;
    var wtipo = document.getElementById("campotipo").value;
    var wproporcion = document.getElementById("campoproporcion").value;
    var wrango = 0;

    if (validarexistencia(wname) == true) {
      msjsave("El Nombre de la Loteria ya existe, intente con otro", "error");
    } else {
      if (wtipo == "Animalito") {
        wrango = 75;
      }
      if (wtipo == "Terminalito") {
        wrango = 100;
      }

      if (wtipo == "Triple") {
        wrango = 1000;
      }

      const data = {
        name: wname,
        tipo: wtipo,
        proporcion: wproporcion,
        rangonum: wrango,
      };
      //enviar datos
      fetch(`${process.env.REACT_APP_API}/guardarloteria`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.message == "1062") {
            msjsave(
              "El Nombre ya se encuentra registrado pruebe con otro",
              "error"
            );
          } else {
            msjsave("Loteria Registrada con Exito", "save");
            getloterias2();
          }

          if (result) {
            console.log("SUS DATOS HAN SIDO GUARDADOS");

            enviarmensaje("nuevo");
            limpiarcampos();
          } else {
            console.log("ERROR DE CONEXION");
          }
        })
        .catch((error) => {
          console.log(error);
        });
      //fin de envio
    }
  };

  const modificarhorario = () => {
    for (let i = 0; i < 12; i++) {
      if (document.getElementById("horario" + i).checked == true) {
        var estatus = 1;
      } else {
        var estatus = 0;
      }

      Axios.post(`${process.env.REACT_APP_API}/modificarhorario/`, {
        id: p1[i].id,
        hora: p1[i].hora,
        estatus: estatus,
      }).then(() => {});
    }

    for (let i = 0; i < 12; i++) {
      if (document.getElementById("horario" + (i + 12)).checked == true) {
        var estatus = 1;
      } else {
        var estatus = 0;
      }
      Axios.post(`${process.env.REACT_APP_API}/modificarhorario`, {
        id: p2[i].id,
        hora: p2[i].hora,
        estatus: estatus,
      }).then(() => {});
    }

    msjsave("Datos Modificados con exito", "save");
    setTimeout(() => {
      enviarmensaje("n");
    }, 1000);
  };

  const modificarloteria = () => {
    var errorsi = 0;
    if (nombreloteria == document.getElementById("campoloteria").value) {
      Axios.post(`${process.env.REACT_APP_API}/modificarloteria/`, {
        id: valorid,
        name: document.getElementById("campoloteria").value,
        tipo: document.getElementById("campotipo").value,
        proporcion: document.getElementById("campoproporcion").value,
      }).then(() => {});
    } else {
      for (let i = 0; i < listalo2.length; i++) {
        if (listalo2[i].name == document.getElementById("campoloteria").value) {
          errorsi = 1;
          msjsave(
            "El nuevo nombre que intenta ingresar ya existe, intente con otro",
            "error"
          );
        }
      }
      if (errorsi == 0) {
        Axios.post(`${process.env.REACT_APP_API}/modificarloteria/`, {
          id: valorid,
          name: document.getElementById("campoloteria").value,
          tipo: document.getElementById("campotipo").value,
          proporcion: document.getElementById("campoproporcion").value,
        }).then(() => {});
      }
    }

    if (errorsi == 0) {
      msjsave("Datos Modificados con exito", "save");
      setTimeout(() => {
        enviarmensaje("n");
      }, 2000);
    }
  };

  const modificarsemana = () => {
    var hlunes, hmartes, hmiercoles, hjueves, hviernes, hsabado, hdomingo;
    if (document.getElementById("hlunes").checked == true) {
      hlunes = 1;
    } else {
      hlunes = 0;
    }
    if (document.getElementById("hmartes").checked == true) {
      hmartes = 1;
    } else {
      hmartes = 0;
    }
    if (document.getElementById("hmiercoles").checked == true) {
      hmiercoles = 1;
    } else {
      hmiercoles = 0;
    }
    if (document.getElementById("hjueves").checked == true) {
      hjueves = 1;
    } else {
      hjueves = 0;
    }
    if (document.getElementById("hviernes").checked == true) {
      hviernes = 1;
    } else {
      hviernes = 0;
    }
    if (document.getElementById("hsabado").checked == true) {
      hsabado = 1;
    } else {
      hsabado = 0;
    }
    if (document.getElementById("hdomingo").checked == true) {
      hdomingo = 1;
    } else {
      hdomingo = 0;
    }
    Axios.post(`${process.env.REACT_APP_API}/modificarsemana/`, {
      id: valorid,
      hlunes: hlunes,
      hmartes: hmartes,
      hmiercoles: hmiercoles,
      hjueves: hjueves,
      hviernes: hviernes,
      hsabado: hsabado,
      hdomingo: hdomingo,
    }).then(() => {});

    msjsave("Dias de la Semana Configurados Exitosamente", "save");
    setTimeout(() => {
      enviarmensaje("n");
    }, 1000);
  };

  const validarexistencia = (wname) => {
    var datosl = listalo2;
    var resultado = false;
    for (let i = 0; i < datosl.length; i++) {
      if (datosl[i].name == wname) {
        resultado = true;
      }
    }
    return resultado;
  };

  const obtenerminutodif = (horapasada) => {
    var hora = horapasada;
    var date5 = new Date("2024-11-05T" + hora);
    //CALCULANDO LOS MINUTOS QUE FALTAN
    date5.setMinutes(parseInt(document.getElementById("minutos").value));

    var hora1 = "";
    var minuto1 = "";
    var segundo1 = "";
    if (date5.getHours() < 10) {
      hora1 = "0" + date5.getHours();
    } else {
      hora1 = date5.getHours();
    }
    if (date5.getMinutes() < 10) {
      minuto1 = "0" + date5.getMinutes();
    } else {
      minuto1 = date5.getMinutes();
    }
    if (date5.getSeconds() < 10) {
      segundo1 = "0" + date5.getSeconds();
    } else {
      segundo1 = date5.getSeconds();
    }
    return hora1 + ":" + minuto1 + ":" + segundo1;
    //FIN DE CALCULO
  };

  const cambiarminutos = () => {
    for (let i = 0; i < 12; i++) {
      obtenerminutodif(p1[i].hora);
    }
    for (let i = 0; i < 12; i++) {
      obtenerminutodif(p2[i].hora);
    }
    console.log(p1);
    console.log(p2);
    var dato1 = [
      {
        id1: 0,
        id: p1[0].id,
        idloteria: p1[0].idloteria,
        hora: obtenerminutodif(p1[0].hora),
        estatus: p1[0].estatus,
      },
      {
        id1: 1,
        id: p1[1].id,
        idloteria: p1[1].idloteria,
        hora: obtenerminutodif(p1[1].hora),
        estatus: p1[1].estatus,
      },
      {
        id1: 2,
        id: p1[2].id,
        idloteria: p1[2].idloteria,
        hora: obtenerminutodif(p1[2].hora),
        estatus: p1[2].estatus,
      },
      {
        id1: 3,
        id: p1[3].id,
        idloteria: p1[3].idloteria,
        hora: obtenerminutodif(p1[3].hora),
        estatus: p1[3].estatus,
      },
      {
        id1: 4,
        id: p1[4].id,
        idloteria: p1[4].idloteria,
        hora: obtenerminutodif(p1[4].hora),
        estatus: p1[4].estatus,
      },
      {
        id1: 5,
        id: p1[5].id,
        idloteria: p1[5].idloteria,
        hora: obtenerminutodif(p1[5].hora),
        estatus: p1[5].estatus,
      },
      {
        id1: 6,
        id: p1[6].id,
        idloteria: p1[6].idloteria,
        hora: obtenerminutodif(p1[6].hora),
        estatus: p1[6].estatus,
      },
      {
        id1: 7,
        id: p1[7].id,
        idloteria: p1[7].idloteria,
        hora: obtenerminutodif(p1[7].hora),
        estatus: p1[7].estatus,
      },
      {
        id1: 8,
        id: p1[8].id,
        idloteria: p1[8].idloteria,
        hora: obtenerminutodif(p1[8].hora),
        estatus: p1[8].estatus,
      },
      {
        id1: 9,
        id: p1[9].id,
        idloteria: p1[9].idloteria,
        hora: obtenerminutodif(p1[9].hora),
        estatus: p1[9].estatus,
      },
      {
        id1: 10,
        id: p1[10].id,
        idloteria: p1[10].idloteria,
        hora: obtenerminutodif(p1[10].hora),
        estatus: p1[10].estatus,
      },
      {
        id1: 11,
        id: p1[11].id,
        idloteria: p1[11].idloteria,
        hora: obtenerminutodif(p1[11].hora),
        estatus: p1[11].estatus,
      },
    ];

    var dato3 = [
      {
        id2: 12,
        id: p2[0].id,
        idloteria: p2[0].idloteria,
        hora: obtenerminutodif(p2[0].hora),
        estatus: p2[0].estatus,
      },
      {
        id2: 13,
        id: p2[1].id,
        idloteria: p2[1].idloteria,
        hora: obtenerminutodif(p2[1].hora),
        estatus: p2[1].estatus,
      },
      {
        id2: 14,
        id: p2[2].id,
        idloteria: p2[2].idloteria,
        hora: obtenerminutodif(p2[2].hora),
        estatus: p2[2].estatus,
      },
      {
        id2: 15,
        id: p2[3].id,
        idloteria: p2[3].idloteria,
        hora: obtenerminutodif(p2[3].hora),
        estatus: p2[3].estatus,
      },
      {
        id2: 16,
        id: p2[4].id,
        idloteria: p2[4].idloteria,
        hora: obtenerminutodif(p2[4].hora),
        estatus: p2[4].estatus,
      },
      {
        id2: 17,
        id: p2[5].id,
        idloteria: p2[5].idloteria,
        hora: obtenerminutodif(p2[5].hora),
        estatus: p2[5].estatus,
      },
      {
        id2: 18,
        id: p2[6].id,
        idloteria: p2[6].idloteria,
        hora: obtenerminutodif(p2[6].hora),
        estatus: p2[6].estatus,
      },
      {
        id2: 19,
        id: p2[7].id,
        idloteria: p2[7].idloteria,
        hora: obtenerminutodif(p2[7].hora),
        estatus: p2[7].estatus,
      },
      {
        id2: 20,
        id: p2[8].id,
        idloteria: p2[8].idloteria,
        hora: obtenerminutodif(p2[8].hora),
        estatus: p2[8].estatus,
      },
      {
        id2: 21,
        id: p2[9].id,
        idloteria: p2[9].idloteria,
        hora: obtenerminutodif(p2[9].hora),
        estatus: p2[9].estatus,
      },
      {
        id2: 22,
        id: p2[10].id,
        idloteria: p2[10].idloteria,
        hora: obtenerminutodif(p2[10].hora),
        estatus: p2[10].estatus,
      },
      {
        id2: 23,
        id: p2[11].id,
        idloteria: p2[11].idloteria,
        hora: obtenerminutodif(p2[11].hora),
        estatus: p2[11].estatus,
      },
    ];
    setP1(dato1);
    setP2(dato3);
  };

  const colocarresultado = (idh, fecha) => {
    var resu = "";
    console.log("RESULTADO IHORARIO:" + idh);
    for (let i = 0; i < resultados.length; i++) {
      if (resultados[i].idhorario == idh && resultados[i].fecha == fecha) {
        resu = resultados[i].resultado;
      }
    }
    return resu;
  };

  const modificarresultado = () => {
    for (let i = 0; i < 12; i++) {
      if (p1[i].estatus == 1) {
        var resultado = document.getElementById(
          "camporesult1" + p1[i].id1
        ).value;
        var idhorario = p1[i].id;
        Axios.post(`${process.env.REACT_APP_API}/modificarresultados/`, {
          wid: idhorario,
          wfecha: fecha,
          wresultado: resultado,
        }).then(() => {});
      }
    }

    for (let i = 0; i < 12; i++) {
      if (p2[i].estatus == 1) {
        var resultado = document.getElementById(
          "camporesult1" + p2[i].id2
        ).value;
        var idhorario = p2[i].id;
        Axios.post(`${process.env.REACT_APP_API}/modificarresultados/`, {
          wid: idhorario,
          wfecha: fecha,
          wresultado: resultado,
        }).then(() => {});
      }
    }

    msjsave("Datos Modificados con exito", "save");
    setTimeout(() => {
      addtipo("h");
    }, 1000);
  };

  useEffect(() => {
    getloterias2();

    getnombres(valorid);
    console.log(parte1);
    console.log(parte2);
    setP1(parte1);
    setP2(parte2);
  }, [formutipo, valorid, parte1, parte2]);

  useEffect(() => {
    setP1(parte1);
    setP2(parte2);
    getnombres(valorid);
    if (formutipo == "h") {
      setTimeout(() => {
        //limpiar datos
        setTotal(0);
        setPremios(0);
        setPpagados(0);
        setNpagados(0);
        setBalance(0);
        setComision(0);

        enviarmensaje("horario");
        realizarcuadre("caja");
      }, 1000);
    }
    if (formutipo == "hh") {
      setTimeout(() => {
        //limpiar datos
        setTotal(0);
        setPremios(0);
        setPpagados(0);
        setNpagados(0);
        setBalance(0);
        setComision(0);

        enviarmensaje("horario2");
        console.log(configag);
        realizarcuadre("agencia");
      }, 1000);
    }
    if (formutipo == "r") {
      setTimeout(() => {
        //limpiar datos
        setTotal(0);
        setPremios(0);
        setPpagados(0);
        setNpagados(0);
        setBalance(0);

        enviarmensaje("resultados");
        buscarpremios();
      }, 1000);
    }
    if (formutipo == "l") {
      setTimeout(() => {
        //limpiar datos
        setTotal(0);
        setPremios(0);
        setPpagados(0);
        setNpagados(0);
        setBalance(0);
        getloterias();
        enviarmensaje("loterias");
      }, 1000);
    }
    if (formutipo == "d") {
      getloterias2();

      getnombres(valorid);
      setTimeout(() => {
        enviarmensaje("editar");
      }, 1000);
    }
    if (formutipo == "n") {
      getloterias2();
      setTimeout(() => {
        enviarmensaje("nuevo");
      }, 1000);
    }
    if (formutipo == "s") {
      getloterias2();
      setTimeout(() => {
        enviarmensaje("semana");
      }, 1000);
    }
  }, [formutipo]);

  useEffect(() => {
    if (formutipo == "resultados") {
      //limpiar datos
      setTotal(0);
      setPremios(0);
      setPpagados(0);
      setNpagados(0);
      setBalance(0);
      buscarpremios();
      setListapremios([]);
    }
    if (formutipo == "horario") {
      //limpiar datos
      setTotal(0);
      setPremios(0);
      setPpagados(0);
      setNpagados(0);
      setBalance(0);
      realizarcuadre("caja");
    }
    if (formutipo == "horario2") {
      //limpiar datos
      setTotal(0);
      setPremios(0);
      setPpagados(0);
      setNpagados(0);
      setBalance(0);
      realizarcuadre("agencia");
    }
    if (formutipo == "loterias") {
      //limpiar datos
      setTotal(0);
      setPremios(0);
      setPpagados(0);
      setNpagados(0);
      setBalance(0);
      getloterias();
    }
  }, [fecha, fecha2]);

  useEffect(() => {
    realizarcuadre("agencia");
  }, [caja]);

  useEffect(() => {
    console.log(listapagadosfinal);
  }, [listapagadosfinal]);

  if (formutipo == "nuevo") {
    console.log("LLEGO EL NUEVO");
    return (
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-calendar-check"></i> Reporte Generado
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="campoloteriasn"
                                name="campoloteriasn"
                                maxlength="27"
                                value="SELECCIONE UN REPORTE...."
                                disabled="true"
                              />
                            </div>
                          </div>

                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  if (formutipo == "editar") {
    console.log("LLEGO EL EDITAR");
    return (
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-edit"></i> Actualizar Registro de
                      Loteria
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <label
                                for="cliente_dni"
                                className="bmd-label-floating"
                              >
                                Nombre de Loteria
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="campoloteriae"
                                name="campoloteriae"
                                maxLength="27"
                                defaultValue={nombreloteria}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label
                                for="cliente_nombre"
                                className="bmd-label-floating"
                              >
                                Tipo de Loteria
                              </label>
                              {datoid.map((val, key) => {
                                if (val.tipo === "ANIMALITOS") {
                                  return (
                                    <select
                                      className="form-control"
                                      id="campotipo"
                                      name="campotipo"
                                    >
                                      <option selected value="ANIMALITOS">
                                        ANIMALITOS
                                      </option>
                                      <option value="TERMINALITO">
                                        TERMINALITO
                                      </option>
                                      <option value="TRIPLE">TRIPLE</option>
                                    </select>
                                  );
                                }
                                if (val.tipo === "TERMINALITO") {
                                  return (
                                    <select
                                      className="form-control"
                                      id="campotipo"
                                      name="campotipo"
                                    >
                                      <option value="ANIMALITOS">
                                        ANIMALITOS
                                      </option>
                                      <option selected value="TERMINALITO">
                                        TERMINALITO
                                      </option>
                                      <option value="TRIPLE">TRIPLE</option>
                                    </select>
                                  );
                                }
                                if (val.tipo === "TRIPLE") {
                                  return (
                                    <select
                                      className="form-control"
                                      id="campotipo"
                                      name="campotipo"
                                    >
                                      <option value="ANIMALITOS">
                                        ANIMALITOS
                                      </option>
                                      <option value="TERMINALITO">
                                        TERMINALITO
                                      </option>
                                      <option selected value="TRIPLE">
                                        TRIPLE
                                      </option>
                                    </select>
                                  );
                                }
                              })}
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label
                                for="cliente_apellido"
                                className="bmd-label-floating"
                              >
                                Proporcion
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="campoproporcion"
                                id="campoproporcion"
                                maxLength="40"
                                defaultValue={proporcion}
                              />
                            </div>
                          </div>
                          <br></br>
                          <br></br>
                          <br></br>

                          <br></br>
                          <table align="center">
                            <tr>
                              <td>
                                <p className="text-center">
                                  &nbsp; &nbsp;
                                  <button
                                    type="submit"
                                    className="btn btn-raised btn-info btn-sm"
                                    onClick={modificarloteria}
                                  >
                                    <i className="far fa-save"></i> &nbsp;
                                    ACTUALIZAR DATOS
                                  </button>
                                </p>
                              </td>
                            </tr>
                          </table>
                          <ToastContainer />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  if (formutipo == "horario") {
    console.log("LLEGO EL HORARIO");
    return (
        <>
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-calendar-check"></i> 
                      
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                            <div
              id="fondolote"
              className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm"
            >
              {`Caja: 1 ==> Fecha: ${fecha}`}
            </div>
                              <input
                                type="hidden"
                                className="form-control"
                                id="campoloteria43"
                                name="campoloteria43"
                                maxlength="27"
                                value={`Caja: 1 ==> Fecha: ${fecha}`}
                                disabled="true"
                              />
                            </div>
                          </div>


                          <div className="">
                          <span className="font-medium text-md">CUADRE DE CAJA</span>
              <table class="w-[100%] text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <tbody>
                  <tr className="w-full">
                    <th scope="col" class="px-6 py-1">
                      
                    </th>
                    <th scope="col" class="px-6 py-1 text-center text-xl">
                     Totales
                    </th>
                    
                  </tr>

                  <tr className="w-full">
                                <td className="text-center roboto-medium">
                                  <span className="text-lg">
                                    <font color="blue">Ventas</font>
                                  </span>
                                </td>
                                <td className="text-center roboto-medium text-lg">{total}</td>
                              </tr>


                              <tr className="text-center roboto-medium">
                                <td>
                                  <span className="text-lg">
                                    <font color="blue">Premios</font>
                                  </span>
                                </td>
                                <td className="text-lg">{premios}</td>
                              </tr>
                              <tr className="text-center roboto-medium">
                                <td>
                                  <span className="text-lg">
                                    <font color="blue">Premios Pagados</font>
                                  </span>
                                </td>
                                <td className="text-lg">{ppagados}</td>
                              </tr>
                              <tr className="text-center roboto-medium">
                                <td>
                                  <span className="text-lg">
                                    <font color="blue">Premios por Pagar</font>
                                  </span>
                                </td>
                                <td className="text-lg">{npagados}</td>
                              </tr>
                              <tr
                                className="text-center roboto-medium"
                                bgcolor="black"
                              >
                                <td>
                                  <span className="text-xl" align="rigth">
                                    <font color="white">Balance</font>
                                  </span>
                                </td>
                                <td>
                                  <span className="text-xl">
                                    <font color="white">{balance}</font>
                                  </span>
                                </td>
                              </tr>

               
                
                </tbody>
              </table>
            </div>

                      

                     
                          <hr></hr>
                          <div className="flex flex-row mt-4">
                          <button
                            type="button"
                            onClick={() => {
                              setModal2(true);
                              }}
                              id="exa5"
                            class="text-white flex flex-row bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-1.5 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                            <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M5 7h14M5 12h14M5 17h10"/>
</svg>
&nbsp;VER DETALLES</button>


<button
                            type="button"
                            onClick={imprimircuadre}
                              id="exa5"
                            class="text-white flex flex-row bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-1.5 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                            <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M8 3a2 2 0 0 0-2 2v3h12V5a2 2 0 0 0-2-2H8Zm-3 7a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h1v-4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v4h1a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2H5Zm4 11a1 1 0 0 1-1-1v-4h8v4a1 1 0 0 1-1 1H9Z" clip-rule="evenodd"/>
</svg>

&nbsp;IMPRIMIR</button>
                          
                                  
                          </div>
                         
                          <ToastContainer />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

{modal2 ? (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-lg">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
            {/*body*/}
            <div className="h-12 bg-black rounded-t-lg place-content-center flex flex-grow text-white text-md font-semibold">
              <span className="mt-2 pl-2">DETALLE DE CUADRE PARA CAJA #
                                            {localStorage.getItem("velocy")} =
                                            {">"} {fecha}</span>
            </div>
            <div className="relative p-1 flex-auto">
              <div className="p-1 md:p-1">
                <div className="flex flex-row flex-wrap">
                  <div className="flex flex-row text-blue-950 text-md font-bold">
                  <div className="modal-body">
                  <div className="modal-body">
                                          <div className="container-fluid2">
                                            <div className="container-dentro3">
                                              <table width="100%">
                                                <tr>
                                                  <td width="100%" valign="top">
                                                    LISTA DE VENTAS

                                                    <table class="w-[100%] table1 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <tbody>
                  <tr className="w-full bg-gray-300">
                    <th scope="col" class="px-6 py-1">
                      #
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Caja
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Fecha
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Hora
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Total
                    </th>
                  </tr>

                  {listaventas.map(
                                                          (val, key) => {
                                                            return (
                                                              <tr
                                                                key={val.id}
                                                                className="text-center"
                                                              >
                                                                <td className="mousehover">
                                                                  {val.id}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.user}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.fecha}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.hora}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.total}
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                </tbody>
              </table>







                                                    PREMIOS PAGADOS


                                                    <table class="w-[100%] text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <tbody>
                  <tr className="w-full bg-gray-300">
                    <th scope="col" class="px-6 py-1">
                      #
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Caja
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Fecha
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Fecha de Pago
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Premio
                    </th>
                  </tr>

                  {listapagadosfinal.map(
                                                          (val, key) => {
                                                            return (
                                                              <tr
                                                                key={val.id}
                                                                className="text-center"
                                                              >
                                                                <td className="mousehover">
                                                                  {val.idticket}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {
                                                                    val.idusuario
                                                                  }
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.fecha}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {
                                                                    val.fechapago
                                                                  }
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.premio}
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                </tbody>
              </table>





                                               
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                        </div>
                  </div>
                </div>

        

              
              </div>
            </div>
            <div className="h-14 bg-black rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
              <button
                type="button"
                onClick={() => {
                  setModal2(false);
                  
                }}
                data-modal-hide="popup-modal"
                class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
              >
                <svg
                  class="w-6 h-6 text-gray-800 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  ) : null}
  </>
    );
  }
  if (formutipo == "horario2") {
    console.log("LLEGO EL HORARIO");
    return (
      <>
      <div>
        <table width="100%" className="mt-4">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <input
                                type="hidden"
                                className="form-control"
                                id="campoloteria43"
                                name="campoloteria43"
                                maxlength="27"
                                value={`REPORTE PARA EL DIA ==> Fecha: ${fecha}`}
                                disabled="true"
                              />
                            </div>
                            <div
              id="fondolote"
              className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm"
            >
              {`REPORTE PARA EL DIA ==> Fecha: ${fecha}`}
            </div>
                          </div>
                          <select
                            class="styled-select bg-gray-400 rounded-lg font-bold text-white mt-4"
                            id="cajas"
                            onChange={(e) => setCaja(e.target.value)}
                          >
                            <option value="0">&nbsp;Selecciona su Caja</option>
                            {listacajas.map((val, key) => {
                              return (
                                <option value={val.id}>
                                  &nbsp;Caja #{val.name}
                                </option>
                              );
                            })}
                          </select>
                          <br />
                          &nbsp;


                          <table class="w-[100%] text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <tbody>
                  <tr className="w-full">
                    <th scope="col" class="px-6 py-1">
                      
                    </th>
                    <th scope="col" class="px-6 py-1 text-center text-xl">
                     Totales
                    </th>
                    
                  </tr>

                  <tr className="w-full">
                                <td className="text-center roboto-medium">
                                  <span className="text-lg">
                                    <font color="blue">Ventas</font>
                                  </span>
                                </td>
                                <td className="text-center roboto-medium text-lg">{total}</td>
                              </tr>


                              <tr className="text-center roboto-medium">
                                <td>
                                  <span className="text-lg">
                                    <font color="blue">Premios</font>
                                  </span>
                                </td>
                                <td className="text-lg">{premios}</td>
                              </tr>
                              <tr className="text-center roboto-medium">
                                <td>
                                  <span className="text-lg">
                                    <font color="blue">Comisiones ({porcentaje}%)</font>
                                  </span>
                                </td>
                                <td className="text-lg">{comision}</td>
                              </tr>
                              <tr className="text-center roboto-medium">
                                <td>
                                  <span className="text-lg">
                                    <font color="blue">Premios Pagados</font>
                                  </span>
                                </td>
                                <td className="text-lg">{ppagados}</td>
                              </tr>
                              <tr className="text-center roboto-medium">
                                <td>
                                  <span className="text-lg">
                                    <font color="blue">Premios por Pagar</font>
                                  </span>
                                </td>
                                <td className="text-lg">{npagados}</td>
                              </tr>
                              <tr
                                className="text-center roboto-medium"
                                bgcolor="black"
                              >
                                <td>
                                  <span className="text-xl" align="rigth">
                                    <font color="white">Balance</font>
                                  </span>
                                </td>
                                <td>
                                  <span className="text-xl">
                                    <font color="white">{balance}</font>
                                  </span>
                                </td>
                              </tr>

               
                
                </tbody>
              </table>


              
                          <hr></hr>
                          <div className="flex flex-row mt-4">
                          <button
                            type="button"
                            onClick={() => {
                              setModal3(true);
                              }}
                              id="exa5"
                            class="text-white flex flex-row bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-1.5 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                            <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M5 7h14M5 12h14M5 17h10"/>
</svg>
&nbsp;VER DETALLES</button>


<button
                            type="button"
                            onClick={imprimircuadre}
                              id="exa5"
                            class="text-white flex flex-row bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-1.5 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                            <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M8 3a2 2 0 0 0-2 2v3h12V5a2 2 0 0 0-2-2H8Zm-3 7a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h1v-4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v4h1a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2H5Zm4 11a1 1 0 0 1-1-1v-4h8v4a1 1 0 0 1-1 1H9Z" clip-rule="evenodd"/>
</svg>

&nbsp;IMPRIMIR</button>
                          
                                  
                          </div>
                          <table className="hidden" align="right">
                            <tr>
                              <td>
                                <p>
                                  &nbsp; &nbsp;
                                  <button
                                    type="button"
                                    className="btn btn-raised btn-primary btn-sm"
                                    data-toggle="modal"
                                    data-target="#example4"
                                   onClick={() => {
                                   setModal3(true);
                                   }}
                                    id="exa4"
                                  >
                                    <i className="fas fa-print"></i> &nbsp; VER
                                    DETALLES
                                  </button>
                                  <div
                                    className="modal fade"
                                    id="example4"
                                    tabIndex="-1"
                                    role="dialog"
                                    aria-labelledby="exampleModalLabel4"
                                    aria-hidden="true"
                                  >
                                    <div
                                      className="modal-dialog modal-lg"
                                      role="document"
                                    >
                                      <div className="modal-content">
                                        <div className="modal-header">
                                          <h5
                                            className="modal-title"
                                            id="exampleModalLabel"
                                          >
                                            DETALLE DE CUADRE PARA CAJA #{caja}{" "}
                                            ={">"} {fecha}
                                          </h5>
                                          <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            <span aria-hidden="true">
                                              &times;
                                            </span>
                                          </button>
                                        </div>
                                        <div className="modal-body">
                                          <div className="container-fluid2">
                                            <div className="container-dentro3">
                                              <table width="100%">
                                                <tr>
                                                  <td width="100%" valign="top">
                                                    LISTA DE VENTAS
                                                    <table className="table table-dark table-sm">
                                                      <thead>
                                                        <tr className="text-center roboto-medium">
                                                          <th id="fondolote">
                                                            #
                                                          </th>
                                                          <th id="fondolote">
                                                            Caja
                                                          </th>
                                                          <th id="fondolote">
                                                            Fecha
                                                          </th>
                                                          <th id="fondolote">
                                                            Hora
                                                          </th>
                                                          <th id="fondolote">
                                                            Total
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {listaventas.map(
                                                          (val, key) => {
                                                            return (
                                                              <tr
                                                                key={val.id}
                                                                className="text-center"
                                                              >
                                                                <td className="mousehover">
                                                                  {val.id}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.user}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.fecha}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.hora}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.total}
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                                                      </tbody>
                                                    </table>
                                                    PREMIOS PAGADOS
                                                    <table className="table table-dark table-sm">
                                                      <thead>
                                                        <tr className="text-center roboto-medium">
                                                          <th id="fondolote">
                                                            #
                                                          </th>
                                                          <th id="fondolote">
                                                            Caja
                                                          </th>
                                                          <th id="fondolote">
                                                            Fecha
                                                          </th>
                                                          <th id="fondolote">
                                                            Fecha de Pago
                                                          </th>
                                                          <th id="fondolote">
                                                            Premio
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {listapagadosfinal.map(
                                                          (val, key) => {
                                                            return (
                                                              <tr
                                                                key={val.id}
                                                                className="text-center"
                                                              >
                                                                <td className="mousehover">
                                                                  {val.idticket}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {
                                                                    val.idusuario
                                                                  }
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.fecha}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {
                                                                    val.fechapago
                                                                  }
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.premio}
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="modal-footer">
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-dismiss="modal"
                                            id="cerrarmodal4"
                                          >
                                            Cerrar
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/*  MODAL ANULACION*/}
                                  &nbsp;
                                  <button
                                    type="submit"
                                    className="btn btn-raised btn-info btn-sm"
                                    onClick={imprimircuadre}
                                  >
                                    <i className="fas fa-print"></i> &nbsp;
                                    IMPRIMIR
                                  </button>
                                </p>
                              </td>
                            </tr>
                          </table>
                          <ToastContainer />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {modal3 ? (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-lg">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
            {/*body*/}
            <div className="h-12 bg-black rounded-t-lg place-content-center flex flex-grow text-white text-md font-semibold">
              <span className="mt-2 pl-2">DETALLE DE CUADRE PARA CAJA #{caja} ={">"} {fecha}</span>
            </div>
            <div className="relative p-1 flex-auto">
              <div className="p-1 md:p-1">
                <div className="flex flex-row flex-wrap">
                  <div className="flex flex-row text-blue-950 text-md font-bold">
                  <div className="modal-body">
                  <div className="modal-body">
                                          <div className="container-fluid2">
                                            <div className="container-dentro3">
                                              <table width="100%">
                                                <tr>
                                                  <td width="100%" valign="top">
                                                    LISTA DE VENTAS

                                                    <table class="w-[100%] table1 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <tbody>
                  <tr className="w-full bg-gray-300">
                    <th scope="col" class="px-6 py-1">
                      #
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Caja
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Fecha
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Hora
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Total
                    </th>
                  </tr>

                  {listaventas.map(
                                                          (val, key) => {
                                                            return (
                                                              <tr
                                                                key={val.id}
                                                                className="text-center"
                                                              >
                                                                <td className="mousehover">
                                                                  {val.id}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.user}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.fecha}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.hora}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.total}
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                </tbody>
              </table>







                                                    PREMIOS PAGADOS


                                                    <table class="w-[100%] text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <tbody>
                  <tr className="w-full bg-gray-300">
                    <th scope="col" class="px-6 py-1">
                      #
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Caja
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Fecha
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Fecha de Pago
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Premio
                    </th>
                  </tr>

                  {listapagadosfinal.map(
                                                          (val, key) => {
                                                            return (
                                                              <tr
                                                                key={val.id}
                                                                className="text-center"
                                                              >
                                                                <td className="mousehover">
                                                                  {val.idticket}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {
                                                                    val.idusuario
                                                                  }
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.fecha}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {
                                                                    val.fechapago
                                                                  }
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.premio}
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                </tbody>
              </table>





                                               
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                        </div>
                  </div>
                </div>

        

              
              </div>
            </div>
            <div className="h-14 bg-black rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
              <button
                type="button"
                onClick={() => {
                  setModal3(false);
                  
                }}
                data-modal-hide="popup-modal"
                class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
              >
                <svg
                  class="w-6 h-6 text-gray-800 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  ) : null}
      </>
    );
  }

  if (formutipo == "resultados") {
    console.log("LLEGO EL HORARIO");
    return (
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-calendar-check"></i> Reporte Generado
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="campoloteria43"
                                name="campoloteria43"
                                maxlength="27"
                                value={`PREMIOS ==> Fecha: ${fecha}`}
                                disabled="true"
                              />
                            </div>
                          </div>

                          <table className="table table-dark table-sm">
                            <thead>
                              <tr className="text-center roboto-medium">
                                <th colSpan="3" id="fondolote">
                                  RESULTADOS AGENCIA
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                bgcolor="black"
                                className="text-center roboto-medium"
                              >
                                <td>
                                  <font color="white"></font>
                                </td>
                                <td>
                                  <font color="white">Totales</font>
                                </td>
                              </tr>

                              <tr className="text-center roboto-medium">
                                <td>
                                  <span className="">
                                    <font color="blue">Premios</font>
                                  </span>
                                </td>
                                <td>{premios}</td>
                              </tr>
                              <tr className="text-center roboto-medium">
                                <td>
                                  <span className="">
                                    <font color="blue">Premios Pagados</font>
                                  </span>
                                </td>
                                <td>{ppagados}</td>
                              </tr>
                              <tr className="text-center roboto-medium">
                                <td>
                                  <span className="">
                                    <font color="blue">Premios por Pagar</font>
                                  </span>
                                </td>
                                <td>{npagados}</td>
                              </tr>
                              <tr
                                className="text-center roboto-medium"
                                bgcolor="black"
                              ></tr>
                            </tbody>
                          </table>

                          <br></br>
                          <br></br>
                          <br></br>
                          <br></br>
                          <hr></hr>
                          <table align="right">
                            <tr>
                              <td>
                                <p>
                                  &nbsp; &nbsp;
                                  <button
                                    type="button"
                                    className="btn btn-raised btn-primary btn-sm"
                                    data-toggle="modal"
                                    data-target="#example3"
                                    id="exa3"
                                  >
                                    <i className="fas fa-print"></i> &nbsp; VER
                                    DETALLES
                                  </button>
                                  <div
                                    className="modal fade"
                                    id="example3"
                                    tabIndex="-1"
                                    role="dialog"
                                    aria-labelledby="exampleModalLabel3"
                                    aria-hidden="true"
                                  >
                                    <div
                                      className="modal-dialog modal-lg"
                                      role="document"
                                    >
                                      <div className="modal-content">
                                        <div className="modal-header">
                                          <h5
                                            className="modal-title"
                                            id="exampleModalLabel"
                                          >
                                            RESULTADOS DE AGENCIA ={">"} {fecha}
                                          </h5>
                                          <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            <span aria-hidden="true">
                                              &times;
                                            </span>
                                          </button>
                                        </div>
                                        <div className="modal-body">
                                          <div className="container-fluid2">
                                            <div className="container-dentro3">
                                              <table width="100%">
                                                <tr>
                                                  <td width="100%" valign="top">
                                                    PREMIOS
                                                    <table className="table table-dark table-sm">
                                                      <thead>
                                                        <tr className="text-center roboto-medium">
                                                          <th id="fondolote">
                                                            #
                                                          </th>
                                                          <th id="fondolote">
                                                            Caja
                                                          </th>
                                                          <th id="fondolote">
                                                            Fecha
                                                          </th>
                                                          <th id="fondolote">
                                                            Monto
                                                          </th>
                                                          <th id="fondolote">
                                                            Premio
                                                          </th>

                                                          <th id="fondolote">
                                                            Pagado
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {listapremios.map(
                                                          (val, key) => {
                                                            return (
                                                              <tr
                                                                key={val.id}
                                                                className="text-center"
                                                              >
                                                                <td className="mousehover">
                                                                  {val.idticket}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {
                                                                    val.idusuario
                                                                  }
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.fecha}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.monto}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.premio}
                                                                </td>

                                                                <td>
                                                                  {val.estatus ==
                                                                  "pagado" ? (
                                                                    <span>
                                                                      <i className="fa-solid fa-circle-check colorgreen"></i>
                                                                    </span>
                                                                  ) : (
                                                                    <span></span>
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="modal-footer">
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-dismiss="modal"
                                            id="cerrarmodal3"
                                          >
                                            Cerrar
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/*  MODAL ANULACION*/}
                                  &nbsp;
                                  <button
                                    type="submit"
                                    className="btn btn-raised btn-info btn-sm"
                                    onClick={imprimircuadre}
                                  >
                                    <i className="fas fa-print"></i> &nbsp;
                                    IMPRIMIR
                                  </button>
                                </p>
                              </td>
                            </tr>
                          </table>
                          <ToastContainer />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  if (formutipo == "loterias") {
    return (
        <>
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-calendar-check"></i> Reporte Generado
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="campoloteria43"
                                name="campoloteria43"
                                maxlength="27"
                                value={`VENTAS POR LOTERIA ==> Fecha: ${fecha} <--> ${fecha2}`}
                                disabled="true"
                              />
                            </div>
                          </div>

                          <table className="table table-dark table-sm">
                            <thead>
                              <tr className="text-center roboto-medium">
                                <th colSpan="3" id="fondolote">
                                  VENTAS POR LOTERIA
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                bgcolor="black"
                                className="text-center roboto-medium"
                              >
                                <td>
                                  <font color="white"></font>
                                </td>
                                <td>
                                  <font color="white">Totales</font>
                                </td>
                              </tr>

                              <tr className="text-center roboto-medium">
                                <td>
                                  <span className="">
                                    <font color="blue">Premios</font>
                                  </span>
                                </td>
                                <td>{premios}</td>
                              </tr>
                              <tr className="text-center roboto-medium">
                                <td>
                                  <span className="">
                                    <font color="blue">Premios Pagados</font>
                                  </span>
                                </td>
                                <td>{ppagados}</td>
                              </tr>
                              <tr className="text-center roboto-medium">
                                <td>
                                  <span className="">
                                    <font color="blue">Premios por Pagar</font>
                                  </span>
                                </td>
                                <td>{npagados}</td>
                              </tr>
                              <tr
                                className="text-center roboto-medium"
                                bgcolor="black"
                              ></tr>
                            </tbody>
                          </table>

                          <br></br>
                          <br></br>
                          <br></br>
                          <br></br>
                          <hr></hr>
                          <table align="right">
                            <tr>
                              <td>
                                <p>
                                  &nbsp; &nbsp;
                                  <button
                                    type="button"
                                    className="btn btn-raised btn-primary btn-sm"
                                    onClick={() => {
                                    setModal(true);
                                    }}
                                    id="exa3"
                                  >
                                    <i className="fas fa-print"></i> &nbsp; VER
                                    DETALLES
                                  </button>
                                  <div
                                    className="modal fade hidden"
                                    id="example3"
                                    tabIndex="-1"
                                    role="dialog"
                                    aria-labelledby="exampleModalLabel3"
                                    aria-hidden="true"
                                  >
                                    <div
                                      className="modal-dialog modal-lg"
                                      role="document"
                                    >
                                      <div className="modal-content">
                                        <div className="modal-header">
                                          <h5
                                            className="modal-title"
                                            id="exampleModalLabel"
                                          >
                                            RESULTADOS DE AGENCIA ={">"} {fecha}
                                          </h5>
                                          <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            <span aria-hidden="true">
                                              &times;
                                            </span>
                                          </button>
                                        </div>
                                        <div className="modal-body">
                                          <div className="container-fluid2">
                                            <div className="container-dentro3">
                                              <table width="100%">
                                                <tr>
                                                  <td width="100%" valign="top">
                                                    PREMIOS
                                                    <table className="table table-dark table-sm">
                                                      <thead>
                                                        <tr className="text-center roboto-medium">
                                                          <th id="fondolote">
                                                            #
                                                          </th>
                                                          <th id="fondolote">
                                                            Caja
                                                          </th>
                                                          <th id="fondolote">
                                                            Fecha
                                                          </th>
                                                          <th id="fondolote">
                                                            Monto
                                                          </th>
                                                          <th id="fondolote">
                                                            Premio
                                                          </th>

                                                          <th id="fondolote">
                                                            Pagado
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {listapremios.map(
                                                          (val, key) => {
                                                            return (
                                                              <tr
                                                                key={val.id}
                                                                className="text-center"
                                                              >
                                                                <td className="mousehover">
                                                                  {val.idticket}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {
                                                                    val.idusuario
                                                                  }
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.fecha}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.monto}
                                                                </td>
                                                                <td className="mousehover">
                                                                  {val.premio}
                                                                </td>

                                                                <td>
                                                                  {val.estatus ==
                                                                  "pagado" ? (
                                                                    <span>
                                                                      <i className="fa-solid fa-circle-check colorgreen"></i>
                                                                    </span>
                                                                  ) : (
                                                                    <span></span>
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="modal-footer">
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-dismiss="modal"
                                            id="cerrarmodal3"
                                          >
                                            Cerrar
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/*  MODAL ANULACION*/}
                                  &nbsp;
                                  <button
                                    type="submit"
                                    className="btn btn-raised btn-info btn-sm"
                                    onClick={imprimircuadre}
                                  >
                                    <i className="fas fa-print"></i> &nbsp;
                                    IMPRIMIR
                                  </button>
                                </p>
                              </td>
                            </tr>
                          </table>
                          <ToastContainer />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {modal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-12 bg-black rounded-t-lg place-content-center flex flex-grow text-white text-md font-semibold">
                  <span className="mt-2 pl-2">RESULTADOS DE AGENCIA ={">"} {fecha}</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1">
                    <div className="flex flex-row flex-wrap">
                      <div className="flex flex-row text-blue-950 text-md font-bold">
                      <div className="modal-body">
                                              <div className="container-fluid2">
                                                <div className="container-dentro3">
                                                  <table width="100%">
                                                    <tr>
                                                      <td width="100%" valign="top">
                                                        PREMIOS
                                                        <table className="table table-dark table-sm">
                                                          <thead>
                                                            <tr className="text-center roboto-medium">
                                                              <th id="fondolote">
                                                                #
                                                              </th>
                                                              <th id="fondolote">
                                                                Caja
                                                              </th>
                                                              <th id="fondolote">
                                                                Fecha
                                                              </th>
                                                              <th id="fondolote">
                                                                Monto
                                                              </th>
                                                              <th id="fondolote">
                                                                Premio
                                                              </th>
    
                                                              <th id="fondolote">
                                                                Pagado
                                                              </th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {listapremios.map(
                                                              (val, key) => {
                                                                return (
                                                                  <tr
                                                                    key={val.id}
                                                                    className="text-center"
                                                                  >
                                                                    <td className="mousehover">
                                                                      {val.idticket}
                                                                    </td>
                                                                    <td className="mousehover">
                                                                      {
                                                                        val.idusuario
                                                                      }
                                                                    </td>
                                                                    <td className="mousehover">
                                                                      {val.fecha}
                                                                    </td>
                                                                    <td className="mousehover">
                                                                      {val.monto}
                                                                    </td>
                                                                    <td className="mousehover">
                                                                      {val.premio}
                                                                    </td>
    
                                                                    <td>
                                                                      {val.estatus ==
                                                                      "pagado" ? (
                                                                        <span>
                                                                          <i className="fa-solid fa-circle-check colorgreen"></i>
                                                                        </span>
                                                                      ) : (
                                                                        <span></span>
                                                                      )}
                                                                    </td>
                                                                  </tr>
                                                                );
                                                              }
                                                            )}
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                      </div>
                    </div>
    
            
    
                  
                  </div>
                </div>
                <div className="h-14 bg-black rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <button
                    type="button"
                    onClick={() => {
                      setModal(false);
                      
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      </>
    );

    
  }

  if (formutipo == "semana") {
    console.log("LLEGO EL dias");
    return (
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-calendar"></i> Configurar Dias de la
                      Semana
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="campoloteriase"
                                name="campoloteriase"
                                maxlength="27"
                                defaultValue={`${nombreloteria} ==> ${tipoloteria}`}
                                disabled="true"
                              />
                            </div>
                          </div>
                          {datoid.map((val, key) => {
                            return (
                              <div className="col-12 col-md-12">
                                <div className="form-group">
                                  <div>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        defaultChecked={val.hlunes}
                                        id="hlunes"
                                      />
                                      <span className="slider"></span>
                                    </label>{" "}
                                    <span className="horastyle">Lunes</span>
                                  </div>
                                  <div>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        id="hmartes"
                                        defaultChecked={val.hmartes}
                                      />
                                      <span className="slider"></span>
                                    </label>{" "}
                                    <span className="horastyle">Martes</span>
                                  </div>
                                  <div>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        defaultChecked={val.hmiercoles}
                                        id="hmiercoles"
                                      />
                                      <span className="slider"></span>
                                    </label>{" "}
                                    <span className="horastyle">Miercoles</span>
                                  </div>
                                  <div>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        defaultChecked={val.hjueves}
                                        id="hjueves"
                                      />
                                      <span className="slider"></span>
                                    </label>{" "}
                                    <span className="horastyle">Jueves</span>
                                  </div>
                                  <div>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        defaultChecked={val.hviernes}
                                        id="hviernes"
                                      />
                                      <span className="slider"></span>
                                    </label>{" "}
                                    <span className="horastyle">Viernes</span>
                                  </div>
                                  <div>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        defaultChecked={val.hsabado}
                                        id="hsabado"
                                      />
                                      <span className="slider"></span>
                                    </label>{" "}
                                    <span className="horastyle">Sabado</span>
                                  </div>
                                  <div>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        defaultChecked={val.hdomingo}
                                        id="hdomingo"
                                      />
                                      <span className="slider"></span>
                                    </label>{" "}
                                    <span className="horastyle">Domingo</span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                          <br></br>
                          <br></br>
                          <br></br>
                          <br></br>
                          <table align="center">
                            <tr>
                              <td>
                                <p className="text-center">
                                  &nbsp; &nbsp;
                                  <button
                                    type="submit"
                                    className="btn btn-raised btn-info btn-sm"
                                    onClick={modificarsemana}
                                  >
                                    <i className="far fa-save"></i> &nbsp;
                                    GUARDAR CAMBIOS
                                  </button>
                                </p>
                              </td>
                            </tr>
                          </table>
                          <ToastContainer />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  if (
    formutipo == "h" ||
    formutipo == "r" ||
    formutipo == "l" ||
    formutipo == "hh"
  ) {
    console.log("LLEGO EL HORARIO2");
    return (
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-clock"></i> Cargando Formulario
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="campoloteria7"
                                name="campoloteria7"
                                maxlength="27"
                                value="POR FAVOR ESPERE...."
                                disabled="true"
                              />
                            </div>
                          </div>

                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  if (formutipo == "d") {
    console.log("LLEGO EL EDITAR2");
    return (
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-clock"></i> Cargando Formulario
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="campoloteriad"
                                name="campoloteriad"
                                maxlength="27"
                                defaultValue="POR FAVOR ESPERE...."
                                disabled="true"
                              />
                            </div>
                          </div>

                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  if (formutipo == "n") {
    console.log("LLEGO EL EDITAR2");
    return (
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-clock"></i> Cargando Formulario
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="campoloteria"
                                name="campoloteria"
                                maxlength="27"
                                defaultValue="POR FAVOR ESPERE...."
                                disabled="true"
                              />
                            </div>
                          </div>

                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  if (formutipo == "s") {
    console.log("LLEGO EL EDITAR2");
    return (
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-clock"></i> Cargando Formulario
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="campoloterias"
                                name="campoloterias"
                                maxlength="27"
                                defaultValue="POR FAVOR ESPERE...."
                                disabled="true"
                              />
                            </div>
                          </div>

                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }




};

export default Formureports;
