import React from "react";
import Axios from "axios";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import { HashRouter, Route, Routes } from "react-router-dom";
import Hora from "./hora";
import userEvent from "@testing-library/user-event";

const Menu = ({ tomadia }) => {
  const [usernivel, setUsernivel] = useState("No");
  const [name, setNameUser] = useState("No");
  const [opcion, setOpcion] = useState("");

  const [diasemana, setDiasemana] = useState(0);

  const removelocalstore = () => {
    Swal.fire({
      title: "Estas seguro de Salir?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Quiero Salir",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Ha cerrado Sesion",
          text: "Hasta Luego",
          icon: "success",
        });
        localStorage.removeItem("token");
        localStorage.removeItem("usu");
        localStorage.removeItem("veloci");
        localStorage.removeItem("velocy");
        setTimeout(() => {
          window.location = "/";
        }, 2000);
      }
    });
  };

  const chequeardatos = () => {
    const username = localStorage.getItem("usu");
    const data = {
      username: username,
    };
    fetch(`${process.env.REACT_APP_API}/datos`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        setUsernivel(result.message);
        setNameUser(result.name);
        if (result.message) {
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const tomardia = (mensaje) => {
    setDiasemana(mensaje);
  };

  useEffect(() => {
    chequeardatos();
  }, []);

  useEffect(() => {
    tomadia(diasemana);
  }, [diasemana]);

  useEffect(() => {
    console.log(opcion);
    if (opcion === "ventas") {
      window.location = "/";
    }
    if (opcion === "loterias") {
      
window.location.href = "/#/configl";
window.location.reload();
    }
    if (opcion === "usuarios") {
     
      window.location = "/#/regusers";
      window.location.reload();
    }
    if (opcion === "resultados") {
      window.location = "/#/results";
      window.location.reload();
    }
    if (opcion === "reportes") {
      window.location = "/#/reports";
      window.location.reload();
    }
    if (opcion === "impresion") {
      
      window.location = "/#/imprimir";
      window.location.reload();
    }
  }, [opcion]);

  useEffect(() => {console.log(usernivel)}, [usernivel]);

  return (
    <>
      {usernivel == "administrador" ? (
        <>
          <div className="flex flex-row flex-wrap place-content-between  bg-cyan-950 pt-[4px] pl-[10px] h-8 rounded-b-2xl font-bold text-white text-sm">
            <div className="flex flex-row">
              <span>Bienvenid(@), Administrador</span>
              <div class="flex pl-2 mt-[-10px]">
                <select
                  id="states"
                  onChange={(e) => setOpcion(e.target.value)}
                  class="bg-gray-50 border mt-0 border-gray-300 text-gray-900 text-sm rounded-lg border-s-gray-100 dark:border-s-gray-700 border-s-2 focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected value="">
                    Entrar en:
                  </option>
                  <option value="ventas">Sistema de Venta</option>
                  <option value="loterias">Registro de Loterias</option>
                  <option value="usuarios">Usuarios/Agencias</option>
                  <option value="resultados">Registro de Resultados</option>
                  <option value="reportes">Generador de Reportes</option>
                  <option value="impresion">Impresion</option>
                </select>
              </div>
            </div>
            <div className="pr-6 flex flex-row flex-wrap">
              <Hora tomardia={tomardia} />
              &nbsp;
              <button onClick={removelocalstore}>
                <svg
                  class="w-6 h-6 text-red-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.403 5H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-6.403a3.01 3.01 0 0 1-1.743-1.612l-3.025 3.025A3 3 0 1 1 9.99 9.768l3.025-3.025A3.01 3.01 0 0 1 11.403 5Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M13.232 4a1 1 0 0 1 1-1H20a1 1 0 0 1 1 1v5.768a1 1 0 1 1-2 0V6.414l-6.182 6.182a1 1 0 0 1-1.414-1.414L17.586 5h-3.354a1 1 0 0 1-1-1Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {usernivel == "caja" ? (
        <>
          <div className="flex flex-row flex-wrap place-content-between  bg-cyan-950 pt-[4px] pl-[10px] h-8 rounded-b-2xl font-bold text-white text-sm">
            <div className="flex flex-row">
              <span>Bienvenid(@), {name}</span>
              <div class="flex pl-2 mt-[-10px]">
                <select
                  id="states"
                  onChange={(e) => setOpcion(e.target.value)}
                  class="bg-gray-50 border mt-0 border-gray-300 text-gray-900 text-sm rounded-lg border-s-gray-100 dark:border-s-gray-700 border-s-2 focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected value="">
                    Entrar en:
                  </option>
                  <option value="ventas">Sistema de Venta</option>
                  <option value="reportes">Generador de Reportes</option>
                  <option value="impresion">Configuracion de Impresora</option>
                </select>
              </div>
            </div>
            <div className="pr-6 flex flex-row flex-wrap">
              <Hora tomardia={tomardia} />
              &nbsp;
              <button onClick={removelocalstore}>
                <svg
                  class="w-6 h-6 text-red-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.403 5H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-6.403a3.01 3.01 0 0 1-1.743-1.612l-3.025 3.025A3 3 0 1 1 9.99 9.768l3.025-3.025A3.01 3.01 0 0 1 11.403 5Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M13.232 4a1 1 0 0 1 1-1H20a1 1 0 0 1 1 1v5.768a1 1 0 1 1-2 0V6.414l-6.182 6.182a1 1 0 0 1-1.414-1.414L17.586 5h-3.354a1 1 0 0 1-1-1Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

{usernivel == "vendedor" ? (
        <>
          <div className="flex flex-row flex-wrap place-content-between  bg-cyan-950 pt-[4px] pl-[10px] h-8 rounded-b-2xl font-bold text-white text-sm">
            <div className="flex flex-row">
              <span>Bienvenid(@), {name}</span>
              <div class="flex pl-2 mt-[-10px]">
                <select
                  id="states"
                  onChange={(e) => setOpcion(e.target.value)}
                  class="bg-gray-50 border mt-0 border-gray-300 text-gray-900 text-sm rounded-lg border-s-gray-100 dark:border-s-gray-700 border-s-2 focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected value="">
                    Entrar en:
                  </option>
                  <option value="ventas">Sistema de Venta</option>
                  <option value="reportes">Generador de Reportes</option>
                </select>
              </div>
            </div>
            <div className="pr-6 flex flex-row flex-wrap">
              <Hora tomardia={tomardia} />
              &nbsp;
              <button onClick={removelocalstore}>
                <svg
                  class="w-6 h-6 text-red-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.403 5H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-6.403a3.01 3.01 0 0 1-1.743-1.612l-3.025 3.025A3 3 0 1 1 9.99 9.768l3.025-3.025A3.01 3.01 0 0 1 11.403 5Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M13.232 4a1 1 0 0 1 1-1H20a1 1 0 0 1 1 1v5.768a1 1 0 1 1-2 0V6.414l-6.182 6.182a1 1 0 0 1-1.414-1.414L17.586 5h-3.354a1 1 0 0 1-1-1Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Menu;
