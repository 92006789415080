import React, { useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment";

const Listaloteresults = ({ myuser, activarchequeo, addvalor, addtipo }) => {
  const [listalo, setListalo] = useState([]);

  console.log(myuser);
  var datali = new Object();

  const getloterias = () => {
    Axios.get(`${process.env.REACT_APP_API}/listaloterias/`).then((response) => {
      datali = response.data;

      setListalo(response.data);
    });
  };

  useEffect(() => {
    getloterias();
  }, [activarchequeo]);

  return (
    <div>
        <div className="">
        <div
              id="fondolote"
              className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm"
            >
              LISTADO DE LOTERIAS
            </div>
              <table class="w-[100%] table1 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <tbody>
                  <tr className="w-full">
                    <th scope="col" class="px-6 py-1">
                      Acciones
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Nombre
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Tipo
                    </th>
                  </tr>

                  {listalo.map((val, key) => {
                    return (
                      <tr key={val.id} className="text-center">
                        <td>
                        <button
                            type="button"
                            title="Mostrar Resultados"
                            onClick={() => {
                                addvalor(val.id);
        
                                addtipo("h");
                              }}
                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-1.5 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                           <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path d="M13.5 2c-.178 0-.356.013-.492.022l-.074.005a1 1 0 0 0-.934.998V11a1 1 0 0 0 1 1h7.975a1 1 0 0 0 .998-.934l.005-.074A7.04 7.04 0 0 0 22 10.5 8.5 8.5 0 0 0 13.5 2Z"/>
  <path d="M11 6.025a1 1 0 0 0-1.065-.998 8.5 8.5 0 1 0 9.038 9.039A1 1 0 0 0 17.975 13H11V6.025Z"/>
</svg>



                          </button>

                          
                        </td>
                        <td>
                        <span id={"loter" + val.id} className="">
                    {" "}
                    {val.name}
                  </span>
                        </td>
                        <td className=" text-[16px]">{val.tipo}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>


    
    </div>
  );
};

export default Listaloteresults;