import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { useState, useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";

const Formuderecho = ({
  addmensaje,
  formutipo,
  valorid,
  parte1,
  parte2,
  activarchequeo,
}) => {
  const [listalo2, setListalo2] = useState([]);
  const [listalo3, setListalo3] = useState([]);
  const [listacaja, setListacaja] = useState([]);
  const [listalimites, setListalimites] = useState([]);
  const [datoid, setDatoid] = useState([]);

  const [nombres, setNombres] = useState("");
  const [nombreusuario, setNombreUsuario] = useState("");

  const [p1, setP1] = useState([]);
  const [p2, setP2] = useState([]);

  const [minagencia, setMinagencia] = useState(0);
  const [maxagencia, setMaxagencia] = useState(0);
  const [minticket, setMinticket] = useState(0);
  const [maxticket, setMaxticket] = useState(0);
  const [anulacion, setAnulacion] = useState(0);
  const [vencimiento, setVencimiento] = useState(0);
  const [imprimir, setImprimir] = useState("");

  const [modal, setModal] =  useState(false);
  const [modal2, setModal2] =  useState(false);
  const [modal3, setModal3] =  useState(false);



  var contador = 0;

  const mostrar = () => {
  //$("#cerrarmodal2").click();
  };

  const mostrar4 = () => {
    //$("#cerrarmodal4").click();
  };

  const mostrar5 = () => {
    //$("#cerrarmodal5").click();
  };

  const getusers2 = () => {
    Axios.get(`${process.env.REACT_APP_API}/listausers/`).then((response) => {
      setListalo2(response.data);
    });
  };

  const getlistaloterias = () => {
    Axios.get(`${process.env.REACT_APP_API}/listaloterias/`).then((response) => {
      setListalo3(response.data);
    });
  };

  const getcajas = (id) => {
    const data = {
      wid: id,
    };
    //enviar datos
    fetch(`${process.env.REACT_APP_API}/listacajas`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setListacaja(result);
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  const getlistalimites = (id) => {
    const data = {
      wid: id,
    };
    //enviar datos
    fetch(`${process.env.REACT_APP_API}/listalimites`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setListalimites(result);
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  const obtenernombreloterias = (id) => {
    var resultado = "Sin valor";
    for (let i = 0; i < listalo3.length; i++) {
      if (listalo3[i].id == id) {
        resultado = listalo3[i].name;
      }
    }
    return resultado;
  };

  const getnombres = (valorid) => {
    for (let i = 0; i < listalo2.length; i++) {
      if (listalo2[i].id == valorid) {
        setNombreUsuario(listalo2[i].username);
        setNombres(listalo2[i].name);

        setDatoid([
          {
            username: listalo2[i].username,
            name: listalo2[i].name,
          },
        ]);
      }
    }
  };

  const enviarmensaje = (mensaje) => {
    addmensaje(mensaje);
  };

  const msjsave = (mensajesave, tipodemensaje) => {
    if (tipodemensaje == "save") {
      toast.success(mensajesave, {});
    }
    if (tipodemensaje == "error") {
      toast.error(mensajesave, {});
    }
    if (tipodemensaje == "warning") {
      toast.warning(mensajesave, {});
    }
    if (tipodemensaje == "info") {
      toast.info(mensajesave, {});
    }
  };

  const limpiarcampos = () => {
    document.getElementById("cusuario").value = "";
    document.getElementById("cnombres").value = "";
    document.getElementById("cclave1").value = "";
    document.getElementById("cclave2").value = "";
  };

  const guardarcaja = () => {
    var wusuario = document.getElementById("cusuario").value;
    var wnombres = document.getElementById("cnombres").value;
    var wclave1 = document.getElementById("cclave1").value;
    var wclave2 = document.getElementById("cclave2").value;

    if (wclave1 != wclave2) {
  
      
      document.getElementById("errorc").innerHTML = "Las Claves no Coinciden";
      document.getElementById("errorc").className = "text-red-500 text-[16px]";
    } else {
      if (wclave1 == "" || wclave2 == "" || wnombres == "" || wusuario == "") {
        document.getElementById("errorc").innerHTML = "Hay Campos Vacios";
        document.getElementById("errorc").className =
          "text-red-500 text-[16px]";
      } else {
        const data = {
          wusuario: wusuario,
          wnombres: wnombres,
          wclave1: wclave1,
          wclave2: wclave2,
          widusuario: valorid,
        };
        //enviar datos
        fetch(`${process.env.REACT_APP_API}/guardarcaja`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((result) => {
            if (result) {
              if (result.message == "existe") {
                document.getElementById("errorc").innerHTML =
                  "El Usuario (" +
                  document.getElementById("cusuario").value +
                  ") Ya existe, intente con otro";
                document.getElementById("errorc").className =
                  "text-red-500 text-[16px]";
              }
              if (result.message == "guardado") {
                document.getElementById("errorc").innerHTML =
                "Datos Guardados con Exito";
              document.getElementById("errorc").className =
                "text-green-500 text-[16px]";
                setTimeout(() => {
                  mostrar();
                  getcajas(valorid);
                  document.getElementById("errorc").innerHTML = "";
                  setModal(false);
                  limpiarcampos();
                }, 2000);
              }
              if (result.message == "error") {
                document.getElementById("errorc").innerHTML = "";
                document.getElementById("errorc").innerHTML =
                  "ERROR DE CONEXION";
              }

              //ACTUAL USUARIOS
            } else {
              console.log("ERROR DE CONEXION");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    //fin de envio
  };

  const modificarhorario = () => {
    for (let i = 0; i < 12; i++) {
      if (document.getElementById("horario" + i).checked == true) {
        var estatus = 1;
      } else {
        var estatus = 0;
      }

      Axios.post(`${process.env.REACT_APP_API}/modificarhorario/`, {
        id: p1[i].id,
        hora: p1[i].hora,
        estatus: estatus,
      }).then(() => {});
    }

    for (let i = 0; i < 12; i++) {
      if (document.getElementById("horario" + (i + 12)).checked == true) {
        var estatus = 1;
      } else {
        var estatus = 0;
      }
      Axios.post(`${process.env.REACT_APP_API}/modificarhorario`, {
        id: p2[i].id,
        hora: p2[i].hora,
        estatus: estatus,
      }).then(() => {});
    }

    msjsave("Datos Modificados con exito", "save");
    setTimeout(() => {
      enviarmensaje("n");
    }, 1000);
  };

  const modificarloteria = () => {
    var errorsi = 0;
    var nombreloteria = ""
    if (nombreloteria == document.getElementById("campoloteria").value) {
      Axios.post(`${process.env.REACT_APP_API}/modificarloteria/`, {
        id: valorid,
        name: document.getElementById("campoloteria").value,
        tipo: document.getElementById("campotipo").value,
        proporcion: document.getElementById("campoproporcion").value,
      }).then(() => {});
    } else {
      for (let i = 0; i < listalo2.length; i++) {
        if (listalo2[i].name == document.getElementById("campoloteria").value) {
          errorsi = 1;
          msjsave(
            "El nuevo nombre que intenta ingresar ya existe, intente con otro",
            "error"
          );
        }
      }
      if (errorsi == 0) {
        Axios.post(`${process.env.REACT_APP_API}/modificarloteria/`, {
          id: valorid,
          name: document.getElementById("campoloteria").value,
          tipo: document.getElementById("campotipo").value,
          proporcion: document.getElementById("campoproporcion").value,
        }).then(() => {});
      }
    }

    if (errorsi == 0) {
      msjsave("Datos Modificados con exito", "save");
      setTimeout(() => {
        enviarmensaje("n");
      }, 2000);
    }
  };

  const modificarsemana = () => {
    var hlunes, hmartes, hmiercoles, hjueves, hviernes, hsabado, hdomingo;
    if (document.getElementById("hlunes").checked == true) {
      hlunes = 1;
    } else {
      hlunes = 0;
    }
    if (document.getElementById("hmartes").checked == true) {
      hmartes = 1;
    } else {
      hmartes = 0;
    }
    if (document.getElementById("hmiercoles").checked == true) {
      hmiercoles = 1;
    } else {
      hmiercoles = 0;
    }
    if (document.getElementById("hjueves").checked == true) {
      hjueves = 1;
    } else {
      hjueves = 0;
    }
    if (document.getElementById("hviernes").checked == true) {
      hviernes = 1;
    } else {
      hviernes = 0;
    }
    if (document.getElementById("hsabado").checked == true) {
      hsabado = 1;
    } else {
      hsabado = 0;
    }
    if (document.getElementById("hdomingo").checked == true) {
      hdomingo = 1;
    } else {
      hdomingo = 0;
    }
    Axios.post(`${process.env.REACT_APP_API}/modificarsemana/`, {
      id: valorid,
      hlunes: hlunes,
      hmartes: hmartes,
      hmiercoles: hmiercoles,
      hjueves: hjueves,
      hviernes: hviernes,
      hsabado: hsabado,
      hdomingo: hdomingo,
    }).then(() => {});

    msjsave("Dias de la Semana Configurados Exitosamente", "save");
    setTimeout(() => {
      enviarmensaje("n");
    }, 1000);
  };

  const validarexistencia = (wname) => {
    var datosl = listalo2;
    var resultado = false;
    for (let i = 0; i < datosl.length; i++) {
      if (datosl[i].name == wname) {
        resultado = true;
      }
    }
    return resultado;
  };

  const obtenerminutodif = (horapasada) => {
    var hora = horapasada;
    var date5 = new Date("2024-11-05T" + hora);
    //CALCULANDO LOS MINUTOS QUE FALTAN
    date5.setMinutes(parseInt(document.getElementById("minutos").value));

    var hora1 = "";
    var minuto1 = "";
    var segundo1 = "";
    if (date5.getHours() < 10) {
      hora1 = "0" + date5.getHours();
    } else {
      hora1 = date5.getHours();
    }
    if (date5.getMinutes() < 10) {
      minuto1 = "0" + date5.getMinutes();
    } else {
      minuto1 = date5.getMinutes();
    }
    if (date5.getSeconds() < 10) {
      segundo1 = "0" + date5.getSeconds();
    } else {
      segundo1 = date5.getSeconds();
    }
    return hora1 + ":" + minuto1 + ":" + segundo1;
    //FIN DE CALCULO
  };

  const cambiarminutos = () => {
    for (let i = 0; i < 12; i++) {
      obtenerminutodif(p1[i].hora);
    }
    for (let i = 0; i < 12; i++) {
      obtenerminutodif(p2[i].hora);
    }

    var dato1 = [
      {
        id1: 0,
        id: p1[0].id,
        idloteria: p1[0].idloteria,
        hora: obtenerminutodif(p1[0].hora),
        estatus: p1[0].estatus,
      },
      {
        id1: 1,
        id: p1[1].id,
        idloteria: p1[1].idloteria,
        hora: obtenerminutodif(p1[1].hora),
        estatus: p1[1].estatus,
      },
      {
        id1: 2,
        id: p1[2].id,
        idloteria: p1[2].idloteria,
        hora: obtenerminutodif(p1[2].hora),
        estatus: p1[2].estatus,
      },
      {
        id1: 3,
        id: p1[3].id,
        idloteria: p1[3].idloteria,
        hora: obtenerminutodif(p1[3].hora),
        estatus: p1[3].estatus,
      },
      {
        id1: 4,
        id: p1[4].id,
        idloteria: p1[4].idloteria,
        hora: obtenerminutodif(p1[4].hora),
        estatus: p1[4].estatus,
      },
      {
        id1: 5,
        id: p1[5].id,
        idloteria: p1[5].idloteria,
        hora: obtenerminutodif(p1[5].hora),
        estatus: p1[5].estatus,
      },
      {
        id1: 6,
        id: p1[6].id,
        idloteria: p1[6].idloteria,
        hora: obtenerminutodif(p1[6].hora),
        estatus: p1[6].estatus,
      },
      {
        id1: 7,
        id: p1[7].id,
        idloteria: p1[7].idloteria,
        hora: obtenerminutodif(p1[7].hora),
        estatus: p1[7].estatus,
      },
      {
        id1: 8,
        id: p1[8].id,
        idloteria: p1[8].idloteria,
        hora: obtenerminutodif(p1[8].hora),
        estatus: p1[8].estatus,
      },
      {
        id1: 9,
        id: p1[9].id,
        idloteria: p1[9].idloteria,
        hora: obtenerminutodif(p1[9].hora),
        estatus: p1[9].estatus,
      },
      {
        id1: 10,
        id: p1[10].id,
        idloteria: p1[10].idloteria,
        hora: obtenerminutodif(p1[10].hora),
        estatus: p1[10].estatus,
      },
      {
        id1: 11,
        id: p1[11].id,
        idloteria: p1[11].idloteria,
        hora: obtenerminutodif(p1[11].hora),
        estatus: p1[11].estatus,
      },
    ];

    var dato3 = [
      {
        id2: 12,
        id: p2[0].id,
        idloteria: p2[0].idloteria,
        hora: obtenerminutodif(p2[0].hora),
        estatus: p2[0].estatus,
      },
      {
        id2: 13,
        id: p2[1].id,
        idloteria: p2[1].idloteria,
        hora: obtenerminutodif(p2[1].hora),
        estatus: p2[1].estatus,
      },
      {
        id2: 14,
        id: p2[2].id,
        idloteria: p2[2].idloteria,
        hora: obtenerminutodif(p2[2].hora),
        estatus: p2[2].estatus,
      },
      {
        id2: 15,
        id: p2[3].id,
        idloteria: p2[3].idloteria,
        hora: obtenerminutodif(p2[3].hora),
        estatus: p2[3].estatus,
      },
      {
        id2: 16,
        id: p2[4].id,
        idloteria: p2[4].idloteria,
        hora: obtenerminutodif(p2[4].hora),
        estatus: p2[4].estatus,
      },
      {
        id2: 17,
        id: p2[5].id,
        idloteria: p2[5].idloteria,
        hora: obtenerminutodif(p2[5].hora),
        estatus: p2[5].estatus,
      },
      {
        id2: 18,
        id: p2[6].id,
        idloteria: p2[6].idloteria,
        hora: obtenerminutodif(p2[6].hora),
        estatus: p2[6].estatus,
      },
      {
        id2: 19,
        id: p2[7].id,
        idloteria: p2[7].idloteria,
        hora: obtenerminutodif(p2[7].hora),
        estatus: p2[7].estatus,
      },
      {
        id2: 20,
        id: p2[8].id,
        idloteria: p2[8].idloteria,
        hora: obtenerminutodif(p2[8].hora),
        estatus: p2[8].estatus,
      },
      {
        id2: 21,
        id: p2[9].id,
        idloteria: p2[9].idloteria,
        hora: obtenerminutodif(p2[9].hora),
        estatus: p2[9].estatus,
      },
      {
        id2: 22,
        id: p2[10].id,
        idloteria: p2[10].idloteria,
        hora: obtenerminutodif(p2[10].hora),
        estatus: p2[10].estatus,
      },
      {
        id2: 23,
        id: p2[11].id,
        idloteria: p2[11].idloteria,
        hora: obtenerminutodif(p2[11].hora),
        estatus: p2[11].estatus,
      },
    ];
    setP1(dato1);
    setP2(dato3);
  };

  const confirmardelcaja = (id) => {
    Swal.fire({
      title: "Eliminar Caja",
      text: "Deseas Eliminar esta Caja!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Eliminando Caja",
          text: "Eliminacion Exitosa",
          icon: "success",
        });
        deletemcajas(id);
      }
    });
  };

  const deletemcajas = (id) => {
    const data = {
      wid: id,
    };
    //enviar datos
    fetch(`${process.env.REACT_APP_API}/delcajas`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          getcajas(valorid);
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  const configurarcupos = () => {
    var limite_c, jugadamin, jugadamax;
    limite_c = document.getElementById("limitecupos").value;
    jugadamin = document.getElementById("jugadaminima").value;
    jugadamax = document.getElementById("jugadamaxima").value;

    if (limite_c == "" || jugadamin == "" || jugadamax == "") {
      document.getElementById("errorconfig").innerHTML = "Hay Campos Vacios";
      document.getElementById("errorconfig").className = "text-red-500 text-[16px]";
    } else {
      for (let i = 0; i < listalimites.length; i++) {
        if (document.getElementById("loteria" + listalimites[i].id).checked) {
          const data = {
            limitec: limite_c,
            jugadamin: jugadamin,
            jugadamax: jugadamax,
            idlimite: document.getElementById("loteria" + listalimites[i].id)
              .value,
          };

          //enviar datos
          fetch(`${process.env.REACT_APP_API}/modificarlimites`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then((result) => {
              if (result) {
                console.log("SUS DATOS HAN SIDO GUARDADOS");
              } else {
                console.log("ERROR DE CONEXION");
              }
            })
            .catch((error) => {
              console.log(error);
            });
          //fin de envio
        }
      }
      document.getElementById("errorconfig").innerHTML = "Datos Guardados con Exito";
      document.getElementById("errorconfig").className = "text-green-500 text-[16px]";
      setTimeout(() => {
        document.getElementById("errorconfig").innerHTML = "";
        document.getElementById("limitecupos").value = "";
        document.getElementById("jugadaminima").value = "";
        document.getElementById("jugadamaxima").value = "";
        //mostrar4();
        getlistalimites(valorid);
      }, 2000);
    }
  };

  const configurardatos = () => {
    var jugadaminimaa,
      ventaminima,
      ventamaxima,
      imprimir,
      vencimiento,
      anulacion;
    jugadaminimaa = document.getElementById("jugadaminimaa").value;
    ventaminima = document.getElementById("ventaminima").value;
    ventamaxima = document.getElementById("ventamaxima").value;
    imprimir = document.getElementById("imprimirmensaje").value;
    vencimiento = document.getElementById("vencimiento").value;
    anulacion = document.getElementById("anulacion").value;

    if (
      jugadaminimaa == "" ||
      ventaminima == "" ||
      ventamaxima == "" ||
      imprimir == ""
    ) {
      document.getElementById("errorconfigeneral").innerHTML = "Hay Campos Vacios";
      document.getElementById("errorconfigeneral").className = "text-red-500 text-[16px]";
    } else {
      const data = {
        jugadamin: jugadaminimaa,
        ventaminima: ventaminima,
        ventamaxima: ventamaxima,
        imprimir: imprimir,
        vencimiento: vencimiento,
        anulacion: anulacion,
        idusuario: valorid,
      };

      //enviar datos
      fetch(`${process.env.REACT_APP_API}/modificarconfiguracion`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result) {
            console.log("SUS DATOS HAN SIDO GUARDADOS");
          } else {
            console.log("ERROR DE CONEXION");
          }
        })
        .catch((error) => {
          console.log(error);
        });
      //fin de envio

      document.getElementById("errorconfigeneral").innerHTML = "Guardado con Exito";
      document.getElementById("errorconfigeneral").className = "text-green-500 text-[16px]";
      setTimeout(() => {
        document.getElementById("errorconfigeneral").innerHTML = "";
        
      }, 2000);
    }
  };

  const seleccionarv = (id) => {
    if (id == 3) {
      return (
        <select className="form-control" id="vencimiento" name="vencimiento">
          <option selected value="3">
            3 Dias
          </option>
          <option value="4">4 Dias</option>
          <option value="5">5 Dias</option>
        </select>
      );
    }
    if (id == 4) {
      return (
        <select className="form-control" id="vencimiento" name="vencimiento">
          <option value="3">3 Dias</option>
          <option value="4" selected>
            4 Dias
          </option>
          <option value="5">5 Dias</option>
        </select>
      );
    }
    if (id == 5) {
      return (
        <select className="form-control" id="vencimiento" name="vencimiento">
          <option value="3">3 Dias</option>
          <option value="4">4 Dias</option>
          <option value="5" selected>
            5 Dias
          </option>
        </select>
      );
    }
  };

  const seleccionara = (id) => {
    if (id == 3) {
      return (
        <select className="form-control" id="anulacion" name="anulacion">
          <option selected value="3">
            3 Minutos
          </option>
          <option value="4">4 Minutos</option>
          <option value="5">5 Minutos</option>
        </select>
      );
    }
    if (id == 4) {
      return (
        <select className="form-control" id="anulacion" name="anulacion">
          <option value="3">3 Minutos</option>
          <option value="4" selected>
            4 Minutos
          </option>
          <option value="5">5 Minutos</option>
        </select>
      );
    }
    if (id == 5) {
      return (
        <select className="form-control" id="anulacion" name="anulacion">
          <option value="3">3 Minutos</option>
          <option value="4">4 Minutos</option>
          <option value="5" selected>
            5 Minutos
          </option>
        </select>
      );
    }
  };

  const mostrarconfiguracion = (id) => {
    const data = {
      wid: id,
    };

    //enviar datos
    fetch(`${process.env.REACT_APP_API}/mostrarconfiguracion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setImprimir(result[0].imprimir);
          setMinagencia(result[0].min_agencia);
          setMaxagencia(result[0].max_agencia);
          setMinticket(result[0].min_ticket);
          setMaxticket(result[0].max_ticket);
          setAnulacion(result[0].anulacion);
          setVencimiento(result[0].vencimiento);
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  useEffect(() => {
   console.log(valorid);
   //INSTRUCCIONES
   getcajas(valorid);
   getnombres(valorid);
   setP1(parte1);
   setP2(parte2);
   getlistalimites(valorid);
   mostrarconfiguracion(valorid);
   getlistaloterias();
 }, [valorid, parte1, parte2]);

  useEffect(() => {
    
    getusers2();
 
   }, []);

   useEffect(() => {
    console.log(listalo2)
   }, [listalo2]);
 


  return (<>
  <div>
    {valorid == 0 ? (<button
          type="button"
          
          class="text-white mt-2 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
        >
          <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path d="M5 3a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm0 12a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H5Zm12 0a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2h-2Zm0-12a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-2Z"/>
  <path fill-rule="evenodd" d="M10 6.5a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1ZM10 18a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1Zm-4-4a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1Zm12 0a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1Z" clip-rule="evenodd"/>
</svg>

          &nbsp; Nueva Caja
        </button>) : (<button
          type="button"
          onClick={(e) => {
            setModal(true);
          }}
          class="text-white mt-2 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
        >
          <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path d="M5 3a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm0 12a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H5Zm12 0a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2h-2Zm0-12a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-2Z"/>
  <path fill-rule="evenodd" d="M10 6.5a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1ZM10 18a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1Zm-4-4a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1Zm12 0a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1Z" clip-rule="evenodd"/>
</svg>

          &nbsp; Nueva Caja
        </button>)}
        

        <div className="flex flex-row">
          <div class="relative pt-2 w-full shadow-md sm:rounded-lg flex flex-col flex-wrap">
            <div
              id="fondolote"
              className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm"
            >
              REGISTRO DE CAJAS - AGENCIA ({nombreusuario})
            </div>
            <div className="">
              <table class="w-[100%] table11 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <tbody>
                  <tr className="w-full">
                    <th scope="col" class="px-6 py-1">
                      Acciones
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Usuario
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Nombres
                    </th>
                  </tr>

                  {listacaja.map((val, key) => {
                            return (
                              <tr key={val.id} className="text-center">
                                <td>
                                <button
                            type="button"
                            onClick={() => {
                              confirmardelcaja(val.id);
                            }}
                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-1.5 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                            <svg
                              class="w-[18px] h-[18px] text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button>
                                
                          <button
                            type="button"
                            onClick={() => {
                              //addvalor(val.id);

                              //addtipo("h");
                            }}
                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-1.5 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                            <svg
                              class="w-[18px] h-[18px] text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M5 8a4 4 0 1 1 7.796 1.263l-2.533 2.534A4 4 0 0 1 5 8Zm4.06 5H7a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h2.172a2.999 2.999 0 0 1-.114-1.588l.674-3.372a3 3 0 0 1 .82-1.533L9.06 13Zm9.032-5a2.907 2.907 0 0 0-2.056.852L9.967 14.92a1 1 0 0 0-.273.51l-.675 3.373a1 1 0 0 0 1.177 1.177l3.372-.675a1 1 0 0 0 .511-.273l6.07-6.07a2.91 2.91 0 0 0-.944-4.742A2.907 2.907 0 0 0 18.092 8Z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button>

                                </td>
                                <td>
                                  <span id={"loter" + val.id} className="">
                                    {" "}
                                    {val.username}
                                  </span>
                                </td>
                                <td>{val.name}</td>
                              </tr>
                            );
                          })}

                
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="flex flex-row">
          <div class="relative pt-2 w-full shadow-md sm:rounded-lg flex flex-col flex-wrap">
            <div
              id="fondolote"
              className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm"
            >
              CONFIGURACION DE JUGADAS - CAJA ({nombreusuario})
            </div>
            <div className="">
              <table class="w-[100%] table12 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <tbody>
                  <tr className="w-full" colSpan="4">
                    <th scope="col" class="px-6 py-1">
                     Loterias Disponibles Configurar
                    </th>
                  </tr>
                
                </tbody>
                <tbody>
                  <tr className="w-full">
                  <th scope="col" class="px-6 py-1">
                    
                    </th>
                    <th scope="col" class="px-6 py-1">
                     Loteria
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Min/Max
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Limite Cupos
                    </th>
                  </tr>

                  {listalimites.map((val, key) => {
                            return (
                              <tr key={val.id} className="text-center">
                                <td>
                                  <input
                                    type="checkbox"
                                    id={"loteria" + val.id}
                                    value={val.id}
                                    name={"loteria" + val.id}
                                  />
                                  &nbsp;
                                </td>
                                <td align="left">
                                  <label htmlFor={"loteria" + val.id}>
                                    <span id={"cloter" + val.id} className="">
                                      {" "}
                                      {obtenernombreloterias(val.idloteria)}
                                    </span>
                                  </label>
                                </td>
                                <td>{`${val.min_jugada}/${val.max_jugada}`}</td>
                                <td>{val.limite_cupos}</td>
                              </tr>
                            );
                          })}

                

                
                </tbody>
              </table>
              {valorid == 0 ? (<button
          type="button"
          
          class="text-white mt-2 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
        >
          <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M7.58209 8.96025 9.8136 11.1917l-1.61782 1.6178c-1.08305-.1811-2.23623.1454-3.07364.9828-1.1208 1.1208-1.32697 2.8069-.62368 4.1363.14842.2806.42122.474.73509.5213.06726.0101.1347.0133.20136.0098-.00351.0666-.00036.1341.00977.2013.04724.3139.24069.5867.52125.7351 1.32944.7033 3.01552.4971 4.13627-.6237.8375-.8374 1.1639-1.9906.9829-3.0736l4.8107-4.8108c1.0831.1811 2.2363-.1454 3.0737-.9828 1.1208-1.1208 1.3269-2.80688.6237-4.13632-.1485-.28056-.4213-.474-.7351-.52125-.0673-.01012-.1347-.01327-.2014-.00977.0035-.06666.0004-.13409-.0098-.20136-.0472-.31386-.2406-.58666-.5212-.73508-1.3294-.70329-3.0155-.49713-4.1363.62367-.8374.83741-1.1639 1.9906-.9828 3.07365l-1.7788 1.77875-2.23152-2.23148-1.41419 1.41424Zm1.31056-3.1394c-.04235-.32684-.24303-.61183-.53647-.76186l-1.98183-1.0133c-.38619-.19746-.85564-.12345-1.16234.18326l-.86321.8632c-.3067.3067-.38072.77616-.18326 1.16235l1.0133 1.98182c.15004.29345.43503.49412.76187.53647l1.1127.14418c.3076.03985.61628-.06528.8356-.28461l.86321-.8632c.21932-.21932.32446-.52801.2846-.83561l-.14417-1.1127ZM19.4448 16.4052l-3.1186-3.1187c-.7811-.781-2.0474-.781-2.8285 0l-.1719.172c-.7811.781-.7811 2.0474 0 2.8284l3.1186 3.1187c.7811.781 2.0474.781 2.8285 0l.1719-.172c.7811-.781.7811-2.0474 0-2.8284Z"/>
</svg>


          &nbsp; Configurar Por Loteria
        </button>) : (<button
          type="button"
          onClick={(e) => {
            setModal2(true);
          }}
          class="text-white mt-2 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
        >
          <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M7.58209 8.96025 9.8136 11.1917l-1.61782 1.6178c-1.08305-.1811-2.23623.1454-3.07364.9828-1.1208 1.1208-1.32697 2.8069-.62368 4.1363.14842.2806.42122.474.73509.5213.06726.0101.1347.0133.20136.0098-.00351.0666-.00036.1341.00977.2013.04724.3139.24069.5867.52125.7351 1.32944.7033 3.01552.4971 4.13627-.6237.8375-.8374 1.1639-1.9906.9829-3.0736l4.8107-4.8108c1.0831.1811 2.2363-.1454 3.0737-.9828 1.1208-1.1208 1.3269-2.80688.6237-4.13632-.1485-.28056-.4213-.474-.7351-.52125-.0673-.01012-.1347-.01327-.2014-.00977.0035-.06666.0004-.13409-.0098-.20136-.0472-.31386-.2406-.58666-.5212-.73508-1.3294-.70329-3.0155-.49713-4.1363.62367-.8374.83741-1.1639 1.9906-.9828 3.07365l-1.7788 1.77875-2.23152-2.23148-1.41419 1.41424Zm1.31056-3.1394c-.04235-.32684-.24303-.61183-.53647-.76186l-1.98183-1.0133c-.38619-.19746-.85564-.12345-1.16234.18326l-.86321.8632c-.3067.3067-.38072.77616-.18326 1.16235l1.0133 1.98182c.15004.29345.43503.49412.76187.53647l1.1127.14418c.3076.03985.61628-.06528.8356-.28461l.86321-.8632c.21932-.21932.32446-.52801.2846-.83561l-.14417-1.1127ZM19.4448 16.4052l-3.1186-3.1187c-.7811-.781-2.0474-.781-2.8285 0l-.1719.172c-.7811.781-.7811 2.0474 0 2.8284l3.1186 3.1187c.7811.781 2.0474.781 2.8285 0l.1719-.172c.7811-.781.7811-2.0474 0-2.8284Z"/>
</svg>


          &nbsp; Configurar Por Loteria
        </button>)}
              
        {valorid == 0 ? (<button
          type="button"
          
          class="text-white mt-2 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
        >
          <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M7.58209 8.96025 9.8136 11.1917l-1.61782 1.6178c-1.08305-.1811-2.23623.1454-3.07364.9828-1.1208 1.1208-1.32697 2.8069-.62368 4.1363.14842.2806.42122.474.73509.5213.06726.0101.1347.0133.20136.0098-.00351.0666-.00036.1341.00977.2013.04724.3139.24069.5867.52125.7351 1.32944.7033 3.01552.4971 4.13627-.6237.8375-.8374 1.1639-1.9906.9829-3.0736l4.8107-4.8108c1.0831.1811 2.2363-.1454 3.0737-.9828 1.1208-1.1208 1.3269-2.80688.6237-4.13632-.1485-.28056-.4213-.474-.7351-.52125-.0673-.01012-.1347-.01327-.2014-.00977.0035-.06666.0004-.13409-.0098-.20136-.0472-.31386-.2406-.58666-.5212-.73508-1.3294-.70329-3.0155-.49713-4.1363.62367-.8374.83741-1.1639 1.9906-.9828 3.07365l-1.7788 1.77875-2.23152-2.23148-1.41419 1.41424Zm1.31056-3.1394c-.04235-.32684-.24303-.61183-.53647-.76186l-1.98183-1.0133c-.38619-.19746-.85564-.12345-1.16234.18326l-.86321.8632c-.3067.3067-.38072.77616-.18326 1.16235l1.0133 1.98182c.15004.29345.43503.49412.76187.53647l1.1127.14418c.3076.03985.61628-.06528.8356-.28461l.86321-.8632c.21932-.21932.32446-.52801.2846-.83561l-.14417-1.1127ZM19.4448 16.4052l-3.1186-3.1187c-.7811-.781-2.0474-.781-2.8285 0l-.1719.172c-.7811.781-.7811 2.0474 0 2.8284l3.1186 3.1187c.7811.781 2.0474.781 2.8285 0l.1719-.172c.7811-.781.7811-2.0474 0-2.8284Z"/>
</svg>

          &nbsp; Configurar General
        </button>) : (<button
          type="button"
          onClick={(e) => {
            setModal3(true);
          }}
          class="text-white mt-2 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
        >
          <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M7.58209 8.96025 9.8136 11.1917l-1.61782 1.6178c-1.08305-.1811-2.23623.1454-3.07364.9828-1.1208 1.1208-1.32697 2.8069-.62368 4.1363.14842.2806.42122.474.73509.5213.06726.0101.1347.0133.20136.0098-.00351.0666-.00036.1341.00977.2013.04724.3139.24069.5867.52125.7351 1.32944.7033 3.01552.4971 4.13627-.6237.8375-.8374 1.1639-1.9906.9829-3.0736l4.8107-4.8108c1.0831.1811 2.2363-.1454 3.0737-.9828 1.1208-1.1208 1.3269-2.80688.6237-4.13632-.1485-.28056-.4213-.474-.7351-.52125-.0673-.01012-.1347-.01327-.2014-.00977.0035-.06666.0004-.13409-.0098-.20136-.0472-.31386-.2406-.58666-.5212-.73508-1.3294-.70329-3.0155-.49713-4.1363.62367-.8374.83741-1.1639 1.9906-.9828 3.07365l-1.7788 1.77875-2.23152-2.23148-1.41419 1.41424Zm1.31056-3.1394c-.04235-.32684-.24303-.61183-.53647-.76186l-1.98183-1.0133c-.38619-.19746-.85564-.12345-1.16234.18326l-.86321.8632c-.3067.3067-.38072.77616-.18326 1.16235l1.0133 1.98182c.15004.29345.43503.49412.76187.53647l1.1127.14418c.3076.03985.61628-.06528.8356-.28461l.86321-.8632c.21932-.21932.32446-.52801.2846-.83561l-.14417-1.1127ZM19.4448 16.4052l-3.1186-3.1187c-.7811-.781-2.0474-.781-2.8285 0l-.1719.172c-.7811.781-.7811 2.0474 0 2.8284l3.1186 3.1187c.7811.781 2.0474.781 2.8285 0l.1719-.172c.7811-.781.7811-2.0474 0-2.8284Z"/>
</svg>

          &nbsp; Configurar General
        </button>)}
            </div>
            
          </div>
        </div>

      </div>
      {modal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-12 bg-black rounded-t-lg place-content-center flex flex-grow text-white text-md font-semibold">
                  <span className="mt-2 pl-2">Registrar Caja</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1">
                    <div className="flex flex-row flex-wrap">
                      <div className="grid grid-cols-2 gap-2 text-blue-950 text-md font-bold">
                        <div className="p-2">
                          <span className="text-sm mt-1">Usuario:</span>
                          <input
                            type="text"
                            id="cusuario"
                            name="cusuario"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            defaultValue=""
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Nombres y Apellidos:</span>
                          <input
                            type="text"
                            id="cnombres"
                            name="cnombres"
                            required
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            defaultValue=""
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Clave:</span>
                          <input
                            type="password"
                            id="cclave1"
                            name="cclave1"
                            required
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            defaultValue=""
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Confirmar Clave:</span>
                          <input
                            type="password"
                            id="cclave2"
                                      name="cclave2"
                                      required
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            defaultValue=""
                          />
                        </div>

                       
                      </div>
                    </div>

                    <div className="flex flex-row place-content-end mt-2 mr-[-10px]">
                      <button
                        type="button"
                        id="opcionb12"
                        onClick={guardarcaja}
                        class="text-white mr-4 pl-2 w-18 h-8 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
                      >
                        <svg
                          class="w-[18px] h-[18px] text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        &nbsp; Guardar Usuario
                      </button>
                    </div>
                  </div>
                </div>
                <div className="h-14 bg-black rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono items-center">
                  <span
                    id="errorc"
                    className="text-green-500 text-[16px]"
                  ></span>
                  <button
                    type="button"
                    onClick={() => {
                      setModal(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

{modal2 ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-12 bg-black rounded-t-lg place-content-center flex flex-grow text-white text-md font-semibold">
                  <span className="mt-2 pl-2">Configurar de Jugadas Por Loteria</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1">
                    <div className="flex flex-row flex-wrap">
                      <div className="grid grid-cols-2 gap-2 text-blue-950 text-md font-bold">
                        <div className="p-2">
                          <span className="text-sm mt-1">Agencia/Usuario:</span>
                          <input
                            type="text"
                            id="magencia"
                            name="magencia"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            disabled="true"
                            value={nombreusuario}
                          />
                          <input
                                      type="hidden"
                                      className="form-control"
                                      id="midusuario"
                                      name="midusuario"
                                      value={valorid}
                                    />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Limite de Cupos</span>
                          <input
                            type="number"
                            id="limitecupos"
                            name="limitecupos"
                            required
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Jugada Minima</span>
                          <input
                            type="number"
                            id="jugadaminima"
                            name="jugadaminima"
                            required
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Jugada Maxima</span>
                          <input
                            type="number"
                            id="jugadamaxima"
                                      name="jugadamaxima"
                                      required
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                          />
                        </div>

                       
                      </div>
                    </div>

                    <div className="flex flex-row place-content-end mt-2 mr-[-10px]">
                      <button
                        type="button"
                        id="opcionb12"
                        onClick={configurarcupos}
                        class="text-white mr-4 pl-2 w-18 h-8 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
                      >
                        <svg
                          class="w-[18px] h-[18px] text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        &nbsp; Configurar Datos
                      </button>
                    </div>
                  </div>
                </div>
                <div className="h-14 bg-black rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono items-center">
                  <span
                    id="errorconfig"
                    className="text-green-500 text-[16px]"
                  ></span>
                  <button
                    type="button"
                    onClick={() => {
                      setModal2(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

{modal3 ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-12 bg-black rounded-t-lg place-content-center flex flex-grow text-white text-md font-semibold">
                  <span className="mt-2 pl-2">Configuracion General</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1">
                    <div className="flex flex-row flex-wrap">
                      <div className="grid grid-cols-2 gap-2 text-blue-950 text-md font-bold">
                        <div className="p-2">
                          <span className="text-sm mt-1">Venta Minima (Ticket)</span>
                          <input
                            type="number"
                            id="ventaminima"
                            name="ventaminima"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            defaultValue={minticket}
                          />
                          
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Venta Maxima (Ticket)</span>
                          <input
                            type="number"
                            id="ventamaxima"
                            name="ventamaxima"
                            required
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            defaultValue={maxticket}
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Tiempo Anulaci&oacute;n</span>
                          {seleccionara(anulacion)}
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Vencimiento del Ticket</span>
                          {seleccionarv(vencimiento)}
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">(%) de Comision</span>
                          <input
                            type="number"
                            id="jugadaminimaa"
                            name="jugadaminimaa"
                            required
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            defaultValue={minagencia}
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Mensaje a Imprimir</span>
                          <input
                            type="text"
                            id="imprimirmensaje"
                            name="imprimirmensaje"
                            defaultValue={imprimir}
                            required
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                           
                          />
                        </div>
                       
                      </div>
                    </div>

                    <div className="flex flex-row place-content-end mt-2 mr-[-10px]">
                      <button
                        type="button"
                        id="opcionb12"
                        onClick={configurardatos}
                        class="text-white mr-4 pl-2 w-18 h-8 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
                      >
                        <svg
                          class="w-[18px] h-[18px] text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        &nbsp; Configurar Datos
                      </button>
                    </div>
                  </div>
                </div>
                <div className="h-14 bg-black rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono items-center">
                  <span
                    id="errorconfigeneral"
                    className="text-green-500 text-[16px]"
                  ></span>
                  <button
                    type="button"
                    onClick={() => {
                      setModal3(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
  </>);


  if (formutipo == "nuevo") {
    return (
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-network-wired"></i> Registro de Cajas
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="campoloteria"
                                name="campoloteria"
                                maxlength="27"
                                value="SELECCIONE UN USUARIO/AGENCIA PARA CONFIGURAR"
                                disabled="true"
                              />
                            </div>
                          </div>

                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  if (formutipo == "editar") {
    return (
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-edit"></i> Actualizar Registro de
                      Loteria
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <label
                                htmlFor="campoloteria"
                                className="bmd-label-floating"
                              >
                                Nombre de Loteria
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="campoloteria"
                                name="campoloteria"
                                maxLength="27"
                                //defaultValue={nombreloteria}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label
                                for="cliente_nombre"
                                className="bmd-label-floating"
                              >
                                Tipo de Loteria
                              </label>
                              {datoid.map((val, key) => {
                                if (val.tipo === "ANIMALITOS") {
                                  return (
                                    <select
                                      className="form-control"
                                      id="campotipo"
                                      name="campotipo"
                                    >
                                      <option selected value="ANIMALITOS">
                                        ANIMALITOS
                                      </option>
                                      <option value="TERMINALITO">
                                        TERMINALITO
                                      </option>
                                      <option value="TRIPLE">TRIPLE</option>
                                    </select>
                                  );
                                }
                                if (val.tipo === "TERMINALITO") {
                                  return (
                                    <select
                                      className="form-control"
                                      id="campotipo"
                                      name="campotipo"
                                    >
                                      <option value="ANIMALITOS">
                                        ANIMALITOS
                                      </option>
                                      <option selected value="TERMINALITO">
                                        TERMINALITO
                                      </option>
                                      <option value="TRIPLE">TRIPLE</option>
                                    </select>
                                  );
                                }
                                if (val.tipo === "TRIPLE") {
                                  return (
                                    <select
                                      className="form-control"
                                      id="campotipo"
                                      name="campotipo"
                                    >
                                      <option value="ANIMALITOS">
                                        ANIMALITOS
                                      </option>
                                      <option value="TERMINALITO">
                                        TERMINALITO
                                      </option>
                                      <option selected value="TRIPLE">
                                        TRIPLE
                                      </option>
                                    </select>
                                  );
                                }
                              })}
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label
                                htmlFor="campoproporcion"
                                className="bmd-label-floating"
                              >
                                Proporcion
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="campoproporcion"
                                id="campoproporcion"
                                maxLength="40"
                                //defaultValue={proporcion}
                              />
                            </div>
                          </div>
                          <br></br>
                          <br></br>
                          <br></br>

                          <br></br>
                          <table align="center">
                            <tr>
                              <td>
                                <p className="text-center">
                                  &nbsp; &nbsp;
                                  <button
                                    type="submit"
                                    className="btn btn-raised btn-info btn-sm"
                                    onClick={modificarloteria}
                                  >
                                    <i className="far fa-save"></i> &nbsp;
                                    ACTUALIZAR DATOS
                                  </button>
                                </p>
                              </td>
                            </tr>
                          </table>
                          <ToastContainer />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  if (formutipo == "configurar") {
    return (
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-hashtag"></i> Configuracion de
                      Jugadas
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="campoloteria"
                                name="campoloteria"
                                value={`Usuario/Agencia ==> ${nombreusuario}`}
                                disabled="true"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* FORMULARIO MODAL DE REGISTRO DE CAJAS */}
                      <table className="table table-dark table-sm">
                        <thead>
                          <tr className="text-center roboto-medium">
                            <th colSpan="4" id="fondolote">
                              Loterias Disponibles Configurar
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr bgcolor="black" className="text-center">
                            <td></td>
                            <td align="left">
                              <font color="white">Loteria</font>
                            </td>
                            <td>
                              <font color="white">Min/Max</font>
                            </td>
                            <td>
                              <font color="white">Limite Cupos</font>
                            </td>
                          </tr>
                          {listalimites.map((val, key) => {
                            return (
                              <tr key={val.id} className="text-center">
                                <td>
                                  <input
                                    type="checkbox"
                                    id={"loteria" + val.id}
                                    value={val.id}
                                    name={"loteria" + val.id}
                                  />
                                  &nbsp;
                                </td>
                                <td align="left">
                                  <label htmlFor={"loteria" + val.id}>
                                    <span id={"cloter" + val.id} className="">
                                      {" "}
                                      {obtenernombreloterias(val.idloteria)}
                                    </span>
                                  </label>
                                </td>
                                <td>{`${val.min_jugada}/${val.max_jugada}`}</td>
                                <td>{val.limite_cupos}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <button
                        type="button"
                        className="btn btn-raised btn-primary btn-sm"
                        data-toggle="modal"
                        data-target="#exampleConfig"
                      >
                        <i className="fas fa-wrench"></i>&nbsp;Configurar Por
                        Loterias
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-raised btn-warning btn-sm"
                        data-toggle="modal"
                        data-target="#exampleConfigGeneral"
                      >
                        <i className="fas fa-wrench"></i>&nbsp;Configurar
                        General
                      </button>
                      &nbsp;
                      {/* FORMULARIO MODAL DE CONFIGURACION GENERAL */}
                      <div
                        className="modal fade"
                        id="exampleConfigGeneral"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="ModalPago"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <form className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="ModalPago">
                                Configuraci&oacute;n General
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="container-fluid">
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label
                                      for="cliente_dni"
                                      className="bmd-label-floating"
                                    >
                                      Venta Minima (Ticket)
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="ventaminima"
                                      name="ventaminima"
                                      defaultValue={minticket}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label
                                      for="cliente_dni"
                                      className="bmd-label-floating"
                                    >
                                      Venta Maxima (Ticket)
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="ventamaxima"
                                      name="ventamaxima"
                                      defaultValue={maxticket}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label
                                      for="cliente_dni"
                                      className="bmd-label-floating"
                                    >
                                      Tiempo Anulaci&oacute;n
                                    </label>
                                    {seleccionara(anulacion)}
                                  </div>
                                </div>
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label
                                      for="cliente_dni"
                                      className="bmd-label-floating"
                                    >
                                      Vencimiento del Ticket
                                    </label>
                                    {seleccionarv(vencimiento)}
                                  </div>
                                </div>

                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label
                                      for="cliente_dni"
                                      className="bmd-label-floating"
                                    >
                                      (%) de Comision
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="jugadaminimaa"
                                      name="jugadaminimaa"
                                      defaultValue={minagencia}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label
                                      for="cliente_dni"
                                      className="bmd-label-floating"
                                    >
                                      Mensaje a Imprimir
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="imprimirmensaje"
                                      name="imprimirmensaje"
                                      defaultValue={imprimir}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <span className="errorred5" id="errorred5"></span>
                              <span
                                className="errorgreen5"
                                id="errorgreen5"
                              ></span>
                              &nbsp;&nbsp;
                              <button
                                type="button"
                                className="btn btn-raised btn-info btn-sm"
                                data-dismiss=""
                                onClick={configurardatos}
                              >
                                Configurar Datos
                              </button>
                              {}
                              &nbsp;&nbsp;
                              <button
                                type="button"
                                className="btn btn-raised btn-danger btn-sm"
                                data-dismiss="modal"
                                id="cerrarmodal5"
                              >
                                Cancelar
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div
                        className="modal fade"
                        id="exampleConfig"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="ModalPago"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <form className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="ModalPago">
                                Configuraci&oacute;n de Jugadas por Loterias
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="container-fluid">
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label
                                      for="cliente_dni"
                                      className="bmd-label-floating"
                                    >
                                      Agencia/Usuario
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="magencia"
                                      name="magencia"
                                      disabled="true"
                                      value={nombreusuario}
                                      required
                                    />
                                    <input
                                      type="hidden"
                                      className="form-control"
                                      id="midusuario"
                                      name="midusuario"
                                      value={valorid}
                                    />
                                  </div>
                                </div>
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label
                                      for="cliente_dni"
                                      className="bmd-label-floating"
                                    >
                                      Limite de Cupos
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="limitecupos"
                                      name="limitecupos"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label
                                      for="cliente_dni"
                                      className="bmd-label-floating"
                                    >
                                      Jugada Minima
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="jugadaminima"
                                      name="jugadaminima"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label
                                      for="cliente_dni"
                                      className="bmd-label-floating"
                                    >
                                      Jugada Maxima
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="jugadamaxima"
                                      name="jugadamaxima"
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <span className="errorred4" id="errorred4"></span>
                              <span
                                className="errorgreen4"
                                id="errorgreen4"
                              ></span>
                              &nbsp;&nbsp;
                              <button
                                type="button"
                                className="btn btn-raised btn-info btn-sm"
                                data-dismiss=""
                                onClick={configurarcupos}
                              >
                                Configurar Datos
                              </button>
                              {}
                              &nbsp;&nbsp;
                              <button
                                type="button"
                                className="btn btn-raised btn-danger btn-sm"
                                data-dismiss="modal"
                                id="cerrarmodal4"
                              >
                                Cancelar
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  if (formutipo == "cajas") {
    return (
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-network-wired"></i> Registro de Cajas
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="campoloteria"
                                name="campoloteria"
                                value={`Usuario/Agencia ==> ${nombreusuario}`}
                                disabled="true"
                              />
                            </div>
                          </div>

                          <br></br>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-raised btn-primary btn-sm"
                        data-toggle="modal"
                        data-target="#exampleModal2"
                      >
                        <i className="fas fa-laptop"></i>&nbsp;Nueva Caja
                      </button>
                      {/* FORMULARIO MODAL DE REGISTRO DE CAJAS */}
                      <div
                        className="modal fade"
                        id="exampleModal2"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="ModalPago"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <form className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="ModalPago">
                                Registrar Caja
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="container-fluid">
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label
                                      for="cliente_dni"
                                      className="bmd-label-floating"
                                    >
                                      Usuario
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="cusuario"
                                      name="cusuario"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label
                                      for="cliente_dni"
                                      className="bmd-label-floating"
                                    >
                                      Nombres y Apellidos
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="cnombres"
                                      name="cnombres"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label
                                      for="cliente_dni"
                                      className="bmd-label-floating"
                                    >
                                      Clave de Acceso
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="cclave1"
                                      name="cclave1"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label
                                      for="cliente_dni"
                                      className="bmd-label-floating"
                                    >
                                      Confirmar Clave de Acceso
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="cclave2"
                                      name="cclave2"
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <span className="errorred2" id="errorred2"></span>
                              <span
                                className="errorgreen2"
                                id="errorgreen2"
                              ></span>
                              &nbsp;&nbsp;
                              <button
                                type="button"
                                className="btn btn-raised btn-info btn-sm"
                                data-dismiss=""
                                onClick={guardarcaja}
                              >
                                Guardar Datos
                              </button>
                              {}
                              &nbsp;&nbsp;
                              <button
                                type="button"
                                className="btn btn-raised btn-danger btn-sm"
                                data-dismiss="modal"
                                id="cerrarmodal2"
                              >
                                Cancelar
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <table className="table table-dark table-sm">
                        <thead>
                          <tr className="text-center roboto-medium">
                            <th colSpan="3" id="fondolote">
                              REGISTRO DE CAJAS
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr bgcolor="black" className="text-center">
                            <td></td>
                            <td>
                              <font color="white">Usuario</font>
                            </td>
                            <td>
                              <font color="white">Nombres</font>
                            </td>
                          </tr>
                          {listacaja.map((val, key) => {
                            return (
                              <tr key={val.id} className="text-center">
                                <td>
                                  <button className="btn btn-primary">
                                    <i
                                      class="fa-solid fa-ban"
                                      title="Eliminar Caja"
                                      onClick={() => {
                                        confirmardelcaja(val.id);
                                      }}
                                    ></i>
                                  </button>

                                  <button className="btn btn-primary">
                                    <i
                                      class="fa-solid fa-wrench"
                                      title="Configurar"
                                      onClick={() => {
                                        //addvalor(val.id);

                                        //addtipo("h");
                                      }}
                                    ></i>
                                  </button>
                                </td>
                                <td>
                                  <span id={"loter" + val.id} className="">
                                    {" "}
                                    {val.username}
                                  </span>
                                </td>
                                <td>{val.name}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

};

export default Formuderecho;
