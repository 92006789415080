import React from "react";
import Axios from "axios";
import { useState, useEffect } from "react";
import Menu from "./menu.jsx";
import moment from "moment";
import Loterias from "./loterias.jsx";
import Formapuesta from "./formapuesta.jsx";
import Listausers from "../componentes/listausers.jsx";
import Formuderecho from "../componentes/formuderecho.jsx";

var date = new Date();

var dia = new Intl.DateTimeFormat("en-US", {
  dateStyle: "full",
  timeStyle: "long",
  timeZone: "America/New_York",
}).format(date);

console.log(date);

const fecha1 = "2017-09-06T20:00:51";
const fecha2 = "2017-12-06T20:00:00";

var a = new Date(fecha1);
var b = new Date(fecha2);

console.log(+a > +b); //false
console.log(+a < +b); //false
console.log(+a == +b); //true

var a1 = new Date("2017-12-01T03:29:50");
var b1 = new Date("2017-12-06T03:11:00");
//La diferencia se da en milisegundos así que debes dividir entre 1000
var c = (a1 - b1) / 1000 / 60;
console.log(Math.trunc(c)); // resultado 5;

const ejecutar1 = () => {
  // const attr = document.createAttribute("data-dismiss");
  // attr.value = "modal";
  // const anchor = document.getElementById("segundomodal");
  // anchor.setAttributeNode(attr);
  alert("Mostrar primer mensaje");
};




//CREAR LA FECHA
const timestamp = Date.now();
var dateDB = new Date(timestamp);

var monthArrayNames = [];
monthArrayNames[0] = "01";
monthArrayNames[1] = "02";
monthArrayNames[2] = "03";
monthArrayNames[3] = "04";
monthArrayNames[4] = "05";
monthArrayNames[5] = "06";
monthArrayNames[6] = "07";
monthArrayNames[7] = "08";
monthArrayNames[8] = "09";
monthArrayNames[9] = "10";
monthArrayNames[10] = "11";
monthArrayNames[11] = "12";

var day = dateDB.getDate();
if (day === 1) {
  day = "01";
}
if (day === 2) {
  day = "02";
}
if (day === 3) {
  day = "03";
}
if (day === 4) {
  day = "04";
}
if (day === 5) {
  day = "05";
}
if (day === 6) {
  day = "06";
}
if (day === 7) {
  day = "07";
}
if (day === 8) {
  day = "08";
}
if (day === 9) {
  day = "09";
}

var month = monthArrayNames[dateDB.getMonth()];
var year = dateDB.getFullYear();
var timestampobj = `${year}-${month}-${day}`;

const Regusers = (props) => {
    const [listaloteria, setListaLoteria] = useState("ANIMALITOS");
    const [activarcheq, setActivarCheq] = useState(1);
    const [arreglopasado, setArregloPasado] = useState([]);
    const [diasemana, setDiasemana] = useState(0);
    const [formutipo, setFormutipo] = useState("nuevo");
    const [valorid, setValorid] = useState(0);
    const [mostrarhorario, setMostrarhorario] = useState([]);
    const [parte1, setParte1] = useState([]);
    const [parte2, setParte2] = useState([]);
    const tomardia = (mensa) => {};

    const [modal, setModal] = useState(false);

    const addmensaje = (mensaje) => {
      if (activarcheq == 0) {
        setActivarCheq(1);
      } else {
        setActivarCheq(0);
      }
      setFormutipo(mensaje);
      console.log(formutipo);
    };
  
    const addtipo = (mtipo) => {
      setFormutipo(mtipo);
    };
  
    const addvalor = (mvalor) => {
      console.log(mvalor)
      setValorid(mvalor);
      //gethorario(mvalor);
    };

    const tomadia = (mensaje) => {
        setDiasemana(mensaje);
        console.log(mensaje);
      };
  
    const gethorario = (mvalor) => {
      console.log("VALOR DEL PARAMETRO ID: " + mvalor);
      var data2;
      Axios.get(`${process.env.REACT_APP_API}/mostrarhorario/${mvalor}`).then(
        (response) => {
          setMostrarhorario(response.data);
          data2 = response.data;
  
          var dato1 = [
            { id1: 0, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id1: 1, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id1: 2, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id1: 3, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id1: 4, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id1: 5, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id1: 6, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id1: 7, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id1: 8, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id1: 9, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id1: 10, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id1: 11, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          ];
  
          var dato3 = [
            { id2: 12, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id2: 13, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id2: 14, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id2: 15, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id2: 16, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id2: 17, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id2: 18, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id2: 19, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id2: 20, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id2: 21, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id2: 22, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
            { id2: 23, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          ];
  
          for (let i = 0; i < 12; i++) {
            dato1[i].id = data2[i].id;
            dato1[i].idloteria = data2[i].idloteria;
            dato1[i].hora = data2[i].hora;
            dato1[i].estatus = data2[i].estatus;
          }
  
          for (let i = 0; i < 12; i++) {
            dato3[i].id = data2[i + 12].id;
            dato3[i].idloteria = data2[i + 12].idloteria;
            dato3[i].hora = data2[i + 12].hora;
            dato3[i].estatus = data2[i + 12].estatus;
          }
  
          setParte1(dato1);
          setParte2(dato3);
  
          console.log(dato1);
          console.log(dato3);
        }
      );
    };
  
    const limpiarcampos = () => {
      document.getElementById("campousuario").value = "";
      document.getElementById("camponombres").value = "";
      document.getElementById("campoclave1").value = "";
      document.getElementById("campoclave2").value = "";
      document.getElementById("campoemail").value = "";
      document.getElementById("campodireccion").value = "";
      document.getElementById("campotelefono").value = "";
    };
  
    const guardarusuario = () => {
      var wusuario = document.getElementById("campousuario").value;
      var wnombres = document.getElementById("camponombres").value;
      var wclave1 = document.getElementById("campoclave1").value;
      var wclave2 = document.getElementById("campoclave2").value;
      var wtelefono = document.getElementById("campotelefono").value;
      var wemail = document.getElementById("campoemail").value;
      var wdireccion = document.getElementById("campodireccion").value;
  
      if (wclave1 != wclave2) {
        document.getElementById("errorgreen").innerHTML = "";
        document.getElementById("errorred").innerHTML = "Las Claves no Coinciden";
      } else {
        if (
          wclave1 == "" ||
          wclave2 == "" ||
          wnombres == "" ||
          wtelefono == "" ||
          wusuario == "" ||
          wdireccion == "" ||
          wemail == ""
        ) {
          document.getElementById("errorgreen").innerHTML = "";
          document.getElementById("errorred").innerHTML = "Hay Campos Vacios";
        } else {
          const data = {
            wusuario: wusuario,
            wnombres: wnombres,
            wclave1: wclave1,
            wclave2: wclave2,
            wtelefono: wtelefono,
            wemail: wemail,
            wdireccion: wdireccion,
          };
          //enviar datos
          fetch(`${process.env.REACT_APP_API}/guardarusuario`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then((result) => {
              if (result) {
                if (result.message == "existe") {
                  document.getElementById("errorred").innerHTML =
                    "(" +
                    document.getElementById("campousuario").value +
                    ") ya existe, intenta con otro";
                  document.getElementById("errorgreen").innerHTML = "";
                }
                if (result.message == "guardado") {
                  document.getElementById("errorgreen").innerHTML =
                    "Datos Guardados con existo";
                  document.getElementById("errorred").innerHTML = "";
                  setTimeout(() => {
                    //mostrar();
                    addmensaje("n");
                    document.getElementById("errorgreen").innerHTML = "";
                    limpiarcampos();
                  }, 2000);
                }
                if (result.message == "error") {
                  document.getElementById("errorgreen").innerHTML = "";
                  document.getElementById("errorred").innerHTML =
                    "ERROR DE CONEXION";
                }
  
                //ACTUAL USUARIOS
              } else {
                console.log("ERROR DE CONEXION");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      //fin de envio
    };
  
  return (
    <>
      <Menu tomadia={tomadia} />
      <div class="grid grid-cols-2 gap-2">

    <div>
    <span className="text-xl pt-4 pl-4 font-bold text-blue-950 flex flex-col">
           Registro de Usuarios
          </span>
          

              <div className="ml-4">
              <Listausers
                                activarchequeo={activarcheq}
                                myuser={props.myuser}
                                mytoken={props.mytoken}
                                addtipo={addtipo}
                                addvalor={addvalor}
                              />
              </div>
    </div>
      

      <div className="flex flex-col place-content-start pl-4 mt-4">

      <span className="text-xl pt-1 pl-0 font-bold text-blue-950 flex flex-col">
           Registro de Cajas
          </span>

      <Formuderecho
                    addmensaje={addmensaje}
                    formutipo={formutipo}
                    valorid={valorid}
                    parte1={parte1}
                    parte2={parte2}
                    activarchequeo={activarcheq}
                  />
           
        
          </div>
      
   
        
        
      </div>
     
        {modal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-12 bg-black rounded-t-lg place-content-center flex flex-grow text-white text-md font-semibold">
                  <span className="mt-2 pl-2">Registrar Usuario</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1">
                    <div className="flex flex-row flex-wrap">
                      <div className="flex flex-row text-blue-950 text-md font-bold">
                        <span className="text-sm mt-1">Usuario:</span>
                        <input
                          type="text"
                          id="serialticket"
                          
                          class="bg-gray-50 ml-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[50%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder=""
                        />

                        <button
                          type="button"
                          id="opcionlimpiar1"
                     
                          class="text-white ml-2 pl-2 w-18 h-8 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
                        >
                          Limpiar
                        </button>
                      </div>
                    </div>

                    <div class="relative pt-4 overflow-x-auto shadow-md sm:rounded-lg flex flex-row flex-wrap">
                      <div className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm">
                        VENTAS
                      </div>
                      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" class="px-6 py-1">
                              #
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Caja
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Hora
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Estatus
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          Contenido
                        </tbody>
                      </table>
                      <div className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm">
                        JUGADAS ----- #Ticket:
                      </div>
                      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" class="px-6 py-1">
                              Loteria
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Tipo
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Numero
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Monto
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          contenido
                        </tbody>
                      </table>
                    </div>

                    <div className="flex flex-row place-content-end mt-2 mr-[-10px]">
                      

                      <button
                        type="button"
                        id="opcionb12"
                        class="text-white ml-2 pl-2 w-18 h-8 bg-[#cb2121] hover:bg-[#cb2121]/90 focus:ring-4 focus:outline-none focus:ring-[#cb2121]/50 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-[#cb2121]/55 me-2 mb-2"
                      >
                        <svg
                          class="w-[18px] h-[18px] text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-width="2"
                            d="m6 6 12 12m3-6a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                        Anular Este Ticket
                      </button>
                    </div>
                  </div>
                </div>
                <div className="h-14 bg-black rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <button
                    type="button"
                    onClick={() => {
                      setModal(false);
                      
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Regusers;