import { HashRouter, Route, Routes, Link } from "react-router-dom";
import Login from "./page/login";

import { useState } from "react";
import Home from "./page/home";

import Configcheck from "./page/configcheck";
import Regusercheck from "./page/regusercheck";
import Imprimircheck from "./page/imprimircheck";
import Resultscheck from "./page/resultscheck";
import Reportscheck from "./page/reportscheck";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/regusers" element={<Regusercheck />} />
          <Route path="/configl" element={<Configcheck />} />
          <Route path="/imprimir" element={<Imprimircheck />} />
          <Route path="/results" element={<Resultscheck />} />
          <Route path="/reports" element={<Reportscheck />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
