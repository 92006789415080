import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { useState, useEffect } from "react";
import Axios from "axios";
import impresora from "../img/plugin.png"

const Formuimpresion = ({ formutipo }) => {
  useEffect(() => {}, [formutipo]);

  if (formutipo == "nuevo") {
    console.log("LLEGO LA IMPRESION");
    return (
      <div>
       <div className="form-group">
        <img src={impresora} alt="" className="mt-10" width="100px" />
                             
                              <div class="text-lg text-green-500 font-bold">
                                Haz clic{" "}
                                <a class="text-blue-700" href="https://github.com/parzibyte/plugin-impresora-termica-v3/releases/download/3.4.0/Windows.64.zip">
                                  aqui
                                </a>
                                {" "} para descargar
                              </div>
                            </div>
      </div>
    );
  }
};

export default Formuimpresion;
