import React from "react";
import Axios from "axios";
import { useState, useEffect } from "react";
import Menu from "./menu.jsx";
import moment from "moment";
import Loterias from "./loterias.jsx";
import Formapuesta from "./formapuesta.jsx";

var date = new Date();

var dia = new Intl.DateTimeFormat("en-US", {
  dateStyle: "full",
  timeStyle: "long",
  timeZone: "America/New_York",
}).format(date);

console.log(date);

const fecha1 = "2017-09-06T20:00:51";
const fecha2 = "2017-12-06T20:00:00";

var a = new Date(fecha1);
var b = new Date(fecha2);

console.log(+a > +b); //false
console.log(+a < +b); //false
console.log(+a == +b); //true

var a1 = new Date("2017-12-01T03:29:50");
var b1 = new Date("2017-12-06T03:11:00");
//La diferencia se da en milisegundos así que debes dividir entre 1000
var c = (a1 - b1) / 1000 / 60;
console.log(Math.trunc(c)); // resultado 5;

const ejecutar1 = () => {
  // const attr = document.createAttribute("data-dismiss");
  // attr.value = "modal";
  // const anchor = document.getElementById("segundomodal");
  // anchor.setAttributeNode(attr);
  alert("Mostrar primer mensaje");
};

const mostrar = (idimage) => {
  var elem = document.getElementById("txtcodigo");
  console.log(idimage);
  elem.value = idimage.icono.codigo;

  //$("#cerrarmodal1").click();
  document.getElementById("txtcodigo").focus();
};

const ejecutar2 = () => {
  // const attr = document.createAttribute("data-dismiss");
  // attr.value = "modal";
  // const anchor = document.getElementById("segundomodal");
  // anchor.setAttributeNode(attr);
  alert("Mostrar primer mensaje2");
  //$("#cerrarmodal2").click();
};

//CREAR LA FECHA
const timestamp = Date.now();
var dateDB = new Date(timestamp);

var monthArrayNames = [];
monthArrayNames[0] = "01";
monthArrayNames[1] = "02";
monthArrayNames[2] = "03";
monthArrayNames[3] = "04";
monthArrayNames[4] = "05";
monthArrayNames[5] = "06";
monthArrayNames[6] = "07";
monthArrayNames[7] = "08";
monthArrayNames[8] = "09";
monthArrayNames[9] = "10";
monthArrayNames[10] = "11";
monthArrayNames[11] = "12";

var day = dateDB.getDate();
if (day === 1) {
  day = "01";
}
if (day === 2) {
  day = "02";
}
if (day === 3) {
  day = "03";
}
if (day === 4) {
  day = "04";
}
if (day === 5) {
  day = "05";
}
if (day === 6) {
  day = "06";
}
if (day === 7) {
  day = "07";
}
if (day === 8) {
  day = "08";
}
if (day === 9) {
  day = "09";
}

var month = monthArrayNames[dateDB.getMonth()];
var year = dateDB.getFullYear();
var timestampobj = `${year}-${month}-${day}`;

const Home2 = () => {
  const [listaloteria, setListaLoteria] = useState("ANIMALITOS");
  const [activarcheq, setActivarCheq] = useState(1);
  const [arreglopasado, setArregloPasado] = useState([]);
  const [diasemana, setDiasemana] = useState(0);
  const [fecha, setFecha] = useState(timestampobj);
  const [listaventas, setListaventas] = useState([]);
  const [listaticket, setListaticket] = useState([]);
  const [listaticketpremio, setListaticketpremio] = useState([]);
  const [listajugadas, setListajugadas] = useState([]);
  const [listajugadas2, setListajugadas2] = useState([]);
  const [numfactura, setNumfactura] = useState(0);
  const [numfactura2, setNumfactura2] = useState(0);
  const [segundos, setSegundos] = useState(0);
  const [segundos2, setSegundos2] = useState(0);

  const [configag, setConfigag] = useState([]);
  const [minanula, setMinutoanula] = useState(0);
  const [activartemp, setActivartemp] = useState(0);
  const [activartemp2, setActivartemp2] = useState(0);
  const [diasv, setDiasv] = useState(0);
  const [diasd, setDiasd] = useState(0);
  const [totalapagar, setTotalapagar] = useState(0);

  const [modal, setModal] = useState(false);
  const [modalticket, setModalTicket] = useState(false);
  const [modalventas, setModalVentas] = useState(false);


  const [bloquearteclak, setBloquearteclak] = useState(false);
  const [bloquearteclap, setBloquearteclap] = useState(false);
  const [bloquearteclaa, setBloquearteclaa] = useState(false);


  const obtenertotalapagar = () => {
    var total = 0;
    for (let i = 0; i < listaticketpremio.length; i++) {
      total = parseInt(total) + parseInt(listaticketpremio[0].premio);
    }
    setTotalapagar(total);
  };
  const obtenerconfiggeneral = () => {
    const data = {
      wid: localStorage.getItem("veloci"),
    };

    //enviar datos
    fetch(`${process.env.REACT_APP_API}/obtenerconfiggeneral`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          if (result.length != 0) {
            setMinutoanula(result[0].anulacion);
            setDiasv(result[0].vencimiento);
          } else {
            setMinutoanula(0);
          }
          setConfigag(result);
          console.log(result);
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  const obtenersegundos = (horapasada) => {
    //TOMAR HORA ACTUAL
    var tomarfecha = "";
    tomarfecha = "" + moment.utc().format();
    tomarfecha = tomarfecha.replace("Z", "");
    var date3 = new Date(tomarfecha);
    date3.setHours(date3.getHours() - 4);
    //FIN DE HORA ACTUAL RESULT EN date3

    var dia;
    console.log(date3);

    if (parseInt(date3.getDate()) > 0 && parseInt(date3.getDate()) < 10) {
      dia = "0" + date3.getDate();
    } else {
      dia = date3.getDate();
    }

    //TOMA LA FECHA EN FORMATO YYYY-MM-DD
    var mes;
    if (date3.getMonth() + 1 > 9) {
      var date4 =
        date3.getFullYear() + "-" + (date3.getMonth() + 1) + "-" + dia;
    } else {
      mes = "0" + (date3.getMonth() + 1);
      var date4 = date3.getFullYear() + "-" + mes + "-" + dia;
    }
    //LA FECHA EN FORMATO YYYY-MM-DD GUARDADA EN LA VARIABLE data4

    //meter la hora en la segunda fecha
    var hora = horapasada;
    var date5 = new Date(date4 + "T" + hora);
    //CALCULANDO LOS MINUTOS QUE FALTAN

    var c = (date3 - date5) / 1000 / 60;

    var segactual = (parseInt(minanula) - c) * 60;
    if (segactual < 0) {
      setSegundos(0);
      setActivartemp(0);
    } else {
      setActivartemp(1);
      setSegundos(parseInt(segactual));
    }
    //FIN DE CALCULO
    return Math.trunc(c);
  };

  const obtenersegundos2 = (horapasada, fecha2) => {
    //TOMAR HORA ACTUAL
    var tomarfecha = "";
    tomarfecha = "" + moment.utc().format();
    tomarfecha = tomarfecha.replace("Z", "");
    var date3 = new Date(tomarfecha);
    date3.setHours(date3.getHours() - 4);
    //FIN DE HORA ACTUAL RESULT EN date3

    var dia;
    console.log(date3);

    if (parseInt(date3.getDate()) > 0 && parseInt(date3.getDate()) < 10) {
      dia = "0" + date3.getDate();
    } else {
      dia = date3.getDate();
    }

    //TOMA LA FECHA EN FORMATO YYYY-MM-DD
    var mes;
    if (date3.getMonth() + 1 > 9) {
      var date4 =
        date3.getFullYear() + "-" + (date3.getMonth() + 1) + "-" + dia;
    } else {
      mes = "0" + (date3.getMonth() + 1);
      var date4 = date3.getFullYear() + "-" + mes + "-" + dia;
    }
    //LA FECHA EN FORMATO YYYY-MM-DD GUARDADA EN LA VARIABLE data4

    //meter la hora en la segunda fecha
    var hora = horapasada;
    var date5 = new Date(fecha2 + "T" + hora);
    //CALCULANDO LOS MINUTOS QUE FALTAN
    console.log(date5);
    date5.setDate(date5.getDate() + parseInt(diasv));
    console.log(date5);
    console.log(hora);

    var c = (date5 - date3) / 1000 / 60;
    console.log("MINUTOS " + c);

    var segactual2 = c * 60;
    var diasd = segactual2 / 3600 / 24;

    setDiasd(diasd.toFixed(1));

    if (segactual2 < 0) {
      setSegundos2(0);
      setActivartemp2(0);
      setDiasd(0);
    } else {
      setActivartemp2(1);
      setSegundos2(parseInt(segactual2));
    }
    //FIN DE CALCULO
    console.log(segactual2);
    return Math.trunc(segactual2);
  };

  const handlerFuntion = () => {
    setSegundos(0);
    getticketbuscado();
  };

  const handlerFuntion2 = () => {
    setSegundos2(0);
    getticketbuscado2();
  };

  const tomadia = (mensaje) => {
    setDiasemana(mensaje);
    console.log(mensaje);
  };

  const numeros = [
    "0",
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "57",
    "58",
    "59",
    "60",
    "61",
    "62",
    "63",
    "64",
    "65",
    "66",
    "67",
    "68",
    "69",
    "70",
    "71",
    "72",
    "73",
    "74",
    "75",
  ];

  const animales = [
    { codigo: "0", title: "Delfin" },
    { codigo: "00", title: "Ballena" },
    { codigo: "01", title: "Camero" },
    { codigo: "02", title: "Toro" },
    { codigo: "03", title: "Ciempies" },
    { codigo: "04", title: "Alacran" },
    { codigo: "05", title: "Leon" },
    { codigo: "06", title: "Rana" },
    { codigo: "07", title: "Perico" },
    { codigo: "08", title: "Raton" },
    { codigo: "09", title: "Aguila" },
    { codigo: "10", title: "Tigre" },
    { codigo: "11", title: "Gato" },
    { codigo: "12", title: "Caballo" },
    { codigo: "13", title: "Mono" },
    { codigo: "14", title: "Paloma" },
    { codigo: "15", title: "Zorro" },
    { codigo: "16", title: "Oso" },
    { codigo: "17", title: "Pavo" },
    { codigo: "18", title: "Burro" },
    { codigo: "19", title: "Chivo" },
    { codigo: "20", title: "Cochino" },
    { codigo: "21", title: "Gallo" },
    { codigo: "22", title: "Camello" },
    { codigo: "23", title: "Zebra" },
    { codigo: "24", title: "Iguana" },
    { codigo: "25", title: "Gallina" },
    { codigo: "26", title: "Vaca" },
    { codigo: "27", title: "Perro" },
    { codigo: "28", title: "Zamuro" },
    { codigo: "29", title: "Elefante" },
    { codigo: "30", title: "Caiman" },
    { codigo: "31", title: "Lapa" },
    { codigo: "32", title: "Ardilla" },
    { codigo: "33", title: "Pescado" },
    { codigo: "34", title: "Venado" },
    { codigo: "35", title: "Jirafa" },
    { codigo: "36", title: "Culebra" },
    { codigo: "37", title: "Tortuga" },
    { codigo: "38", title: "Bufalo" },
    { codigo: "39", title: "Lechuza" },
    { codigo: "40", title: "Avispa" },
    { codigo: "41", title: "Canguro" },
    { codigo: "42", title: "Tucan" },
    { codigo: "43", title: "Mariposa" },
    { codigo: "44", title: "Chiguire" },
    { codigo: "45", title: "Garza" },
    { codigo: "46", title: "Puma" },
    { codigo: "47", title: "Pavo Real" },
    { codigo: "48", title: "Puercoespin" },
    { codigo: "49", title: "Pereza" },
    { codigo: "50", title: "Canario" },
    { codigo: "51", title: "Pelicano" },
    { codigo: "52", title: "Pulpo" },
    { codigo: "53", title: "Caracol" },
    { codigo: "54", title: "Grillo" },
    { codigo: "55", title: "Oso Hormiguero" },
    { codigo: "56", title: "Tiburon" },
    { codigo: "57", title: "Pato" },
    { codigo: "58", title: "Hormiga" },
    { codigo: "59", title: "Pantera" },
    { codigo: "60", title: "Camaleon" },
    { codigo: "61", title: "Panda" },
    { codigo: "62", title: "Cachicamo" },
    { codigo: "63", title: "Cangrejo" },
    { codigo: "64", title: "Gavilan" },
    { codigo: "65", title: "Araña" },
    { codigo: "66", title: "Lobo" },
    { codigo: "67", title: "Avestruz" },
    { codigo: "68", title: "Jaguar" },
    { codigo: "69", title: "Conejo" },
    { codigo: "70", title: "Bisonte" },
    { codigo: "71", title: "Guacamaya" },
    { codigo: "72", title: "Gorila" },
    { codigo: "73", title: "Hipopotamo" },
    { codigo: "74", title: "Turpial" },
    { codigo: "75", title: "Doble" },
  ];

  const ventasdiarias = () => {
    setModalVentas(true);
    getventas();
  };

  const anulaciont = () => {
    abrirmodal2();
    
    setModal(true);
    setTimeout(() => {
      limpiardatos();
    }, 500);

    //getticketbuscado();
    //obtenerconfiggeneral();
  };

  const obtenerfecha = () => {
    //TOMAR HORA ACTUAL
    var tomarfecha = "";
    tomarfecha = "" + moment.utc().format();
    tomarfecha = tomarfecha.replace("Z", "");
    var date3 = new Date(tomarfecha);
    date3.setHours(date3.getHours() - 4);
    //FIN DE HORA ACTUAL RESULT EN date3

    var dia;

    if (parseInt(date3.getDate()) > 0 && parseInt(date3.getDate()) < 10) {
      dia = "0" + date3.getDate();
    } else {
      dia = date3.getDate();
    }

    //TOMA LA FECHA EN FORMATO YYYY-MM-DD
    var mes;
    if (date3.getMonth() + 1 > 9) {
      var date4 =
        date3.getFullYear() + "-" + (date3.getMonth() + 1) + "-" + dia;
    } else {
      mes = "0" + (date3.getMonth() + 1);
      var date4 = date3.getFullYear() + "-" + mes + "-" + dia;
    }
    //LA FECHA EN FORMATO YYYY-MM-DD GUARDADA EN LA VARIABLE data4

    return date4;
  };

  const mostrarpagarticket = () => {
    setModalTicket(true);
      setTimeout(() => {
        limpiardatos2();
      }, 500);
    //getticketbuscado();
    //obtenerconfiggeneral();
  };

  const mostrarjugadas = (id) => {
    alert(id);
  };

  const abrirmodal = () => {
    //$("#exa3").click();
  };

  const abrirmodal2 = () => {
    //$("#exa4").click();
  };
  const abrirmodal3 = () => {
    //$("#exa5").click();
  };

  const limpiardatos = () => {
    document.getElementById("opcionb12").innerHTML = "Anular este Ticket";
    document.getElementById("opcionb12").className =
      "text-white ml-2 pl-2 w-18 h-8 bg-[#cb2121] hover:bg-[#cb2121]/90 focus:ring-4 focus:outline-none focus:ring-[#cb2121]/50 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-[#cb2121]/55 me-2 mb-2";
    document.getElementById("opcionb12").disabled = true;
    setActivartemp(0);
    setSegundos(0);
    setNumfactura(0);
    setListaticket([]);
    setListajugadas([]);

    document.getElementById("serialticket").value = "";
    document.getElementById("serialticket").disabled = false;
    document.getElementById("serialticket").focus();
  };

  const limpiardatos2 = () => {
    document.getElementById("opcionb17").innerHTML =
      '<svg class="w-[18px] h-[18px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path d="M11 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" /><path fill-rule="evenodd" d="M9.896 3.051a2.681 2.681 0 0 1 4.208 0c.147.186.38.282.615.255a2.681 2.681 0 0 1 2.976 2.975.681.681 0 0 0 .254.615 2.681 2.681 0 0 1 0 4.208.682.682 0 0 0-.254.615 2.681 2.681 0 0 1-2.976 2.976.681.681 0 0 0-.615.254 2.682 2.682 0 0 1-4.208 0 .681.681 0 0 0-.614-.255 2.681 2.681 0 0 1-2.976-2.975.681.681 0 0 0-.255-.615 2.681 2.681 0 0 1 0-4.208.681.681 0 0 0 .255-.615 2.681 2.681 0 0 1 2.976-2.975.681.681 0 0 0 .614-.255ZM12 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z" clip-rule="evenodd"/><path d="M5.395 15.055 4.07 19a1 1 0 0 0 1.264 1.267l1.95-.65 1.144 1.707A1 1 0 0 0 10.2 21.1l1.12-3.18a4.641 4.641 0 0 1-2.515-1.208 4.667 4.667 0 0 1-3.411-1.656Zm7.269 2.867 1.12 3.177a1 1 0 0 0 1.773.224l1.144-1.707 1.95.65A1 1 0 0 0 19.915 19l-1.32-3.93a4.667 4.667 0 0 1-3.4 1.642 4.643 4.643 0 0 1-2.53 1.21Z" /></svg>Pagar Ticket';
    document.getElementById("opcionb17").className =
      "text-white ml-2 pl-2 w-18 h-8 bg-[#3585b7] hover:bg-[#3585b7]/90 focus:ring-4 focus:outline-none focus:ring-[#3585b7]/50 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-[#3585b7]/55 me-2 mb-2";
    document.getElementById("opcionb17").disabled = true;
    setActivartemp2(0);
    setSegundos2(0);
    setNumfactura2(0);
    setDiasd(0);
    setListaticketpremio([]);
    setListajugadas2([]);

    document.getElementById("serialticketpago").value = "";
    document.getElementById("serialticketpago").disabled = false;
    document.getElementById("serialticketpago").focus();
  };

  document.addEventListener("keydown", function (event) {
    if (event.key == "k") {
      if(bloquearteclak==false){
ventasdiarias();
setBloquearteclak(true);
      }
      
    }
  });

  document.addEventListener("keydown", function (event) {
    if (event.key == "a") {
      if(bloquearteclaa==false){
anulaciont();
setBloquearteclaa(true);
      }
      
    }
  });

  document.addEventListener("keydown", function (event) {
    if (event.key == "p") {
      if(bloquearteclap==false){
mostrarpagarticket();
setBloquearteclap(true);
      }
      
      
    }
  });

  

  const getventas = () => {
    const usuario = localStorage.getItem("velocy");
    const data = {
      usuario: usuario,
      fecha: fecha,
    };
    //enviar datos
    fetch(`${process.env.REACT_APP_API}/listaventas`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          console.log(result);
          setListaventas(result);
          setNumfactura(0)
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  const getticketbuscado = () => {
    const usuario = localStorage.getItem("velocy");
    const serial = document.getElementById("serialticket").value;
    const idagencia = localStorage.getItem("veloci");
    const data = {
      usuario: usuario,
      serial: serial,
      widagencia: idagencia,
    };

    //enviar datos
    fetch(`${process.env.REACT_APP_API}/ticketbuscado`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          console.log(result);
          if (result.length != 0) {
            setNumfactura(result[0].id);
            document.getElementById("serialticket").disabled = true;
            document.getElementById("opcionb12").disabled = false;
            obtenersegundos(result[0].hora);
          }

          setListaticket(result);
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  const getticketbuscado2 = () => {
    const usuario = localStorage.getItem("velocy");
    const serial = document.getElementById("serialticketpago").value;
    const idagencia = localStorage.getItem("veloci");
    const data = {
      usuario: usuario,
      serial: serial,
      widagencia: idagencia,
    };

    //enviar datos
    fetch(`${process.env.REACT_APP_API}/ticketbuscado2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          console.log(result);
          if (result.message == "no hay") {
            console.log(result.message);
          } else {
            setNumfactura2(result[0].id);
            document.getElementById("opcionb17").disabled = false;
            obtenersegundos2(result[0].hora, result[0].fecha);

            document.getElementById("serialticketpago").disabled = true;
          }
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  const getjugadas = () => {
    const data = {
      numfactura: numfactura,
    };
    //enviar datos
    fetch(`${process.env.REACT_APP_API}/listajugadas`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          console.log(result);
          setListajugadas(result);
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  const getjugadas2 = () => {
    const data = {
      numfactura: numfactura2,
    };
    //enviar datos
    fetch(`${process.env.REACT_APP_API}/listajugadas`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          console.log(result);
          setListajugadas2(result);
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  const listItems = animales.map((icono) => (
    <div className="animal-icono" key={icono.codigo}>
      <label className="tamano-letra">{icono.codigo}</label>
      <img
        src={`${process.env.REACT_APP_HOST}/src/animalitos/${icono.codigo}.png`}
        className="animalito"
        onClick={() => {
          mostrar({ icono });
        }}
      />
      <label className="tamano-letra">{icono.title}</label>
    </div>
  ));

  const addmensaje = (mensaje) => {
    if (activarcheq == 0) {
      setActivarCheq(1);
    } else {
      setActivarCheq(0);
    }
  };

  const addmensaje2 = (mensaje2) => {
    console.log(mensaje2);
    setArregloPasado(mensaje2);
  };

  const seleccion1 = () => {
    setListaLoteria("ANIMALITOS");
  };
  const seleccion2 = () => {
    setListaLoteria("TERMINALITO");
  };
  const seleccion3 = () => {
    setListaLoteria("TRIPLE");
  };

  const anularticket = () => {
    if (segundos != 0 && numfactura != 0) {
      document.getElementById("opcionb12").innerHTML = "Anulando...";
      const usuario = localStorage.getItem("velocy");
      const serial = document.getElementById("serialticket").value;
      const data = {
        usuario: usuario,
        serial: serial,
        wid: numfactura,
      };

      //enviar datos
      fetch(`${process.env.REACT_APP_API}/anularticket`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result) {
            console.log(result.message);
            setActivartemp(0);
            setSegundos(0);
            document.getElementById("opcionb12").innerHTML = "Ya Esta Anulado";
            document.getElementById("opcionb12").className =
              "text-white ml-2 pl-2 w-18 h-8 bg-green-600 hover:bg-bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-600 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-green-600 me-2 mb-2";
            document.getElementById("opcionb12").disabled = true;
          } else {
            console.log("ERROR DE CONEXION");
          }
        })
        .catch((error) => {
          console.log(error);
        });
      //fin de envio
    } else {
      document.getElementById("opcionb12").innerHTML = "Tiempo Vencido";
      document.getElementById("opcionb12").className =
        "text-white ml-2 pl-2 w-18 h-8 bg-red-600 hover:bg-bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-600 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-red-600 me-2 mb-2";
      document.getElementById("opcionb12").disabled = true;
    }
  };

  const pagarticket = () => {
    if (segundos2 != 0 && numfactura2 != 0) {
      document.getElementById("opcionb17").innerHTML = "Pagando...";
      const usuario = localStorage.getItem("velocy");
      const serial = document.getElementById("serialticketpago").value;
      const data = {
        usuario: usuario,
        serial: serial,
        wid: numfactura2,
        widagencia: localStorage.getItem("veloci"),
        fechaactual: obtenerfecha(),
      };

      //enviar datos
      fetch(`${process.env.REACT_APP_API}/pagandoticket`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result) {
            console.log(result.message);
            setActivartemp2(0);
            setSegundos2(0);
            setDiasd(0);
            document.getElementById("opcionb17").innerHTML = "Pagado";
            document.getElementById("opcionb17").className =
              "text-white ml-2 pl-2 w-18 h-8 bg-green-600 hover:bg-bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-600 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-green-600 me-2 mb-2";
            document.getElementById("opcionb17").disabled = true;
          } else {
            console.log("ERROR DE CONEXION");
          }
        })
        .catch((error) => {
          console.log(error);
        });
      //fin de envio
    } else {
      document.getElementById("opcionb17").innerHTML = "Ticket Vencido";
      document.getElementById("opcionb17").className =
        "text-white ml-2 pl-2 w-18 h-8 bg-red-600 hover:bg-bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-600 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-red-600 me-2 mb-2";
    }
  };

  const obtenerpremios = () => {
    const data = {
      wid: numfactura2,
    };

    //enviar datos
    fetch(`${process.env.REACT_APP_API}/ticketpremiado`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setListaticketpremio(result);
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  useEffect(() => {
    getventas();
    obtenerconfiggeneral();
  }, [fecha]);

  useEffect(() => {
    obtenertotalapagar();
  }, [listaticketpremio]);

  useEffect(() => {
    obtenerpremios();
    getjugadas2();
  }, [numfactura2]);

  useEffect(() => {
    getjugadas();
    if (numfactura > 0) {
      getticketbuscado();
    }
  }, [numfactura]);

  useEffect(() => {
    setTimeout(() => {
      if (activartemp == 1) {
        if (segundos != 0) {
          setSegundos(segundos - 1);
        } else {
          setActivartemp(0);
          setSegundos(0);
        }
      }
    }, 1000);
  }, [segundos]);

  useEffect(() => {
    setTimeout(() => {
      if (activartemp2 == 1) {
        if (segundos2 != 0) {
          setSegundos2(segundos2 - 1);
        } else {
          setActivartemp2(0);
          setSegundos2(0);
        }
      }
    }, 1000);
  }, [segundos2]);

  useEffect(() => {
    setTimeout(() => {
      if (activartemp == 0) {
        setSegundos(0);
      }
    }, 1000);
  }, [activartemp]);

  useEffect(() => {
    setTimeout(() => {
      if (activartemp2 == 0) {
        setSegundos2(0);
      }
    }, 1000);
  }, [activartemp2]);

  return (
    <>
      <Menu tomadia={tomadia} />
      <div class="grid grid-cols-2 gap-2">
        <div className="flex flex-col">
          <span className="text-xl pt-4 pl-4 font-bold text-blue-950">
            Sistema de Venta
          </span>
          <div className="flex flex-row place-content-start pl-4">
            {listaloteria == "ANIMALITOS" ? (
              <button
                type="button"
                class="text-white bg-[#ce3a2a] hover:bg-[#ce3a2a]/90 focus:ring-4 focus:outline-none focus:ring-[#ce3a2a]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#ce3a2a]/55 me-2 mb-2"
              >
                <svg
                  class="w-6 h-6 text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M18 17h-.09c.058-.33.088-.665.09-1v-1h1a1 1 0 0 0 0-2h-1.09a5.97 5.97 0 0 0-.26-1H17a2 2 0 0 0 2-2V8a1 1 0 1 0-2 0v2h-.54a6.239 6.239 0 0 0-.46-.46V8a3.963 3.963 0 0 0-.986-2.6l.693-.693A1 1 0 0 0 16 4V3a1 1 0 1 0-2 0v.586l-.661.661a3.753 3.753 0 0 0-2.678 0L10 3.586V3a1 1 0 1 0-2 0v1a1 1 0 0 0 .293.707l.693.693A3.963 3.963 0 0 0 8 8v1.54a6.239 6.239 0 0 0-.46.46H7V8a1 1 0 0 0-2 0v2a2 2 0 0 0 2 2h-.65a5.97 5.97 0 0 0-.26 1H5a1 1 0 0 0 0 2h1v1a6 6 0 0 0 .09 1H6a2 2 0 0 0-2 2v2a1 1 0 1 0 2 0v-2h.812A6.012 6.012 0 0 0 11 21.907V12a1 1 0 0 1 2 0v9.907A6.011 6.011 0 0 0 17.188 19H18v2a1 1 0 0 0 2 0v-2a2 2 0 0 0-2-2Zm-4-8.65a5.922 5.922 0 0 0-.941-.251l-.111-.017a5.52 5.52 0 0 0-1.9 0l-.111.017A5.925 5.925 0 0 0 10 8.35V8a2 2 0 1 1 4 0v.35Z" />
                </svg>
                Animalitos
              </button>
            ) : (
              <button
                type="button"
                onClick={seleccion1}
                class="text-white bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
              >
                <svg
                  class="w-6 h-6 text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M18 17h-.09c.058-.33.088-.665.09-1v-1h1a1 1 0 0 0 0-2h-1.09a5.97 5.97 0 0 0-.26-1H17a2 2 0 0 0 2-2V8a1 1 0 1 0-2 0v2h-.54a6.239 6.239 0 0 0-.46-.46V8a3.963 3.963 0 0 0-.986-2.6l.693-.693A1 1 0 0 0 16 4V3a1 1 0 1 0-2 0v.586l-.661.661a3.753 3.753 0 0 0-2.678 0L10 3.586V3a1 1 0 1 0-2 0v1a1 1 0 0 0 .293.707l.693.693A3.963 3.963 0 0 0 8 8v1.54a6.239 6.239 0 0 0-.46.46H7V8a1 1 0 0 0-2 0v2a2 2 0 0 0 2 2h-.65a5.97 5.97 0 0 0-.26 1H5a1 1 0 0 0 0 2h1v1a6 6 0 0 0 .09 1H6a2 2 0 0 0-2 2v2a1 1 0 1 0 2 0v-2h.812A6.012 6.012 0 0 0 11 21.907V12a1 1 0 0 1 2 0v9.907A6.011 6.011 0 0 0 17.188 19H18v2a1 1 0 0 0 2 0v-2a2 2 0 0 0-2-2Zm-4-8.65a5.922 5.922 0 0 0-.941-.251l-.111-.017a5.52 5.52 0 0 0-1.9 0l-.111.017A5.925 5.925 0 0 0 10 8.35V8a2 2 0 1 1 4 0v.35Z" />
                </svg>
                Animalitos
              </button>
            )}
            {listaloteria == "TERMINALITO" ? (
              <button
                type="button"
                class="text-white bg-[#ce3a2a] hover:bg-[#ce3a2a]/90 focus:ring-4 focus:outline-none focus:ring-[#ce3a2a]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#ce3a2a]/55 me-2 mb-2"
              >
                <svg
                  class="w-6 h-6 text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H8Zm7 0a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Terminalitos
              </button>
            ) : (
              <button
                type="button"
                onClick={seleccion2}
                class="text-white bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
              >
                <svg
                  class="w-6 h-6 text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H8Zm7 0a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Terminalitos
              </button>
            )}
            {listaloteria == "TRIPLE" ? (
              <button
                type="button"
                class="text-white bg-[#ce3a2a] hover:bg-[#ce3a2a]/90 focus:ring-4 focus:outline-none focus:ring-[#ce3a2a]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#ce3a2a]/55 me-2 mb-2"
              >
                <svg
                  class="w-6 h-6 text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-width="2"
                    d="M9 8h10M9 12h10M9 16h10M4.99 8H5m-.02 4h.01m0 4H5"
                  />
                </svg>
                Triple
              </button>
            ) : (
              <button
                type="button"
                onClick={seleccion3}
                class="text-white bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
              >
                <svg
                  class="w-6 h-6 text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-width="2"
                    d="M9 8h10M9 12h10M9 16h10M4.99 8H5m-.02 4h.01m0 4H5"
                  />
                </svg>
                Triple
              </button>
            )}
          </div>
          <div className="pl-4 ">
            <Loterias
              tipo={listaloteria}
              activarchequeo={activarcheq}
              addmensaje2={addmensaje2}
              diasemana={diasemana}
            />
          </div>
        </div>

        <div className="pr-4">
          <Formapuesta
            tipo2={listaloteria}
            addmensaje={addmensaje}
            arreglopasado={arreglopasado}
            activarchequeo={activarcheq}
          />
        </div>
        
        
      </div>
      <div className="mt-4 ml-4 flex flex-row" >
        <button
              type="button"
              id="anularticket"
              onClick={anulaciont}
              class="text-white bg-blue-950 hover:bg-blue-950/90 focus:ring-4 focus:outline-none focus:ring-blue-950/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-blue-950/55 me-2 mb-2"
            >
              <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="m6 6 12 12m3-6a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
</svg>

              &nbsp;Anular Ticket (A)
            </button>
            <button
              type="button"
              id="anularticket"
              onClick={mostrarpagarticket}
              class="text-white bg-blue-950 hover:bg-blue-950/90 focus:ring-4 focus:outline-none focus:ring-blue-950/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-blue-950/55 me-2 mb-2"
            >
              <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8H5m12 0a1 1 0 0 1 1 1v2.6M17 8l-4-4M5 8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.6M5 8l4-4 4 4m6 4h-4a2 2 0 1 0 0 4h4a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1Z"/>
</svg>


              &nbsp;Pagar Ticket (P)
            </button>
            <button
              type="button"
              id="anularticket"
              onClick={ventasdiarias}
              class="text-white bg-blue-950 hover:bg-blue-950/90 focus:ring-4 focus:outline-none focus:ring-blue-950/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-blue-950/55 me-2 mb-2"
            >
              <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6Zm2 8v-2h7v2H4Zm0 2v2h7v-2H4Zm9 2h7v-2h-7v2Zm7-4v-2h-7v2h7Z" clip-rule="evenodd"/>
</svg>



              &nbsp;Ventas Diarias (K)
            </button>
        </div>
      {modal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-12 bg-black rounded-t-lg place-content-center flex flex-grow text-white text-md font-semibold">
                  <span className="mt-2 pl-2">Anulacion de Ticket</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1">
                    <div className="flex flex-row flex-wrap">
                      <div className="flex flex-row text-blue-950 text-md font-bold">
                        <span className="text-sm mt-1">Serial:</span>
                        <input
                          type="text"
                          id="serialticket"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handlerFuntion();
                          }}
                          class="bg-gray-50 ml-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[50%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder=""
                        />

                        <button
                          type="button"
                          id="opcionlimpiar1"
                          onClick={limpiardatos}
                          class="text-white ml-2 pl-2 w-18 h-8 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
                        >
                          Limpiar
                        </button>
                      </div>
                    </div>

                    <div class="relative pt-4 overflow-x-auto shadow-md sm:rounded-lg flex flex-row flex-wrap">
                      <div className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm">
                        VENTAS
                      </div>
                      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" class="px-6 py-1">
                              #
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Caja
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Hora
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Estatus
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {listaticket.map((val, key) => {
                            return (
                              <tr
                                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-sm"
                                key={val.id}
                                className="text-center"
                                onClick={() => {
                                  setNumfactura(val.id);
                                  getjugadas();
                                }}
                              >
                                <th
                                  scope="row"
                                  class="px-6 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                  {val.id}
                                </th>
                                <td class="px-6 py-1">{val.user}</td>
                                <td class="px-6 py-1">{val.hora}</td>
                                <td class="px-6 py-1">{val.estatus}</td>
                                <td class="px-6 py-1">{val.total}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm">
                        JUGADAS ----- #Ticket: {numfactura}
                      </div>
                      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" class="px-6 py-1">
                              Loteria
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Tipo
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Numero
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Monto
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {listajugadas.map((val, key) => {
                            return (
                              <tr
                                key={val.id}
                                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-sm"
                              >
                                <th
                                  scope="row"
                                  class="px-6 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                  {val.loteria}
                                </th>
                                <td class="px-6 py-1">{val.tipo}</td>
                                <td class="px-6 py-1">{val.codigo}</td>
                                <td class="px-6 py-1">{val.monto}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div className="flex flex-row place-content-end mt-2 mr-[-10px]">
                      <span className=" text-red-600 text-sm mt-2">
                        Tienes ({segundos}) Segundos para{" "}
                      </span>

                      <button
                        type="button"
                        id="opcionb12"
                        onClick={anularticket}
                        class="text-white ml-2 pl-2 w-18 h-8 bg-[#cb2121] hover:bg-[#cb2121]/90 focus:ring-4 focus:outline-none focus:ring-[#cb2121]/50 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-[#cb2121]/55 me-2 mb-2"
                      >
                        <svg
                          class="w-[18px] h-[18px] text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-width="2"
                            d="m6 6 12 12m3-6a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                        Anular Este Ticket
                      </button>
                    </div>
                  </div>
                </div>
                <div className="h-14 bg-black rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <button
                    type="button"
                    onClick={() => {
                      setBloquearteclaa(false);
                      limpiardatos();
                      setModal(false);
                      
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {modalticket ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-12 bg-black rounded-t-lg place-content-center flex flex-grow text-white text-md font-semibold">
                  <span className="mt-2 pl-2">Pago de Ticket</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1">
                    <div className="flex flex-row flex-wrap">
                      <div className="flex flex-row text-blue-950 text-md font-bold">
                        <span className="text-sm mt-1">Serial:</span>
                        <input
                          type="text"
                          id="serialticketpago"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handlerFuntion2();
                          }}
                          class="bg-gray-50 ml-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[50%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder=""
                        />

                        <button
                          type="button"
                          id="opcionlimpiar1"
                          onClick={limpiardatos2}
                          class="text-white ml-2 pl-2 w-18 h-8 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
                        >
                          Limpiar
                        </button>
                      </div>
                    </div>

                    <div class="relative pt-4 overflow-x-auto shadow-md sm:rounded-lg flex flex-row flex-wrap">
                      <div className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm">
                        TICKET PREMIADO
                      </div>
                      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" class="px-6 py-1">
                              #
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Caja
                            </th>
                            <th scope="col" class="px-6 py-1">
                              No.
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Monto
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Premio
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {listaticketpremio.map((val, key) => {
                            return (
                              <tr
                                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-sm"
                                key={val.id}
                                className="text-center"
                              >
                                <th
                                  scope="row"
                                  class="px-6 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                  {val.idticket}
                                </th>
                                <td class="px-6 py-1">{val.idusuario}</td>
                                <td class="px-6 py-1">{val.codigo}</td>
                                <td class="px-6 py-1">{val.monto}</td>
                                <td class="px-6 py-1 text-green-600 font-bold">
                                  {val.premio}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm">
                        JUGADAS ----- #Ticket: {numfactura2}
                      </div>
                      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" class="px-6 py-1">
                              Loteria
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Tipo
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Numero
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Monto
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {listajugadas2.map((val, key) => {
                            return (
                              <tr
                                key={val.id}
                                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-sm"
                              >
                                <th
                                  scope="row"
                                  class="px-6 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                  {val.loteria}
                                </th>
                                <td class="px-6 py-1">{val.tipo}</td>
                                <td class="px-6 py-1">{val.codigo}</td>
                                <td class="px-6 py-1">{val.monto}</td>
                                {val.estatus == "premiado" ? (
                                  <svg
                                    class="w-6 h-6 text-green-500"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                ) : (
                                  <span></span>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="flex flex-row place-content-end">
                      <span className="text-xl">
                        <strong>
                          Total a Pagar:{" "}
                          <span className="text-green-700">{totalapagar}</span>
                        </strong>
                      </span>
                    </div>
                    <div className="flex flex-row place-content-end mt-2 mr-[-10px]">
                      <span className=" text-red-600 text-sm mt-2">
                        Tienes ({segundos2}) Segundos &oacute; ({diasd} Dias)
                        para{" "}
                      </span>

                      <button
                        type="button"
                        id="opcionb17"
                        onClick={pagarticket}
                        class="text-white ml-2 pl-2 w-18 h-8 bg-[#3585b7] hover:bg-[#3585b7]/90 focus:ring-4 focus:outline-none focus:ring-[#3585b7]/50 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-[#3585b7]/55 me-2 mb-2"
                      >
                        <svg
                          class="w-[18px] h-[18px] text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M11 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
                          <path
                            fill-rule="evenodd"
                            d="M9.896 3.051a2.681 2.681 0 0 1 4.208 0c.147.186.38.282.615.255a2.681 2.681 0 0 1 2.976 2.975.681.681 0 0 0 .254.615 2.681 2.681 0 0 1 0 4.208.682.682 0 0 0-.254.615 2.681 2.681 0 0 1-2.976 2.976.681.681 0 0 0-.615.254 2.682 2.682 0 0 1-4.208 0 .681.681 0 0 0-.614-.255 2.681 2.681 0 0 1-2.976-2.975.681.681 0 0 0-.255-.615 2.681 2.681 0 0 1 0-4.208.681.681 0 0 0 .255-.615 2.681 2.681 0 0 1 2.976-2.975.681.681 0 0 0 .614-.255ZM12 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                            clip-rule="evenodd"
                          />
                          <path d="M5.395 15.055 4.07 19a1 1 0 0 0 1.264 1.267l1.95-.65 1.144 1.707A1 1 0 0 0 10.2 21.1l1.12-3.18a4.641 4.641 0 0 1-2.515-1.208 4.667 4.667 0 0 1-3.411-1.656Zm7.269 2.867 1.12 3.177a1 1 0 0 0 1.773.224l1.144-1.707 1.95.65A1 1 0 0 0 19.915 19l-1.32-3.93a4.667 4.667 0 0 1-3.4 1.642 4.643 4.643 0 0 1-2.53 1.21Z" />
                        </svg>
                        Pagar Ticket
                      </button>
                    </div>
                  </div>
                </div>
                <div className="h-14 bg-black rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <button
                    type="button"
                    onClick={() => {
                      setBloquearteclap(false);
                      limpiardatos2();
                      setModalTicket(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {modalventas ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-12 bg-black rounded-t-lg place-content-center flex flex-grow text-white text-md font-semibold">
                  <span className="mt-2 pl-2">Ventas del Dia</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1">
                    <div className="flex flex-row flex-wrap">
                      <div className="flex flex-row text-blue-950 text-md font-bold">
                        <span className="text-sm mt-1">Fecha:</span>
                        <input
                          type="date"
                          id="serialticket"
                          onChange={(e) => setFecha(e.target.value)}
                          class="bg-gray-50 ml-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[70%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          value={fecha}
                          min="2024-01-01"
                          max="2034-12-31"
                        />
                        {/* <input
                          type="date"
                          value={fecha}
                          onChange={(e) => setFecha(e.target.value)}
                          className="titulo3"
                          min="2024-01-01"
                          max="2034-12-31"
                        ></input> */}
                      </div>
                    </div>

                    <div class="relative pt-4 overflow-x-auto shadow-md sm:rounded-lg flex flex-row flex-wrap">
                      <div className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm">
                        VENTAS
                      </div>
                      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" class="px-6 py-1 text-center">
                              #
                            </th>
                            <th scope="col" class="px-6 py-1 text-center">
                              Caja
                            </th>
                            <th scope="col" class="px-6 py-1 text-center">
                              Hora
                            </th>
                            <th scope="col" class="px-6 py-1 text-center">
                              Estatus
                            </th>
                            <th scope="col" class="px-6 py-1 text-center">
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {listaventas.map((val, key) => {
                            return (
                              <tr
                                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-sm"
                                key={val.id}
                                className="text-center"
                                onClick={() => {
                                  setNumfactura(val.id);
                                  getjugadas();
                                }}
                              >
                                <th
                                  scope="row"
                                  class="px-6 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                  <span className="bg-slate-500 p-[2px] border-1 cursor-pointer hover:bg-emerald-700 hover:text-white border-black rounded-md mb-2">
                                    {val.id}
                                  </span>
                                </th>
                                <td class="px-6 py-1">{val.user}</td>
                                <td class="px-6 py-1">{val.hora}</td>
                                <td class="px-6 py-1">{val.estatus}</td>
                                <td class="px-6 py-1">{val.total}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm">
                        JUGADAS ----- #Ticket: {numfactura}
                      </div>
                      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" class="px-6 py-1">
                              Loteria
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Tipo
                            </th>
                            <th scope="col" class="px-6 py-1 text-center">
                              Numero
                            </th>
                            <th scope="col" class="px-6 py-1 text-center">
                              Monto
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {listajugadas.map((val, key) => {
                            return (
                              <tr
                                key={val.id}
                                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-sm"
                              >
                                <th
                                  scope="row"
                                  class="px-6 py-1 font-medium text-blue-950 whitespace-nowrap dark:text-white"
                                >
                                  {val.loteria}
                                </th>
                                <td class="px-6 py-1">{val.tipo}</td>
                                <td class="px-6 py-1 text-center">
                                  {val.codigo}
                                </td>
                                <td class="px-6 py-1 text-center">
                                  {val.monto}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="h-14 bg-black rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <button
                    type="button"
                    onClick={() => {
                      setModalVentas(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Home2;
