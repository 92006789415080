import React from "react";
import Axios from "axios";
import { useState, useEffect } from "react";
import Menu from "./menu.jsx";
import moment from "moment";
import Loterias from "./loterias.jsx";
import Formapuesta from "./formapuesta.jsx";
import Listausers from "../componentes/listausers.jsx";
import Formuderecho from "../componentes/formuderecho.jsx";
import Formuimpresion from "../componentes/formuimpresion.jsx";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Listagenerador from "./listagenerador.jsx";
import Formureports from "./formureports.jsx";

var date = new Date();

var dia = new Intl.DateTimeFormat("en-US", {
  dateStyle: "full",
  timeStyle: "long",
  timeZone: "America/New_York",
}).format(date);

console.log(date);

const fecha1 = "2017-09-06T20:00:51";
const fecha2 = "2017-12-06T20:00:00";

var a = new Date(fecha1);
var b = new Date(fecha2);

console.log(+a > +b); //false
console.log(+a < +b); //false
console.log(+a == +b); //true

var a1 = new Date("2017-12-01T03:29:50");
var b1 = new Date("2017-12-06T03:11:00");
//La diferencia se da en milisegundos así que debes dividir entre 1000
var c = (a1 - b1) / 1000 / 60;
console.log(Math.trunc(c)); // resultado 5;


//CREAR LA FECHA
const timestamp = Date.now();
var dateDB = new Date(timestamp);

var monthArrayNames = [];
monthArrayNames[0] = "01";
monthArrayNames[1] = "02";
monthArrayNames[2] = "03";
monthArrayNames[3] = "04";
monthArrayNames[4] = "05";
monthArrayNames[5] = "06";
monthArrayNames[6] = "07";
monthArrayNames[7] = "08";
monthArrayNames[8] = "09";
monthArrayNames[9] = "10";
monthArrayNames[10] = "11";
monthArrayNames[11] = "12";

var day = dateDB.getDate();
if (day === 1) {
  day = "01";
}
if (day === 2) {
  day = "02";
}
if (day === 3) {
  day = "03";
}
if (day === 4) {
  day = "04";
}
if (day === 5) {
  day = "05";
}
if (day === 6) {
  day = "06";
}
if (day === 7) {
  day = "07";
}
if (day === 8) {
  day = "08";
}
if (day === 9) {
  day = "09";
}

var month = monthArrayNames[dateDB.getMonth()];
var year = dateDB.getFullYear();
var timestampobj = `${year}-${month}-${day}`;

const Report = (props) => {
    const [listaloteria, setListaLoteria] = useState("ANIMALITOS");
  const [activarcheq, setActivarCheq] = useState(1);
  const [arreglopasado, setArregloPasado] = useState([]);
  const [formutipo, setFormutipo] = useState("nuevo");
  const [valorid, setValorid] = useState(0);
  const [mostrarhorario, setMostrarhorario] = useState([]);
  const [parte1, setParte1] = useState([]);
  const [parte2, setParte2] = useState([]);
  const [resultados, setResultados] = useState([]);
  const [fecha, setFecha] = useState(timestampobj);
  const [fecha2, setFecha2] = useState(timestampobj);
  const [configag, setConfigag] = useState([]);
  const [listacajas, setListacajas] = useState([]);

  const [diasemana, setDiasemana] = useState(0);

  const tomardia = (mensa) => {};

  const addmensaje = (mensaje) => {
    if (activarcheq == 0) {
      setActivarCheq(1);
    } else {
      setActivarCheq(0);
    }
    setFormutipo(mensaje);
    console.log("FORMULARIO ES: " + mensaje);
  };

  const addtipo = (mtipo) => {
    setFormutipo(mtipo);
  };

  const addvalor = (mvalor) => {
    setValorid(mvalor);
    gethorario(mvalor);
    getresultados(mvalor, fecha);
  };

  const gethorario = (mvalor) => {
    var data2;

    Axios.get(`${process.env.REACT_APP_API}/mostrarhorario/${mvalor}`).then(
      (response) => {
        setMostrarhorario(response.data);
        data2 = response.data;

        var dato1 = [
          { id1: 0, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id1: 1, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id1: 2, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id1: 3, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id1: 4, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id1: 5, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id1: 6, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id1: 7, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id1: 8, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id1: 9, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id1: 10, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id1: 11, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
        ];

        var dato3 = [
          { id2: 12, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id2: 13, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id2: 14, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id2: 15, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id2: 16, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id2: 17, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id2: 18, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id2: 19, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id2: 20, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id2: 21, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id2: 22, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
          { id2: 23, id: 0, idloteria: 0, hora: "00:00:00", estatus: 0 },
        ];

        for (let i = 0; i < 12; i++) {
          dato1[i].id = data2[i].id;
          dato1[i].idloteria = data2[i].idloteria;
          dato1[i].hora = data2[i].hora;
          dato1[i].estatus = data2[i].estatus;
          if (data2[i].estatus == 1) {
            existenciaderesultados(data2[i].id, data2[i].idloteria);
          }
        }

        for (let i = 0; i < 12; i++) {
          dato3[i].id = data2[i + 12].id;
          dato3[i].idloteria = data2[i + 12].idloteria;
          dato3[i].hora = data2[i + 12].hora;
          dato3[i].estatus = data2[i + 12].estatus;
          if (data2[i + 12].estatus == 1) {
            existenciaderesultados(data2[i + 12].id, data2[i + 12].idloteria);
          }
        }

        setParte1(dato1);
        setParte2(dato3);
      }
    );
  };

  const getresultados = (id, fecha) => {
    const data = {
      idloteria: id,
      fecha: fecha,
    };
    //enviar datos
    fetch(`${process.env.REACT_APP_API}/obtenerresultadost`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setResultados(result);
          //ACTUAL USUARIOS
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const tomadia = (mensaje) => {
    setDiasemana(mensaje);
    console.log(mensaje);
  };

  const obtenerconfiggeneral = () => {
    const data = {
      wid: localStorage.getItem("velocy"),
    };

    //enviar datos
    fetch(`${process.env.REACT_APP_API}/obtenerconfiggeneral`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setConfigag(result);
          console.log(result);
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  const obtenercajas = () => {
    const data = {
      wid: localStorage.getItem("velocy"),
    };

    //enviar datos
    fetch(`${process.env.REACT_APP_API}/obtenerlistacajas`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setListacajas(result);
          console.log(result);
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  const existenciaderesultados = (idhorario, idloteria) => {
    const data = {
      idhorario: idhorario,
      fecha: fecha,
      idloteria: idloteria,
    };
    //enviar datos

    fetch(`${process.env.REACT_APP_API}/obtenerresultados`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {})
      .catch((error) => {
        console.log(error);
      });
    //CHEQUE TERMINADO
  };

  useEffect(() => {
    //gethorario(valorid);
    getresultados(valorid, fecha);
  }, [fecha, formutipo]);

  useEffect(() => {
    obtenerconfiggeneral();
    obtenercajas();
  }, []);
  
  
  return (
    <>
      <Menu tomadia={tomadia} />
      <div class="grid grid-cols-2 gap-2">

    <div>
    <span className="text-xl pt-4 pl-4 font-bold text-blue-950 flex flex-col">
           Generador de Reportes
          </span>
          

              <div className="ml-4">
              <div className="container-fluid2">
                            <div className="container-dentro">
                              <div class="form-group">
                                <table width="100%">
                                  <tr>
                                    <td>
                                      <span class="horastyle font-bold text-red-500">
                                        Desde:&nbsp;
                                      </span>
                                      <input
                                        type="date"
                                        class="titulo3 bg-gray-400 text-white font-bold rounded-md"
                                        min="2024-01-01"
                                        max="2034-12-31"
                                        value={fecha}
                                        onChange={(e) =>
                                          setFecha(e.target.value)
                                        }
                                      />
                                      <span class="horastyle font-bold text-red-500">
                                        &nbsp; &nbsp; Hasta:&nbsp;
                                      </span>
                                      <input
                                        type="date"
                                        class="titulo3 bg-gray-400 text-white font-bold rounded-md"
                                        min="2024-01-01"
                                        max="2034-12-31"
                                        value={fecha2}
                                        onChange={(e) =>
                                          setFecha2(e.target.value)
                                        }
                                      />
                                    </td>
                                  </tr>
                                </table>
                                <hr className="h-2 bg-indigo-400"/>
                              </div>
                              <Listagenerador
                                activarchequeo={activarcheq}
                                myuser={props.myuser}
                                mytoken={props.mytoken}
                                addtipo={addtipo}
                                addvalor={addvalor}
                                addmensaje={addmensaje}
                              />
                            </div>
                          </div>
                </div>               
    </div>
      

      <div className="flex flex-col place-content-start pl-4 mt-4">

      <span className="text-xl pt-1 pl-0 font-bold text-blue-950 flex flex-col">
           Reporte Generado
          </span>

          <Formureports
                    addmensaje={addmensaje}
                    formutipo={formutipo}
                    valorid={valorid}
                    parte1={parte1}
                    parte2={parte2}
                    fecha={fecha}
                    addtipo={addtipo}
                    resultados={resultados}
                    activarchequeo={activarcheq}
                    fecha2={fecha2}
                    configag={configag}
                    listacajas={listacajas}
                  />
           
        
          </div>
      
   
        
        
      </div>

      <ToastContainer />
     
    </>
  );
};

export default Report;