import React from "react";
import { useState, useEffect } from "react";
import Axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import imgimpresora from "../img/impresora.png";
import imgrevision from "../img/revision.png";



const Ticket = (props) => {
  const [listaticket, setListaticket] = useState([]);
  const username = localStorage.getItem("velocy");
  const [totalgeneral, setTotalGeneral] = useState(0);
  const [ventadiaria, setVentadiaria] = useState([{ id: 0 }]);
  const [configlo, setConfiglo] = useState([]);
  const [configgeneral, setConfigGeneral] = useState([]);
  const [estatuschequeo, setEstatuschequeo] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [revisado, setRevisado] = useState(false);
  const [imprimiendo, setImprimiendo] = useState(false);

  const [bloquearteclamas, setBloquearteclamas] = useState(false)

  const getticket = () => {
    Axios.get(`${process.env.REACT_APP_API}/ticket/${username}`).then(
      (response) => {
        setListaticket(response.data);
        var registros = response.data;
        console.log(response.data);
        setTotalGeneral(0);
        var totalg = 0;
        for (let i = 0; i < registros.length; i++) {
          totalg = totalg + parseInt(registros[i].monto);
        }
        setTotalGeneral(totalg);
      }
    );
  };

  const obtenerfecha2 = () => {
    //TOMAR HORA ACTUAL
    var tomarfecha = "";
    tomarfecha = "" + moment.utc().format();
    tomarfecha = tomarfecha.replace("Z", "");
    var date3 = new Date(tomarfecha);
    date3.setHours(date3.getHours() - 4);
    //FIN DE HORA ACTUAL RESULT EN date3

    var dia;

    if (parseInt(date3.getDate()) > 0 && parseInt(date3.getDate()) < 10) {
      dia = "0" + date3.getDate();
    } else {
      dia = date3.getDate();
    }

    //TOMA LA FECHA EN FORMATO YYYY-MM-DD
    var mes;
    if (date3.getMonth() + 1 > 9) {
      var date4 =
        date3.getFullYear() + "-" + (date3.getMonth() + 1) + "-" + dia;
    } else {
      mes = "0" + (date3.getMonth() + 1);
      var date4 = date3.getFullYear() + "-" + mes + "-" + dia;
    }
    //LA FECHA EN FORMATO YYYY-MM-DD GUARDADA EN LA VARIABLE data4
    console.log(date4);
    return date4;
  };

  const obtenerhora = () => {
    //TOMAR HORA ACTUAL
    var tomarfecha = "";
    tomarfecha = "" + moment.utc().format();
    tomarfecha = tomarfecha.replace("Z", "");
    var date3 = new Date(tomarfecha);
    date3.setHours(date3.getHours() - 4);
    //FIN DE HORA ACTUAL RESULT EN date3
    var hora1 = "";
    var minuto1 = "";
    var segundo1 = "";
    if (date3.getHours() < 10) {
      hora1 = "0" + date3.getHours();
    } else {
      hora1 = date3.getHours();
    }
    if (date3.getMinutes() < 10) {
      minuto1 = "0" + date3.getMinutes();
    } else {
      minuto1 = date3.getMinutes();
    }
    if (date3.getSeconds() < 10) {
      segundo1 = "0" + date3.getSeconds();
    } else {
      segundo1 = date3.getSeconds();
    }
    return hora1 + ":" + minuto1 + ":" + segundo1;
  };

  const chequearcupos = () => {
    var hayerror = 0;
    for (let i = 0; i < listaticket.length; i++) {
      if (
        limiteloteria(
          listaticket[i].id,
          listaticket[i].idloteria,
          listaticket[i].monto,
          listaticket[i].codigo,
          listaticket[i].idhorario
        ) == true
      ) {
        hayerror = 1;
      }
    }
    if (hayerror == 0) {
      if (limiteticket() == true) {
        setModal2(false)
      } else {
        confirmarticket();
      }
    } else {
      //ERROR
      setModal2(false)
      console.log("LOTERIAS CERRADAS")
      document.getElementById("procesarticket").disabled = false;
    }
  };

  const obtenerminutosap2 = (horapasada) => {
    //TOMAR HORA ACTUAL

    var tomarfecha = "";
    tomarfecha = "" + moment.utc().format();
    tomarfecha = tomarfecha.replace("Z", "");
    var date3 = new Date(tomarfecha);
    date3.setHours(date3.getHours() - 4);
    //FIN DE HORA ACTUAL RESULT EN date3

    var dia;
    console.log(date3.getDate());

    if (parseInt(date3.getDate()) > 0 && parseInt(date3.getDate()) < 10) {
      dia = "0" + date3.getDate();
    } else {
      dia = date3.getDate();
    }

    //TOMA LA FECHA EN FORMATO YYYY-MM-DD
    var mes;
    if (date3.getMonth() + 1 > 9) {
      var date4 =
        date3.getFullYear() + "-" + (date3.getMonth() + 1) + "-" + dia;
    } else {
      mes = "0" + (date3.getMonth() + 1);
      var date4 = date3.getFullYear() + "-" + mes + "-" + dia;
    }
    //LA FECHA EN FORMATO YYYY-MM-DD GUARDADA EN LA VARIABLE data4

    //meter la hora en la segunda fecha
    console.log("fecha nueva: " + date4);
    var hora = horapasada;
    var date5 = new Date(date4 + "T" + hora);
    //CALCULANDO LOS MINUTOS QUE FALTAN
    var c = (date5 - date3) / 1000 / 60;

    //FIN DE CALCULO
    return Math.trunc(c);
  };

  const ejecutarticket = () => {
    setModal2(true);
    setRevisado(false);
    
    document.getElementById("procesarticket").disabled = true;
    if (listaticket.length == 0) {
      console.log("NO HAY NADA QUE PROCESAR");
      document.getElementById("procesarticket").disabled = false;
      setModal2(false);
    } else {
      var hayerror1 = 0;

      for (let i = 0; i < listaticket.length; i++) {
        console.log(obtenerfecha2());
        console.log(listaticket[i].fecha);
        if (obtenerfecha2() == listaticket[i].fecha) {
          if (obtenerminutosap2(listaticket[i].hora) < 5) {
            document.getElementById("row" + listaticket[i].id).className =
              "text-red-500";
            document.getElementById("trow" + listaticket[i].id).className =
              "text-red-500";
            document.getElementById("mrow" + listaticket[i].id).className =
              "text-red-500";
            hayerror1 = hayerror1 + 1;
          }
        } else {
          document.getElementById("row" + listaticket[i].id).className =
            "text-red-500";
          document.getElementById("trow" + listaticket[i].id).className =
            "text-red-500";
          document.getElementById("mrow" + listaticket[i].id).className =
            "text-red-500";

          hayerror1 = hayerror1 + 1;
        }
      }
      if (hayerror1 == 0) {
        console.log("VAMOS A PROCESAR ENTONCES");

        chequearcupos();
      } else {
        console.log("CHEQUE TU TICKET HAY LOTERIAS CERRADAS");
        setModal2(false)
        document.getElementById("procesarticket").disabled = false;
      }
    }
  };

  const eliminartemp = (idticket) => {
    const data = {
      username: username,
    };
    fetch(`${process.env.REACT_APP_API}/deltempticket`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);

        if (result) {
          console.log(result.message);
          if (result.message == "Actualizar") {
            setTimeout(() => {
              //window.location = "";
            }, 3000);
          }
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });

   //CONSULTAR EL TICKET CREADO PARA IMPRIMIR
   
   const data2 = {
    id: idticket,
  };

  console.log(idticket)

 fetch(`${process.env.REACT_APP_API}/imprimirticket`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data2),
  })
    .then((response) => response.json())
    .then((result) => {
      console.log(result);

      enviaraimpresora(result)
     
    })
    .catch((error) => {
      console.log(error);
    });



  };

  const enviaraimpresora = (data) => {

    var botonImprimirticket = document.getElementById("btnImprimirticket");
    console.log(data)


    var dataprint = [];

     for (let i = 0; i < data.length; i++) {
        dataprint.push(data[i]);
     }

     console.log(dataprint)


    const jsonArray = JSON.stringify(data);
    document.getElementById('hiddenticket').value = jsonArray;

            botonImprimirticket.click();
  }

  const guardarcuerpo = (idticket) => {
    for (let i = 0; i < listaticket.length; i++) {
      const data = {
        username: username,
        idagencia: localStorage.getItem("veloci"),
        idticket: idticket,
        idloteria: listaticket[i].idloteria,
        idhorario: listaticket[i].idhorario,
        codigo: listaticket[i].codigo,
        tipo: listaticket[i].tipo,
        loteria: listaticket[i].loteria,
        proporcion: listaticket[i].proporcion,
        monto: listaticket[i].monto,
        fecha: obtenerfecha2(),
      };
      fetch(`${process.env.REACT_APP_API}/agregarcuerpo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((result) => {
          console.log(result);

          if (result) {
            console.log("DATOS GUARDADOS");
            //setModal2(false)
          } else {
            console.log("ERROR DE CONEXION");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    eliminartemp(idticket);
  };

  const deleterow = (val) => {
    console.log("EJECUTANDOSE DELETE");
    Axios.delete(`${process.env.REACT_APP_API}/ticket/${val.id}`).then(
      (res) => {
        getticket();
      }
    );
  };

  const confirmarticket = () => {
    setRevisado(true);
    procesarticket();
  };
  const procesarticket = () => {
    const data = {
      username: username,
      total: totalgeneral,
      hora: obtenerhora(),
      fecha: obtenerfecha2(),
      idagencia: localStorage.getItem("veloci"),
    };
    console.log(data);
    console.log(listaticket)


//     // Ordenar por hora
// listaticket.sort((a, b) => {
//   return a.hora.localeCompare(b.hora);
// });

// // Mostrar el resultado
// console.log(listaticket);


    //return
  
    fetch(`${process.env.REACT_APP_API}/agregarticket`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        var resultado = result.idticket["max(id)"];
        console.log(resultado);

        if (result) {
          console.log("VER TICKET " + resultado);
          guardarcuerpo(resultado);
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verificarlimites = () => {
    setEstatuschequeo(true);
    setVentadiaria({ id: 0 });
    //INICIO DE BUSQUEDA DE CUPOS
    console.log("SE EJECUTO UNA VES VERIFICARLIMITES")

    var wfecha = obtenerfecha2();

    const data = {
      fecha: wfecha,
      idagencia: localStorage.getItem("veloci"),
    };

    // enviar datos
    fetch(`${process.env.REACT_APP_API}/cuposdisponibles2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setVentadiaria(result);
      })
      .catch((error) => {
        console.log(error);
      });

    //fin de envio
  };

  const limiteloteria = (id, idloteria, monto, codigo, idhorario) => {
    var titulo = "";
    var cuposdisponibles = 0;
    var hayerror = false;

    var idagencia = localStorage.getItem("veloci");

    for (let i = 0; i < configlo.length; i++) {
      if (idloteria == configlo[i].idloteria) {
        for (let i = 0; i < ventadiaria.length; i++) {
          if (
            codigo == ventadiaria[i].codigo &&
            idagencia == ventadiaria[i].idagencia &&
            idhorario == ventadiaria[i].idhorario
          ) {
            console.log("REALIZANDO APUESTA EN: " + ventadiaria[i].id);
            cuposdisponibles =
              cuposdisponibles + parseInt(ventadiaria[i].monto);
          }
        }
        cuposdisponibles =
          parseInt(configlo[i].limite_cupos) - parseInt(cuposdisponibles);
      }
    }

    if (parseInt(monto) > parseInt(cuposdisponibles)) {
      titulo = "Cupos Disponibles: " + cuposdisponibles;
      hayerror = true;
    }

    //FIN DE BUSQUEDA DE CUPOS

    if (hayerror == true) {
      console.log("ERRORRRRRR JUDADA");
      document.getElementById("row" + id).className = "text-blue-700";
      document.getElementById("trow" + id).className = "text-blue-700";
      document.getElementById("mrow" + id).className = "text-blue-700";
      document.getElementById("icont" + id).title = titulo;
    }

    return hayerror;
  };

  const limiteticket = () => {
    var hayerror = false;

    var idagencia = localStorage.getItem("veloci");

    for (let i = 0; i < configgeneral.length; i++) {
      if (idagencia == configgeneral[i].idusuario) {
        if (parseInt(totalgeneral) < configgeneral[i].min_ticket) {
          hayerror = true;
          Swal.fire({
            icon: "error",
            title: "Venta Minima",
            text:
              "La Venta Minima por Ticket es: " + configgeneral[i].min_ticket,
          });
          document.getElementById("procesarticket").disabled = false;
        }
        if (parseInt(totalgeneral) > configgeneral[i].max_ticket) {
          Swal.fire({
            icon: "error",
            title: "Venta Maxima",
            text:
              "La Venta Maxima por Ticket es: " + configgeneral[i].max_ticket,
          });
          hayerror = true;
          document.getElementById("procesarticket").disabled = false;
        }
      }
    }

    return hayerror;
  };

  const verificatodo = () => {
    if (ventadiaria.length == 0) {
      ejecutarticket();
    } else {
      if (ventadiaria.id == 0) {
      } else {
        ejecutarticket();
      }
    }
  };

  document.addEventListener("keydown", function (event) {
    if (event.key == "+") {
      setImprimiendo(true);

    }
  });

  const obtenerconfiglo = () => {
    const data = {
      wid: localStorage.getItem("veloci"),
    };

    //enviar datos
    fetch(`${process.env.REACT_APP_API}/obtenerconfiglo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setConfiglo(result);
          console.log(result);
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  const obtenerconfiggeneral = () => {
    const data = {
      wid: localStorage.getItem("veloci"),
    };

    //enviar datos
    fetch(`${process.env.REACT_APP_API}/obtenerconfiggeneral`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setConfigGeneral(result);
          console.log(result);
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  function convertira12horas(time) {
    // Separar la hora, minutos y segundos
    const [hours, minutes, seconds] = time.split(':').map(Number);

    // Determinar si es AM o PM
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convertir a formato de 12 horas
    const adjustedHours = hours % 12 || 12; // Si hours es 0, se convierte a 12

    // Formatear la cadena de tiempo en 12 horas
    return `${adjustedHours}:${String(minutes).padStart(2, '0')} ${period}`;
}

  useEffect(() => {
    getticket();
    obtenerconfiglo();
    obtenerconfiggeneral();
  }, [props]);

  useEffect(() => {
    verificatodo();

    console.log(ventadiaria);
  }, [ventadiaria]);

  useEffect(() => {
    if(imprimiendo == true){
      verificarlimites()
    }
  }, [imprimiendo]);

  return (
    <>
    <div>
      <div class="relative pt-2  w-full shadow-md sm:rounded-lg flex flex-col flex-wrap">
        <div
          id="fondolote"
          className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm"
        >
          SUS JUGADAS
        </div>
        <div className="">
          <table class="w-[100%] table2 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <tbody>
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 w-full">
                <tr className="w-full">
                  <th scope="col" class="px-6 py-1">
                    Eliminar
                  </th>
                  <th scope="col" class="px-6 py-1">
                    #
                  </th>
                  <th scope="col" class="px-6 py-1">
                    Loteria
                  </th>
                  <th scope="col" class="px-6 py-1">
                    Jugada
                  </th>
                </tr>
                {listaticket.map((val, key) => {
                  return (
                    <tr key={val.id}>
                      <th align="center">
                        <svg
                          class="w-6 h-6 text-red-700 cursor-pointer"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          onClick={() => {
                            deleterow(val);
                          }}
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </th>

                      <td className="text-center">
                        <span id={"row" + val.id}>{val.codigo}</span>
                        &nbsp;
                        <span id={"icont" + val.id}></span>
                      </td>
                      <td className="text-center">
                        <span id={"trow" + val.id}>{val.loteria} {convertira12horas(val.hora)}</span>
                      </td>
                      <td className="text-center">
                        <span id={"mrow" + val.id}>
                          {val.monto} ({val.proporcion}x1)
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </thead>
            </tbody>
          </table>
          <hr />
          <table width="100%" className="ticket pr-2">
            <thead></thead>
            <tbody>
              <tr>
                <td align="right">
                  <strong>
                    <span>Total a Pagar: </span>
                  </strong>
                  <strong>
                    <span className="text-green-700">{totalgeneral} (Bs)</span>
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
          <div align="right" className="pr-2">
            {" "}
            <button
              type="button"
              id="procesarticket"
              onClick={verificarlimites}
              class="text-white bg-[#ce3a2a] hover:bg-[#ce3a2a]/90 focus:ring-4 focus:outline-none focus:ring-[#ce3a2a]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#ce3a2a]/55 me-2 mb-2"
            >
              <svg
                class="w-6 h-6 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 3a2 2 0 0 0-2 2v3h12V5a2 2 0 0 0-2-2H8Zm-3 7a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h1v-4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v4h1a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2H5Zm4 11a1 1 0 0 1-1-1v-4h8v4a1 1 0 0 1-1 1H9Z"
                  clip-rule="evenodd"
                />
              </svg>
              Procesar Ticket (+)
            </button>
          </div>
        </div>
      </div>
    </div>
    {modal2 ? (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-lg">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
            {/*body*/}
            <div className="h-12 bg-black rounded-t-lg place-content-center flex flex-grow text-white text-md font-semibold">
              <span className="mt-2 pl-2">INFORMACIÓN DE IMPRESIÓN</span>
            </div>
            <div className="relative p-1 flex-auto">
              <div className="p-1 md:p-1">
                <div className="flex flex-row flex-wrap">
                  <div className="flex flex-row text-blue-950 text-md font-bold">
                  <div className="modal-body">

                    {revisado == false ? (<div className="flex flex-row place-content-between p-6">
                      <img src={imgrevision} width="70px" alt="" />
                     
                      <div className=" justify-center items-center text-center mt-6 ml-4">REVISANDO DATOS...</div>
                    </div>) : (<div className="flex flex-row place-content-between p-6">
                      <img src={imgimpresora} width="70px" alt="" />
                     
                      <div className=" justify-center items-center text-center mt-6 ml-4">IMPRIMIENDO TICKET...</div>
                    </div>)}
                    
                  
                                        </div>
                  </div>
                </div>

        

              
              </div>
            </div>
            <div className="h-14 bg-black rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
              <button
                type="button"
                onClick={() => {
                  setModal2(false);
                  
                }}
                data-modal-hide="popup-modal"
                class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
              >
                <svg
                  class="w-6 h-6 text-gray-800 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  ) : null}
    </>
  );
};

export default Ticket;
