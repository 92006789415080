import React, { useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment";

const Loterias = ({ tipo, activarchequeo, addmensaje2, diasemana }) => {
  const [listal, setListal] = useState([]);
  const [animales75, setAnimales75] = useState([
    "0",
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
    "60",
    "61",
    "62",
    "63",
    "64",
    "65",
    "66",
    "67",
    "68",
    "69",
    "70",
    "71",
    "72",
    "73",
    "74",
    "75",
  ]);
  const [animales36, setAnimales36] = useState([
    "0",
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
  ]);

  var nuevotipo = "";
  var datali = new Object();

  var horas;

  const getloterias = () => {
    console.log("Dia de la semana" + diasemana);
    Axios.get(
      `${process.env.REACT_APP_API}/loterias/${tipo}/${diasemana}`
    ).then((response) => {
      datali = response.data;

      setListal(response.data);

      horas = response.data;

      chequearhora();
    });
  };

  const obteneranimal = (codigo) => {
    var icono = "";
    if (codigo == "0") {
      icono = "Delfin";
    }
    if (codigo == "00") {
      icono = "Ballena";
    }
    if (codigo == "01") {
      icono = "Camero";
    }
    if (codigo == "02") {
      icono = "Toro";
    }
    if (codigo == "03") {
      icono = "Ciempies";
    }
    if (codigo == "04") {
      icono = "Alacran";
    }
    if (codigo == "05") {
      icono = "Leon";
    }
    if (codigo == "06") {
      icono = "Rana";
    }
    if (codigo == "07") {
      icono = "Perico";
    }
    if (codigo == "08") {
      icono = "Raton";
    }
    if (codigo == "09") {
      icono = "Aguila";
    }
    if (codigo == "10") {
      icono = "Tigre";
    }
    if (codigo == "11") {
      icono = "Gato";
    }
    if (codigo == "12") {
      icono = "Caballo";
    }
    if (codigo == "13") {
      icono = "Mono";
    }
    if (codigo == "14") {
      icono = "Paloma";
    }
    if (codigo == "15") {
      icono = "Zorro";
    }
    if (codigo == "16") {
      icono = "Oso";
    }
    if (codigo == "17") {
      icono = "Pavo";
    }
    if (codigo == "18") {
      icono = "Burro";
    }
    if (codigo == "19") {
      icono = "Chivo";
    }
    if (codigo == "20") {
      icono = "Cochino";
    }

    if (codigo == "21") {
      icono = "Gallo";
    }

    if (codigo == "22") {
      icono = "Camello";
    }

    if (codigo == "23") {
      icono = "Zebra";
    }

    if (codigo == "24") {
      icono = "Iguana";
    }

    if (codigo == "25") {
      icono = "Gallina";
    }

    if (codigo == "26") {
      icono = "Vaca";
    }

    if (codigo == "27") {
      icono = "Perro";
    }

    if (codigo == "28") {
      icono = "Zamuro";
    }

    if (codigo == "29") {
      icono = "Elefante";
    }

    if (codigo == "30") {
      icono = "Caiman";
    }

    if (codigo == "31") {
      icono = "Lapa";
    }

    if (codigo == "32") {
      icono = "Ardilla";
    }

    if (codigo == "33") {
      icono = "Pescado";
    }

    if (codigo == "34") {
      icono = "Venado";
    }

    if (codigo == "35") {
      icono = "Jirafa";
    }

    if (codigo == "36") {
      icono = "Culebra";
    }

    if (codigo == "37") {
      icono = "Tortuga";
    }
    if (codigo == "38") {
      icono = "Bufalo";
    }
    if (codigo == "39") {
      icono = "Lechuza";
    }
    if (codigo == "40") {
      icono = "Avispa";
    }
    if (codigo == "41") {
      icono = "Canguro";
    }
    if (codigo == "42") {
      icono = "Tucan";
    }
    if (codigo == "43") {
      icono = "Mariposa";
    }
    if (codigo == "44") {
      icono = "Chiguire";
    }
    if (codigo == "45") {
      icono = "Garza";
    }
    if (codigo == "46") {
      icono = "Puma";
    }
    if (codigo == "47") {
      icono = "Pavo Real";
    }
    if (codigo == "48") {
      icono = "Puercoespin";
    }
    if (codigo == "49") {
      icono = "Pereza";
    }
    if (codigo == "50") {
      icono = "Canario";
    }
    if (codigo == "51") {
      icono = "Pelicano";
    }
    if (codigo == "52") {
      icono = "Pulpo";
    }
    if (codigo == "53") {
      icono = "Caracol";
    }
    if (codigo == "54") {
      icono = "Grillo";
    }
    if (codigo == "55") {
      icono = "Oso Hormiguero";
    }
    if (codigo == "56") {
      icono = "Tiburon";
    }
    if (codigo == "57") {
      icono = "Pato";
    }
    if (codigo == "58") {
      icono = "Hormiga";
    }
    if (codigo == "59") {
      icono = "Pantera";
    }
    if (codigo == "60") {
      icono = "Camaleon";
    }
    if (codigo == "61") {
      icono = "Panda";
    }
    if (codigo == "62") {
      icono = "Cachicamo";
    }
    if (codigo == "63") {
      icono = "Cangrejo";
    }
    if (codigo == "64") {
      icono = "Gavilan";
    }
    if (codigo == "65") {
      icono = "Araña";
    }
    if (codigo == "66") {
      icono = "Lobo";
    }
    if (codigo == "67") {
      icono = "Avestruz";
    }
    if (codigo == "68") {
      icono = "Jaguar";
    }
    if (codigo == "69") {
      icono = "Conejo";
    }
    if (codigo == "70") {
      icono = "Bisonte";
    }
    if (codigo == "71") {
      icono = "Guacamaya";
    }
    if (codigo == "72") {
      icono = "Gorila";
    }
    if (codigo == "73") {
      icono = "Hipopotamo";
    }
    if (codigo == "74") {
      icono = "Turpial";
    }
    if (codigo == "75") {
      icono = "Doble";
    }
    return icono;
  };

  const obtenerminutos = (horapasada) => {
    //TOMAR HORA ACTUAL
    var tomarfecha = "";
    tomarfecha = "" + moment.utc().format();
    tomarfecha = tomarfecha.replace("Z", "");
    var date3 = new Date(tomarfecha);
    date3.setHours(date3.getHours() - 4);
    //FIN DE HORA ACTUAL RESULT EN date3

    var dia;
    console.log(date3.getDate());

    if (parseInt(date3.getDate()) > 0 && parseInt(date3.getDate()) < 10) {
      dia = "0" + date3.getDate();
    } else {
      dia = date3.getDate();
    }

    //TOMA LA FECHA EN FORMATO YYYY-MM-DD
    var mes;
    if (date3.getMonth() + 1 > 9) {
      var date4 =
        date3.getFullYear() + "-" + (date3.getMonth() + 1) + "-" + dia;
    } else {
      mes = "0" + (date3.getMonth() + 1);
      var date4 = date3.getFullYear() + "-" + mes + "-" + dia;
    }
    //LA FECHA EN FORMATO YYYY-MM-DD GUARDADA EN LA VARIABLE data4

    //meter la hora en la segunda fecha
    console.log("fecha nueva: " + date4);
    var hora = horapasada;
    var date5 = new Date(date4 + "T" + hora);
    //CALCULANDO LOS MINUTOS QUE FALTAN
    var c = (date5 - date3) / 1000 / 60;

    //FIN DE CALCULO
    return Math.trunc(c);
  };

  function convertira12horas(time) {
    // Separar la hora, minutos y segundos
    const [hours, minutes, seconds] = time.split(':').map(Number);

    // Determinar si es AM o PM
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convertir a formato de 12 horas
    const adjustedHours = hours % 12 || 12; // Si hours es 0, se convierte a 12

    // Formatear la cadena de tiempo en 12 horas
    return `${adjustedHours}:${String(minutes).padStart(2, '0')} ${period}`;
}

  const chequearhora = () => {
    for (let i = 0; i < horas.length; i++) {
      if (obtenerminutos(horas[i].hora) < 5) {
        horas[i].estatus = 0;
        console.log(obtenerminutos(horas[i].hora));
      } else {
        console.log("DISPONIBLE CHEQUEO 1: " + obtenerminutos(horas[i].hora));
      }
    }
    console.log("VALOR DE HORAS");
    console.log(horas);
    addmensaje2(horas);
  };

  const revisarhoras = () => {
    console.log(listal);
    addmensaje2(listal);
  };

  useEffect(() => {
    getloterias();
  }, [tipo, diasemana]);

  useEffect(() => {
    revisarhoras();
  }, [activarchequeo]);



  
  return (
    <div>
      <input type="hidden" id="horasd" value={horas} />
      <div className="flex flex-row">
        <div class="relative pt-2 w-full shadow-md sm:rounded-lg flex flex-col flex-wrap">
          <div
            id="fondolote"
            className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm"
          >
            LOTERIAS DISPONIBLES ({tipo})
          </div>
          <div className="">
            <table class="w-[100%] table1 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <tbody>
                {listal.map((val, key) => {
                  if (val.estatus == 1) {
                    return (
                      <tr key={val.id} className="text-left">
                        <td className="pt-1 pl-1 flex flex-row">
                          {/* <input
                          type="checkbox"
                          id={"loteria" + val.id}
                          value={val.name}
                          name={"loteria" + val.id}
                        /> */}
                          &nbsp;
                          <label
                            htmlFor={"loteria" + val.id}
                            className="text-blue-950  text-md mt-2 flex flex-row"
                          >
                            <input
                              id={"loteria" + val.id}
                              type="checkbox"
                              name={"loteria" + val.id}
                              value={val.name}
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            ></input>
                            &nbsp;&nbsp;&nbsp;
                            <span
                              id={"loter" + val.id}
                              className="text-blue-950 font-semibold"
                            >
                              {" "}
                              {val.name} {convertira12horas(val.hora)}
                            </span>
                            &nbsp;
                            <span id={"iconw" + val.id}></span>
                            &nbsp;&nbsp;&nbsp;
                          </label>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div class="relative pt-2 w-full shadow-md sm:rounded-lg flex flex-col flex-wrap">
          <div
            id="fondolote"
            className="pl-2 font-semibold text-blue-950 bg-slate-400 text-center w-full text-sm"
          >
            ANIMALITOS
          </div>
          <div className="">
            <table class="w-[100%] table1 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <tbody>
                {animales75.map((val, key) => {
                  return (
                    <tr>
                      <td className="p-1 font-semibold text-blue-950">
                        {val}-{obteneranimal(val)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loterias;
