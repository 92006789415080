import React from "react";
import Axios from "axios";
import { useState, useEffect } from "react";
import Menu from "./menu.jsx";
import moment from "moment";
import Loterias from "./loterias.jsx";
import Formapuesta from "./formapuesta.jsx";
import Listausers from "../componentes/listausers.jsx";
import Formuderecho from "../componentes/formuderecho.jsx";
import Formuimpresion from "../componentes/formuimpresion.jsx";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

var date = new Date();

var dia = new Intl.DateTimeFormat("en-US", {
  dateStyle: "full",
  timeStyle: "long",
  timeZone: "America/New_York",
}).format(date);

console.log(date);

const fecha1 = "2017-09-06T20:00:51";
const fecha2 = "2017-12-06T20:00:00";

var a = new Date(fecha1);
var b = new Date(fecha2);

console.log(+a > +b); //false
console.log(+a < +b); //false
console.log(+a == +b); //true

var a1 = new Date("2017-12-01T03:29:50");
var b1 = new Date("2017-12-06T03:11:00");
//La diferencia se da en milisegundos así que debes dividir entre 1000
var c = (a1 - b1) / 1000 / 60;
console.log(Math.trunc(c)); // resultado 5;


//CREAR LA FECHA
const timestamp = Date.now();
var dateDB = new Date(timestamp);

var monthArrayNames = [];
monthArrayNames[0] = "01";
monthArrayNames[1] = "02";
monthArrayNames[2] = "03";
monthArrayNames[3] = "04";
monthArrayNames[4] = "05";
monthArrayNames[5] = "06";
monthArrayNames[6] = "07";
monthArrayNames[7] = "08";
monthArrayNames[8] = "09";
monthArrayNames[9] = "10";
monthArrayNames[10] = "11";
monthArrayNames[11] = "12";

var day = dateDB.getDate();
if (day === 1) {
  day = "01";
}
if (day === 2) {
  day = "02";
}
if (day === 3) {
  day = "03";
}
if (day === 4) {
  day = "04";
}
if (day === 5) {
  day = "05";
}
if (day === 6) {
  day = "06";
}
if (day === 7) {
  day = "07";
}
if (day === 8) {
  day = "08";
}
if (day === 9) {
  day = "09";
}

var month = monthArrayNames[dateDB.getMonth()];
var year = dateDB.getFullYear();
var timestampobj = `${year}-${month}-${day}`;

const Imprimir = (props) => {
    const [formutipo, setFormutipo] = useState("nuevo");
    const [diasemana, setDiasemana] = useState(0);
    const [modal, setModal] = useState(false);
    const [impresoraactual, setImpresoraactual] = useState("Ninguna")

    const tomardia = (mensa) => {};

    const msjsave = (mensajesave, tipodemensaje) => {
    if (tipodemensaje == 'save') {
    toast.success(mensajesave, {});
    }
    if (tipodemensaje == 'error') {
      toast.error(mensajesave, {});
    }
    if (tipodemensaje == 'warning') {
    toast.warning(mensajesave, {});
     }
    if (tipodemensaje == 'info') {
    toast.info(mensajesave, {});
    }
    };

    const guardarimpresora = () => {
      const variableName = 'myimpresora';

      const  impresora = document.getElementById("listaDeImpresoras2").value
      

if (localStorage.getItem(variableName) === null) {
    localStorage.setItem(variableName, impresora);
    console.log(`La variable '${variableName}' no estaba definida, se ha creado con el valor: '${impresora}'`);
} else {
    const valorExistente = localStorage.getItem(variableName);
    localStorage.setItem(variableName, impresora);
    console.log(`La variable '${variableName}' ya está definida con el valor: ${impresora}`);
}
actimpresora();
msjsave("La Impresora ha sido Actualizada con Exito","save");

    }
  
    const imprimirmas = () => {
      abrirmodal();
    };

    const tomadia = (mensaje) => {
        setDiasemana(mensaje);
        console.log(mensaje);
      };
  
    const imprimirejemplo1 = () => {
      document.getElementById("imprimiresto").innerHTML =
        document.getElementById("ejemploi1").innerHTML;
      abrirmodal();
    };
  
    const imprimirejemplo2 = () => {
      document.getElementById("imprimiresto").innerHTML =
        document.getElementById("ejemploi2").innerHTML;
      abrirmodal();
    };
  
    const imprimirejemplo3 = () => {
      document.getElementById("imprimiresto").innerHTML =
        document.getElementById("ejemploi3").innerHTML;
      abrirmodal();
    };
  
    const imprimirejemplo4 = () => {
      document.getElementById("imprimiresto").innerHTML =
        document.getElementById("ejemploi4").innerHTML;
      abrirmodal();
    };
  
    const abrirmodal = () => {
    
        var botonImprimir = document.getElementById("btnImprimir");

            botonImprimir.click();

    };
  
    const seleccionarimpresora = () => {
      let select = document.getElementById("listaDeImpresoras");
      select.value = document.getElementById("listaDeImpresoras2").value;
    };
    const cargarimpresoras = () => {
      document.getElementById("cargarimpresoras").innerHTML = '<svg class="w-[18px] h-[18px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M16.444 18H19a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h2.556M17 11V5a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v6h10ZM7 15h10v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-4Z"/></svg> Cargando...';
      document.getElementById("listaDeImpresoras2").length =
        document.getElementById("listaDeImpresoras").length;
        //SIMULA UNA IMPRESORA
        document.getElementById("listaDeImpresoras2").length = 2;
        document.getElementById("listaDeImpresoras2").options[0].value = "W311";
        document.getElementById("listaDeImpresoras2").options[0].text = "W311";
        document.getElementById("listaDeImpresoras2").options[1].value = "W312";
        document.getElementById("listaDeImpresoras2").options[1].text = "W312";
        //FIN SIMULACION
      for (
        let a = 0;
        a < document.getElementById("listaDeImpresoras").length;
        a++
      ) {
        console.log(
          document.getElementById("listaDeImpresoras").options[a].value
        );
        console.log(document.getElementById("listaDeImpresoras").options[a].text);
        document.getElementById("listaDeImpresoras2").options[a].value =
          document.getElementById("listaDeImpresoras").options[a].value;
        document.getElementById("listaDeImpresoras2").options[a].text =
          document.getElementById("listaDeImpresoras").options[a].text;
      }
  
      setTimeout(() => {
        document.getElementById("cargarimpresoras").innerHTML = '<svg class="w-[18px] h-[18px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M16.444 18H19a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h2.556M17 11V5a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v6h10ZM7 15h10v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-4Z"/></svg> Cargar Impresoras';
      
      }, 2000);
    };

    const actimpresora = ()  => {
      if (localStorage.getItem("myimpresora") === null) {
      } else {
          const valorExistente = localStorage.getItem("myimpresora");
   
          setImpresoraactual(valorExistente);
      }
    }

  
    useEffect(() => {
      actimpresora()
    }, []);
  
  
  return (
    <>
      <Menu tomadia={tomadia} />
      <div class="grid grid-cols-2 gap-2">

    <div>
    <span className="text-xl pt-4 pl-4 font-bold text-blue-950 flex flex-col">
           Configuracion de Impresoras
          </span>
          

              <div className="ml-4">
                <div className="flex flex-col">
                <span class="horastyle mt-6 text-blue-950 font-medium italic">
                                        Seleccione una Impresora:&nbsp;
                                      </span>
                                      <div className="flex flex-row">
                                      <select
                                        class="bg-slate-400 p-1 w-[200px] h-8 rounded-md"
                                        id="listaDeImpresoras2"
                                        name="listaDeImpresoras2"
                                        onChange={seleccionarimpresora}
                                      >
                                        <option value="0">&nbsp;Ninguna</option>
                                      </select>
                                      <button
          type="button"
          onClick={guardarimpresora}
          id="impresorapredefinida"
          class="text-white bg-[#181f74] ml-2 h-8 hover:bg-[#2630b6]/90 focus:ring-4 focus:outline-none focus:ring-[#2630b6]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#2630b6]/55 me-2 mb-2"
        >
          <svg class="w-[18px] h-[18px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
</svg>
 Impresora Predefinida
        </button>
                                      </div>
                                      
                                      <br />
                                    <div className="text-blue-800 font-bold">Tu Impresora Actual es: <span className="text-green-600">{impresoraactual}</span></div>
                </div>


                <input
                            type="text"
                            id="mensajeimprimir"
                            name="mensajeimprimir"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Escriba un Texto para probar su impresora"
                            defaultValue=""
                          />
              

<button
          type="button"
          onClick={cargarimpresoras}
          id="cargarimpresoras"
          class="text-white mt-2 bg-[#181f74] hover:bg-[#2630b6]/90 focus:ring-4 focus:outline-none focus:ring-[#2630b6]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#2630b6]/55 me-2 mb-2"
        >
          <svg class="w-[18px] h-[18px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M16.444 18H19a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h2.556M17 11V5a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v6h10ZM7 15h10v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-4Z"/>
</svg> Cargar Impresoras
        </button>

        <button
          type="button"
          id="btnImprimir2"
                                        onClick={imprimirmas}
          class="text-white mt-2 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
        >
          <svg class="w-[18px] h-[18px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M16.444 18H19a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h2.556M17 11V5a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v6h10ZM7 15h10v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-4Z"/>
</svg>

          Imprimir
        </button>

                                     

                                      <hr />
                                      <div id="ejemploi1">
                                        Hola mundo\nlotoactivo.com
                                      </div>

                                      <button
          type="button"
          id="imprimirejemplo1"
          onClick={imprimirejemplo1}
          class="text-white mt-2 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
        >
          <svg class="w-[18px] h-[18px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M16.444 18H19a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h2.556M17 11V5a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v6h10ZM7 15h10v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-4Z"/>
</svg>

          Imprimir Ejemplo1
        </button>
                                      
                                      <hr />
                                      <div id="ejemploi2">
                                        ABCDEFGHIJKLMNOPQRSTUVWXYZ-ABCDEFGHIJKLMNOPQRSTUVWXYZ
                                      </div>
                                      <button
          type="button"
          id="imprimirejemplo2"
          onClick={imprimirejemplo2}
          class="text-white mt-2 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
        >
          <svg class="w-[18px] h-[18px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M16.444 18H19a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h2.556M17 11V5a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v6h10ZM7 15h10v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-4Z"/>
</svg>

          Imprimir Ejemplo2
        </button>
                                      <hr />
                                      <div id="ejemploi3">
                                        abcdefghijklmnopqrstuvwxyz-abcdefghijklmnopqrstuvwxyz
                                      </div>
                                      <button
          type="button"
          id="imprimirejemplo3"
          onClick={imprimirejemplo3}
          class="text-white mt-2 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
        >
          <svg class="w-[18px] h-[18px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M16.444 18H19a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h2.556M17 11V5a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v6h10ZM7 15h10v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-4Z"/>
</svg>

          Imprimir Ejemplo3
        </button>
                                      <hr />
                                      <div id="ejemploi4">
                                        1234567890-1234567890-1234567890-1234567890
                                      </div>
                                      <button
          type="button"
          id="imprimirejemplo4"
          onClick={imprimirejemplo4}
          class="text-white mt-2 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
        >
          <svg class="w-[18px] h-[18px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M16.444 18H19a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h2.556M17 11V5a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v6h10ZM7 15h10v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-4Z"/>
</svg>

          Imprimir Ejemplo4
        </button>
                                      <hr />
                                      <div id="imprimiresto"></div>
              </div>
    </div>
      

      <div className="flex flex-col place-content-start pl-4 mt-4">

      <span className="text-xl pt-1 pl-0 font-bold text-blue-950 flex flex-col">
           Descarga el Plugin para Impresora Termica
          </span>

          <Formuimpresion formutipo={formutipo} />
           
        
          </div>
      
   
        
        
      </div>

      <ToastContainer />
     
        {modal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-12 bg-black rounded-t-lg place-content-center flex flex-grow text-white text-md font-semibold">
                  <span className="mt-2 pl-2">Registrar Usuario</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1">
                    <div className="flex flex-row flex-wrap">
                      <div className="flex flex-row text-blue-950 text-md font-bold">
                        <span className="text-sm mt-1">Usuario:</span>
                        <input
                          type="text"
                          id="serialticket"
                          
                          class="bg-gray-50 ml-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[50%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder=""
                        />

                        <button
                          type="button"
                          id="opcionlimpiar1"
                     
                          class="text-white ml-2 pl-2 w-18 h-8 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
                        >
                          Limpiar
                        </button>
                      </div>
                    </div>

                    <div class="relative pt-4 overflow-x-auto shadow-md sm:rounded-lg flex flex-row flex-wrap">
                      <div className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm">
                        VENTAS
                      </div>
                      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" class="px-6 py-1">
                              #
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Caja
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Hora
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Estatus
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          Contenido
                        </tbody>
                      </table>
                      <div className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm">
                        JUGADAS ----- #Ticket:
                      </div>
                      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" class="px-6 py-1">
                              Loteria
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Tipo
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Numero
                            </th>
                            <th scope="col" class="px-6 py-1">
                              Monto
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          contenido
                        </tbody>
                      </table>
                    </div>

                    <div className="flex flex-row place-content-end mt-2 mr-[-10px]">
                      

                      <button
                        type="button"
                        id="opcionb12"
                        class="text-white ml-2 pl-2 w-18 h-8 bg-[#cb2121] hover:bg-[#cb2121]/90 focus:ring-4 focus:outline-none focus:ring-[#cb2121]/50 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-[#cb2121]/55 me-2 mb-2"
                      >
                        <svg
                          class="w-[18px] h-[18px] text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-width="2"
                            d="m6 6 12 12m3-6a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                        Anular Este Ticket
                      </button>
                    </div>
                  </div>
                </div>
                <div className="h-14 bg-black rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <button
                    type="button"
                    onClick={() => {
                      setModal(false);
                      
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Imprimir;