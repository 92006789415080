import React, { useEffect, useState } from "react";
import moment from "moment";

const Hora = ({ tomardia }) => {
  const [horaactual, setHoraActual] = useState("----------");

  const tomardia2 = () => {
    var tomarfecha = "";
    tomarfecha = "" + moment.utc().format();
    tomarfecha = tomarfecha.replace("Z", "");
    var date3 = new Date(tomarfecha);
    date3.setHours(date3.getHours() - 4);
    //FIN DE HORA ACTUAL RESULT EN date3

    tomardia(date3.getDay());
  };

  const obtenerhora = () => {
    setHoraActual("-----------");
    const shortTime = new Intl.DateTimeFormat("es-ES", {
      timeStyle: "long",
      dateStyle: "long",
      timeZone: "America/Caracas",
    });
    tomardia2();
    setHoraActual(shortTime.format(Date.now())); // "1:31 PM"
  };

  useEffect(() => {
    setTimeout(() => {
      obtenerhora();
    }, 1000);
  }, [horaactual]);

  return <div>{horaactual}</div>;
};

export default Hora;
