import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { useState, useEffect } from "react";
import Axios from "axios";

const Formuresults = ({
  addmensaje,
  formutipo,
  valorid,
  parte1,
  parte2,
  fecha,
  addtipo,
  resultados,
  activarchequeo,
}) => {
  const [listalo2, setListalo2] = useState([]);
  const [mostrarhorario, setMostrarhorario] = useState([]);
  const [datoid, setDatoid] = useState([]);

  const [nombreloteria, setNombreloteria] = useState("");
  const [tipoloteria, setTipoloteria] = useState("");
  const [proporcion, setProporcion] = useState("");

  const [p1, setP1] = useState([]);
  const [p2, setP2] = useState([]);

  var contador = 0;

  const getloterias2 = () => {
    Axios.get(`${process.env.REACT_APP_API}/listaloterias/`).then((response) => {
      setListalo2(response.data);
      console.log(response.data);
    });
  };

  const getnombres = (valorid) => {
    var lunes = false;
    var martes = false;
    var miercoles = false;
    var jueves = false;
    var viernes = false;
    var sabado = false;
    var domingo = false;
    for (let i = 0; i < listalo2.length; i++) {
      if (listalo2[i].id == valorid) {
        console.log("SE CUMPLIO");
        console.log(listalo2[i].name);
        setNombreloteria(listalo2[i].name);
        setTipoloteria(listalo2[i].tipo);
        setProporcion(listalo2[i].proporcion);
        if (listalo2[i].hlunes == 1) {
          lunes = true;
        }
        if (listalo2[i].hmartes == 1) {
          martes = true;
        }
        if (listalo2[i].hmiercoles == 1) {
          miercoles = true;
        }
        if (listalo2[i].hjueves == 1) {
          jueves = true;
        }
        if (listalo2[i].hviernes == 1) {
          viernes = true;
        }
        if (listalo2[i].hsabado == 1) {
          sabado = true;
        }
        if (listalo2[i].hdomingo == 1) {
          domingo = true;
        }
        setDatoid([
          {
            name: listalo2[i].name,
            tipo: listalo2[i].tipo,
            proporcion: listalo2[i].proporcion,
            hlunes: lunes,
            hmartes: martes,
            hmiercoles: miercoles,
            hjueves: jueves,
            hviernes: viernes,
            hsabado: sabado,
            hdomingo: domingo,
          },
        ]);
        console.log(datoid);
      }
    }
  };

  const enviarmensaje = (mensaje) => {
    addmensaje(mensaje);
  };

  const msjsave = (mensajesave, tipodemensaje) => {
    if (tipodemensaje == "save") {
      toast.success(mensajesave, {});
    }
    if (tipodemensaje == "error") {
      toast.error(mensajesave, {});
    }
    if (tipodemensaje == "warning") {
      toast.warning(mensajesave, {});
    }
    if (tipodemensaje == "info") {
      toast.info(mensajesave, {});
    }
  };

  const limpiarcampos = () => {
    document.getElementById("campotipo").value = "";
    document.getElementById("campoloteria").value = "";
    document.getElementById("campoproporcion").value = "";
  };
  const guardarloteria = () => {
    var wname = document.getElementById("campoloteria").value;
    var wtipo = document.getElementById("campotipo").value;
    var wproporcion = document.getElementById("campoproporcion").value;
    var wrango = 0;

    if (validarexistencia(wname) == true) {
      msjsave("El Nombre de la Loteria ya existe, intente con otro", "error");
    } else {
      if (wtipo == "Animalito") {
        wrango = 75;
      }
      if (wtipo == "Terminalito") {
        wrango = 100;
      }

      if (wtipo == "Triple") {
        wrango = 1000;
      }

      const data = {
        name: wname,
        tipo: wtipo,
        proporcion: wproporcion,
        rangonum: wrango,
      };
      //enviar datos
      fetch(`${process.env.REACT_APP_API}/guardarloteria`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.message == "1062") {
            msjsave(
              "El Nombre ya se encuentra registrado pruebe con otro",
              "error"
            );
          } else {
            msjsave("Loteria Registrada con Exito", "save");
            getloterias2();
          }

          if (result) {
            console.log("SUS DATOS HAN SIDO GUARDADOS");

            enviarmensaje("nuevo");
            limpiarcampos();
          } else {
            console.log("ERROR DE CONEXION");
          }
        })
        .catch((error) => {
          console.log(error);
        });
      //fin de envio
    }
  };

  const modificarhorario = () => {
    for (let i = 0; i < 12; i++) {
      if (document.getElementById("horario" + i).checked == true) {
        var estatus = 1;
      } else {
        var estatus = 0;
      }

      Axios.post(`${process.env.REACT_APP_API}/modificarhorario/`, {
        id: p1[i].id,
        hora: p1[i].hora,
        estatus: estatus,
      }).then(() => {});
    }

    for (let i = 0; i < 12; i++) {
      if (document.getElementById("horario" + (i + 12)).checked == true) {
        var estatus = 1;
      } else {
        var estatus = 0;
      }
      Axios.post(`${process.env.REACT_APP_API}/modificarhorario`, {
        id: p2[i].id,
        hora: p2[i].hora,
        estatus: estatus,
      }).then(() => {});
    }

    msjsave("Datos Modificados con exito", "save");
    setTimeout(() => {
      enviarmensaje("n");
    }, 1000);
  };

  const modificarloteria = () => {
    var errorsi = 0;
    if (nombreloteria == document.getElementById("campoloteria").value) {
      Axios.post(`${process.env.REACT_APP_API}/modificarloteria/`, {
        id: valorid,
        name: document.getElementById("campoloteria").value,
        tipo: document.getElementById("campotipo").value,
        proporcion: document.getElementById("campoproporcion").value,
      }).then(() => {});
    } else {
      for (let i = 0; i < listalo2.length; i++) {
        if (listalo2[i].name == document.getElementById("campoloteria").value) {
          errorsi = 1;
          msjsave(
            "El nuevo nombre que intenta ingresar ya existe, intente con otro",
            "error"
          );
        }
      }
      if (errorsi == 0) {
        Axios.post(`${process.env.REACT_APP_API}/modificarloteria/`, {
          id: valorid,
          name: document.getElementById("campoloteria").value,
          tipo: document.getElementById("campotipo").value,
          proporcion: document.getElementById("campoproporcion").value,
        }).then(() => {});
      }
    }

    if (errorsi == 0) {
      msjsave("Datos Modificados con exito", "save");
      setTimeout(() => {
        enviarmensaje("n");
      }, 2000);
    }
  };

  const modificarsemana = () => {
    var hlunes, hmartes, hmiercoles, hjueves, hviernes, hsabado, hdomingo;
    if (document.getElementById("hlunes").checked == true) {
      hlunes = 1;
    } else {
      hlunes = 0;
    }
    if (document.getElementById("hmartes").checked == true) {
      hmartes = 1;
    } else {
      hmartes = 0;
    }
    if (document.getElementById("hmiercoles").checked == true) {
      hmiercoles = 1;
    } else {
      hmiercoles = 0;
    }
    if (document.getElementById("hjueves").checked == true) {
      hjueves = 1;
    } else {
      hjueves = 0;
    }
    if (document.getElementById("hviernes").checked == true) {
      hviernes = 1;
    } else {
      hviernes = 0;
    }
    if (document.getElementById("hsabado").checked == true) {
      hsabado = 1;
    } else {
      hsabado = 0;
    }
    if (document.getElementById("hdomingo").checked == true) {
      hdomingo = 1;
    } else {
      hdomingo = 0;
    }
    Axios.post(`${import.meta.env.VITE_API}/modificarsemana/`, {
      id: valorid,
      hlunes: hlunes,
      hmartes: hmartes,
      hmiercoles: hmiercoles,
      hjueves: hjueves,
      hviernes: hviernes,
      hsabado: hsabado,
      hdomingo: hdomingo,
    }).then(() => {});

    msjsave("Dias de la Semana Configurados Exitosamente", "save");
    setTimeout(() => {
      enviarmensaje("n");
    }, 1000);
  };

  const validarexistencia = (wname) => {
    var datosl = listalo2;
    var resultado = false;
    for (let i = 0; i < datosl.length; i++) {
      if (datosl[i].name == wname) {
        resultado = true;
      }
    }
    return resultado;
  };

  const obtenerminutodif = (horapasada) => {
    var hora = horapasada;
    var date5 = new Date("2024-11-05T" + hora);
    //CALCULANDO LOS MINUTOS QUE FALTAN
    date5.setMinutes(parseInt(document.getElementById("minutos").value));

    var hora1 = "";
    var minuto1 = "";
    var segundo1 = "";
    if (date5.getHours() < 10) {
      hora1 = "0" + date5.getHours();
    } else {
      hora1 = date5.getHours();
    }
    if (date5.getMinutes() < 10) {
      minuto1 = "0" + date5.getMinutes();
    } else {
      minuto1 = date5.getMinutes();
    }
    if (date5.getSeconds() < 10) {
      segundo1 = "0" + date5.getSeconds();
    } else {
      segundo1 = date5.getSeconds();
    }
    return hora1 + ":" + minuto1 + ":" + segundo1;
    //FIN DE CALCULO
  };

  const cambiarminutos = () => {
    for (let i = 0; i < 12; i++) {
      obtenerminutodif(p1[i].hora);
    }
    for (let i = 0; i < 12; i++) {
      obtenerminutodif(p2[i].hora);
    }
    console.log(p1);
    console.log(p2);
    var dato1 = [
      {
        id1: 0,
        id: p1[0].id,
        idloteria: p1[0].idloteria,
        hora: obtenerminutodif(p1[0].hora),
        estatus: p1[0].estatus,
      },
      {
        id1: 1,
        id: p1[1].id,
        idloteria: p1[1].idloteria,
        hora: obtenerminutodif(p1[1].hora),
        estatus: p1[1].estatus,
      },
      {
        id1: 2,
        id: p1[2].id,
        idloteria: p1[2].idloteria,
        hora: obtenerminutodif(p1[2].hora),
        estatus: p1[2].estatus,
      },
      {
        id1: 3,
        id: p1[3].id,
        idloteria: p1[3].idloteria,
        hora: obtenerminutodif(p1[3].hora),
        estatus: p1[3].estatus,
      },
      {
        id1: 4,
        id: p1[4].id,
        idloteria: p1[4].idloteria,
        hora: obtenerminutodif(p1[4].hora),
        estatus: p1[4].estatus,
      },
      {
        id1: 5,
        id: p1[5].id,
        idloteria: p1[5].idloteria,
        hora: obtenerminutodif(p1[5].hora),
        estatus: p1[5].estatus,
      },
      {
        id1: 6,
        id: p1[6].id,
        idloteria: p1[6].idloteria,
        hora: obtenerminutodif(p1[6].hora),
        estatus: p1[6].estatus,
      },
      {
        id1: 7,
        id: p1[7].id,
        idloteria: p1[7].idloteria,
        hora: obtenerminutodif(p1[7].hora),
        estatus: p1[7].estatus,
      },
      {
        id1: 8,
        id: p1[8].id,
        idloteria: p1[8].idloteria,
        hora: obtenerminutodif(p1[8].hora),
        estatus: p1[8].estatus,
      },
      {
        id1: 9,
        id: p1[9].id,
        idloteria: p1[9].idloteria,
        hora: obtenerminutodif(p1[9].hora),
        estatus: p1[9].estatus,
      },
      {
        id1: 10,
        id: p1[10].id,
        idloteria: p1[10].idloteria,
        hora: obtenerminutodif(p1[10].hora),
        estatus: p1[10].estatus,
      },
      {
        id1: 11,
        id: p1[11].id,
        idloteria: p1[11].idloteria,
        hora: obtenerminutodif(p1[11].hora),
        estatus: p1[11].estatus,
      },
    ];

    var dato3 = [
      {
        id2: 12,
        id: p2[0].id,
        idloteria: p2[0].idloteria,
        hora: obtenerminutodif(p2[0].hora),
        estatus: p2[0].estatus,
      },
      {
        id2: 13,
        id: p2[1].id,
        idloteria: p2[1].idloteria,
        hora: obtenerminutodif(p2[1].hora),
        estatus: p2[1].estatus,
      },
      {
        id2: 14,
        id: p2[2].id,
        idloteria: p2[2].idloteria,
        hora: obtenerminutodif(p2[2].hora),
        estatus: p2[2].estatus,
      },
      {
        id2: 15,
        id: p2[3].id,
        idloteria: p2[3].idloteria,
        hora: obtenerminutodif(p2[3].hora),
        estatus: p2[3].estatus,
      },
      {
        id2: 16,
        id: p2[4].id,
        idloteria: p2[4].idloteria,
        hora: obtenerminutodif(p2[4].hora),
        estatus: p2[4].estatus,
      },
      {
        id2: 17,
        id: p2[5].id,
        idloteria: p2[5].idloteria,
        hora: obtenerminutodif(p2[5].hora),
        estatus: p2[5].estatus,
      },
      {
        id2: 18,
        id: p2[6].id,
        idloteria: p2[6].idloteria,
        hora: obtenerminutodif(p2[6].hora),
        estatus: p2[6].estatus,
      },
      {
        id2: 19,
        id: p2[7].id,
        idloteria: p2[7].idloteria,
        hora: obtenerminutodif(p2[7].hora),
        estatus: p2[7].estatus,
      },
      {
        id2: 20,
        id: p2[8].id,
        idloteria: p2[8].idloteria,
        hora: obtenerminutodif(p2[8].hora),
        estatus: p2[8].estatus,
      },
      {
        id2: 21,
        id: p2[9].id,
        idloteria: p2[9].idloteria,
        hora: obtenerminutodif(p2[9].hora),
        estatus: p2[9].estatus,
      },
      {
        id2: 22,
        id: p2[10].id,
        idloteria: p2[10].idloteria,
        hora: obtenerminutodif(p2[10].hora),
        estatus: p2[10].estatus,
      },
      {
        id2: 23,
        id: p2[11].id,
        idloteria: p2[11].idloteria,
        hora: obtenerminutodif(p2[11].hora),
        estatus: p2[11].estatus,
      },
    ];
    setP1(dato1);
    setP2(dato3);
  };

  const colocarresultado = (idh, fecha) => {
    var resu = "";
    console.log("RESULTADO IHORARIO:" + idh);
    for (let i = 0; i < resultados.length; i++) {
      if (resultados[i].idhorario == idh && resultados[i].fecha == fecha) {
        resu = resultados[i].resultado;
      }
    }
    return resu;
  };

  const modificarresultado = () => {
    for (let i = 0; i < 12; i++) {
      if (p1[i].estatus == 1) {
        var resultado = document.getElementById(
          "camporesult1" + p1[i].id1
        ).value;
        var idhorario = p1[i].id;
        Axios.post(`${process.env.REACT_APP_API}/modificarresultados/`, {
          wid: idhorario,
          wfecha: fecha,
          wresultado: resultado,
        }).then(() => {});
      }
    }

    for (let i = 0; i < 12; i++) {
      if (p2[i].estatus == 1) {
        var resultado = document.getElementById(
          "camporesult1" + p2[i].id2
        ).value;
        var idhorario = p2[i].id;
        Axios.post(`${process.env.REACT_APP_API}/modificarresultados/`, {
          wid: idhorario,
          wfecha: fecha,
          wresultado: resultado,
        }).then(() => {});
      }
    }

    msjsave("Datos Modificados con exito", "save");
    setTimeout(() => {
      addtipo("h");
    }, 1000);
  };

  useEffect(() => {
    getloterias2();

    getnombres(valorid);
    console.log(parte1);
    console.log(parte2);
    setP1(parte1);
    setP2(parte2);
  }, [formutipo, valorid, parte1, parte2]);

  useEffect(() => {
    setP1(parte1);
    setP2(parte2);
    getnombres(valorid);
    if (formutipo == "h") {
      setTimeout(() => {
        enviarmensaje("horario");
      }, 1000);
    }
    if (formutipo == "d") {
      getloterias2();

      getnombres(valorid);
      setTimeout(() => {
        enviarmensaje("editar");
      }, 1000);
    }
    if (formutipo == "n") {
      getloterias2();
      setTimeout(() => {
        enviarmensaje("nuevo");
      }, 1000);
    }
    if (formutipo == "s") {
      getloterias2();
      setTimeout(() => {
        enviarmensaje("semana");
      }, 1000);
    }
  }, [formutipo]);

  useEffect(() => {
    addtipo("h");
  }, [fecha]);

  if (formutipo == "nuevo" || valorid == 0) {
    console.log("LLEGO EL NUEVO");
    return (
      <div className="mt-[30px]">
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                  <h4 className="text-red-700 font-medium italic">
                      Resultados del
                      Dia
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                            <div
              id="fondolote"
              className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm"
            >
              SELECCIONE UNA LOTERIA....
            </div>
                              <input
                                type="hidden"
                                className="form-control"
                                id="campoloteriasn"
                                name="campoloteriasn"
                                maxlength="27"
                                value="SELECCIONE UNA LOTERIA...."
                                disabled="true"
                              />
                            </div>
                          </div>

                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  if (formutipo == "editar") {
    console.log("LLEGO EL EDITAR");
    return (
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-edit"></i> Actualizar Registro de
                      Loteria
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <label
                                for="cliente_dni"
                                className="bmd-label-floating"
                              >
                                Nombre de Loteria
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="campoloteriae"
                                name="campoloteriae"
                                maxLength="27"
                                defaultValue={nombreloteria}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label
                                for="cliente_nombre"
                                className="bmd-label-floating"
                              >
                                Tipo de Loteria
                              </label>
                              {datoid.map((val, key) => {
                                if (val.tipo === "ANIMALITOS") {
                                  return (
                                    <select
                                      className="form-control"
                                      id="campotipo"
                                      name="campotipo"
                                    >
                                      <option selected value="ANIMALITOS">
                                        ANIMALITOS
                                      </option>
                                      <option value="TERMINALITO">
                                        TERMINALITO
                                      </option>
                                      <option value="TRIPLE">TRIPLE</option>
                                    </select>
                                  );
                                }
                                if (val.tipo === "TERMINALITO") {
                                  return (
                                    <select
                                      className="form-control"
                                      id="campotipo"
                                      name="campotipo"
                                    >
                                      <option value="ANIMALITOS">
                                        ANIMALITOS
                                      </option>
                                      <option selected value="TERMINALITO">
                                        TERMINALITO
                                      </option>
                                      <option value="TRIPLE">TRIPLE</option>
                                    </select>
                                  );
                                }
                                if (val.tipo === "TRIPLE") {
                                  return (
                                    <select
                                      className="form-control"
                                      id="campotipo"
                                      name="campotipo"
                                    >
                                      <option value="ANIMALITOS">
                                        ANIMALITOS
                                      </option>
                                      <option value="TERMINALITO">
                                        TERMINALITO
                                      </option>
                                      <option selected value="TRIPLE">
                                        TRIPLE
                                      </option>
                                    </select>
                                  );
                                }
                              })}
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label
                                for="cliente_apellido"
                                className="bmd-label-floating"
                              >
                                Proporcion
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="campoproporcion"
                                id="campoproporcion"
                                maxLength="40"
                                defaultValue={proporcion}
                              />
                            </div>
                          </div>
                          <br></br>
                          <br></br>
                          <br></br>

                          <br></br>
                          <table align="center">
                            <tr>
                              <td>
                                <p className="text-center">
                                  &nbsp; &nbsp;
                                  <button
                                    type="submit"
                                    className="btn btn-raised btn-info btn-sm"
                                    onClick={modificarloteria}
                                  >
                                    <i className="far fa-save"></i> &nbsp;
                                    ACTUALIZAR DATOS
                                  </button>
                                </p>
                              </td>
                            </tr>
                          </table>
                          <ToastContainer />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  if (formutipo == "horario" && valorid != 0) {
    console.log("LLEGO EL HORARIO");
    return (
      <div className="mt-[30px]">
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4 className="text-red-700 font-medium italic">
                      Resultados del
                      Dia
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">

                            <div
              id="fondolote"
              className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm"
            >
              {`${nombreloteria} ==> ${tipoloteria} ==> ${fecha}`}
            </div>
                              <input
                                type="hidden"
                                className="form-control"
                                id="campoloteria43"
                                name="campoloteria43"
                                maxlength="27"
                                value={`${nombreloteria} ==> ${tipoloteria} ==> ${fecha}`}
                                disabled="true"
                              />
                            </div>
                          </div>
        <div className="grid grid-cols-2 gap-2">
                          <div className="col-12 col-md-6 mt-4">
                            <div className="form-group">
                              {p1.map((val, key) => {
                                if (val.estatus === 1) {
                                  return (
                                    <div className="p-1">
                                      <div class="">
                                        <span className="text-blue-900 font-bold">{val.hora}</span>
                                        <input
                                          type="text"
                                          className="form-field w-[25%] bg-slate-200 ml-4 text-center text-red-600 font-bold rounded-lg border-2 border-gray-600"
                                          id={`camporesult1${val.id1}`}
                                          name={`camporesult1${val.id1}`}
                                          maxLength="3"
                                          defaultValue={colocarresultado(
                                            val.id,
                                            fecha
                                          )}
                                        />
                                      </div>

                                      <span className=""></span>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                          <div className="col-12 col-md-6  mt-4">
                            <div className="form-group">
                              {p2.map((val, key) => {
                                if (val.estatus === 1) {
                                  return (
                                    <div className="p-1">
                                      <div class="form-group2">
                                        <span className="text-blue-900 font-bold">{val.hora}</span>
                                        <input
                                          type="text"
                                          className="form-field w-[25%] bg-slate-200 ml-4 text-center text-red-600 font-bold rounded-lg border-2 border-gray-600"
                                          id={`camporesult1${val.id2}`}
                                          name={`camporesult1${val.id2}`}
                                          maxLength="3"
                                          defaultValue={colocarresultado(
                                            val.id,
                                            fecha
                                          )}
                                        />
                                      </div>

                                      <span className=""></span>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                          </div>
                          <br></br>
               
                          <hr className="bg-gray-500 h-1"></hr>
                          <table align="center">
                            <tr>
                              <td>
                                <p className="text-center">
                                  &nbsp; &nbsp;
                                  <button
          type="button"
          onClick={modificarresultado}
          class="text-white mt-2 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
        >
          <svg class="w-[18px] h-[18px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
</svg>


          &nbsp; GUARDAR RESULTADOS
        </button>
  
                                </p>
                              </td>
                            </tr>
                          </table>
                          <ToastContainer />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  if (formutipo == "semana") {
    console.log("LLEGO EL dias");
    return (
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-calendar"></i> Configurar Dias de la
                      Semana
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="campoloteriase"
                                name="campoloteriase"
                                maxlength="27"
                                defaultValue={`${nombreloteria} ==> ${tipoloteria}`}
                                disabled="true"
                              />
                            </div>
                          </div>
                          {datoid.map((val, key) => {
                            return (
                              <div className="col-12 col-md-12">
                                <div className="form-group">
                                  <div>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        defaultChecked={val.hlunes}
                                        id="hlunes"
                                      />
                                      <span className="slider"></span>
                                    </label>{" "}
                                    <span className="horastyle">Lunes</span>
                                  </div>
                                  <div>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        id="hmartes"
                                        defaultChecked={val.hmartes}
                                      />
                                      <span className="slider"></span>
                                    </label>{" "}
                                    <span className="horastyle">Martes</span>
                                  </div>
                                  <div>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        defaultChecked={val.hmiercoles}
                                        id="hmiercoles"
                                      />
                                      <span className="slider"></span>
                                    </label>{" "}
                                    <span className="horastyle">Miercoles</span>
                                  </div>
                                  <div>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        defaultChecked={val.hjueves}
                                        id="hjueves"
                                      />
                                      <span className="slider"></span>
                                    </label>{" "}
                                    <span className="horastyle">Jueves</span>
                                  </div>
                                  <div>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        defaultChecked={val.hviernes}
                                        id="hviernes"
                                      />
                                      <span className="slider"></span>
                                    </label>{" "}
                                    <span className="horastyle">Viernes</span>
                                  </div>
                                  <div>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        defaultChecked={val.hsabado}
                                        id="hsabado"
                                      />
                                      <span className="slider"></span>
                                    </label>{" "}
                                    <span className="horastyle">Sabado</span>
                                  </div>
                                  <div>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        defaultChecked={val.hdomingo}
                                        id="hdomingo"
                                      />
                                      <span className="slider"></span>
                                    </label>{" "}
                                    <span className="horastyle">Domingo</span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                          <br></br>
                          <br></br>
                          <br></br>
                          <br></br>
                          <table align="center">
                            <tr>
                              <td>
                                <p className="text-center">
                                  &nbsp; &nbsp;
                                  <button
                                    type="submit"
                                    className="btn btn-raised btn-info btn-sm"
                                    onClick={modificarsemana}
                                  >
                                    <i className="far fa-save"></i> &nbsp;
                                    GUARDAR CAMBIOS
                                  </button>
                                </p>
                              </td>
                            </tr>
                          </table>
                          <ToastContainer />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

//   ES ESTA
  if (formutipo == "h") {
    console.log("LLEGO EL HORARIO2");
    return (
        <div className="flex flex-col text-2xl font-bold text-blue-900 place-content-center text-center mt-[300px]">
        CARGANDO FORMULARIO ....
      </div>
    );
  }

  if (formutipo == "d") {
    console.log("LLEGO EL EDITAR2");
    return (
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-clock"></i> Cargando Formulario
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="campoloteriad"
                                name="campoloteriad"
                                maxlength="27"
                                defaultValue="POR FAVOR ESPERE...."
                                disabled="true"
                              />
                            </div>
                          </div>

                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  if (formutipo == "n") {
    console.log("LLEGO EL EDITAR2");
    return (
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-clock"></i> Cargando Formulario
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="campoloteria"
                                name="campoloteria"
                                maxlength="27"
                                defaultValue="POR FAVOR ESPERE...."
                                disabled="true"
                              />
                            </div>
                          </div>

                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  if (formutipo == "s") {
    console.log("LLEGO EL EDITAR2");
    return (
      <div>
        <table width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div className="container-fluid2">
                  <div className="container-dentro2">
                    <h4>
                      <i className="fas fa-clock"></i> Cargando Formulario
                    </h4>
                  </div>
                </div>
                <div className="container-fluid2">
                  <div className="container-dentro">
                    <div className="form-group">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="campoloterias"
                                name="campoloterias"
                                maxlength="27"
                                defaultValue="POR FAVOR ESPERE...."
                                disabled="true"
                              />
                            </div>
                          </div>

                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
};

export default Formuresults;
