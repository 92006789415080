import React, { useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment";

const Listagenerador = ({
  myuser,
  activarchequeo,
  addvalor,
  addtipo,
  addmensaje,
}) => {
  const [usernivel, setUsernivel] = useState("");
  const enviarmensaje = (mensaje) => {
    addmensaje(mensaje);
    console.log(mensaje);
  };

  const chequeardatos = () => {
    const username = localStorage.getItem("usu");
    const data = {
      username: username,
    };
    fetch(`${process.env.REACT_APP_API}/datos`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        setUsernivel(result.message);

        if (result.message) {
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    chequeardatos();
  }, []);

  if (usernivel == "administrador") {
    return (
      <div>
        <table className="table table-dark table-sm">
          <thead>
            <tr className="text-center roboto-medium">
              <th colSpan="3" id="fondolote">
                CONSULTE SU REPORTE
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <td>
               

                <button
                            type="button"
                            onClick={() => {
                                enviarmensaje("l");
                              }}
                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-1.5 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                            <svg class="w-[18px] h-[18px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M8 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1h2a2 2 0 0 1 2 2v15a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h2Zm6 1h-4v2H9a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2h-1V4Zm-6 8a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm1 3a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Z" clip-rule="evenodd"/>
</svg>

                          </button>
              </td>
              <td>
                <span className="">Venta por Loteria</span>
              </td>
            </tr>

            <tr className="text-center">
              <td>
              <button
                            type="button"
                            
                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-1.5 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                            <svg class="w-[18px] h-[18px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M8 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1h2a2 2 0 0 1 2 2v15a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h2Zm6 1h-4v2H9a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2h-1V4Zm-6 8a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm1 3a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Z" clip-rule="evenodd"/>
</svg>

                          </button>
              </td>
              <td>
                <span className="">Reporte por Agencia</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  if (usernivel == "vendedor") {
    
    
    return (
      <div>
        <table className="table table-dark table-sm">
          <thead>
            <tr className="text-center roboto-medium">
              <th colSpan="3" id="fondolote">
                CONSULTE SU REPORTE
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <td>
                
                <button
                            type="button"
                            title="Mostra Reporte de Cuadre de Caja por Banca"
                            onClick={() => {
                              enviarmensaje("hh");
                            }}
                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-1.5 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                            <svg class="w-[18px] h-[18px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M8 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1h2a2 2 0 0 1 2 2v15a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h2Zm6 1h-4v2H9a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2h-1V4Zm-6 8a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm1 3a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Z" clip-rule="evenodd"/>
</svg>

                          </button>
              </td>
              <td className="flex flex-row">
                <span className="text-lg italic font-medium text-red-700">Cuadre de Caja por Banca</span>&nbsp;
                <span id="iconw" title="Se tomara la Fecha (Desde) para el Dia" className="cursor-pointer">
                <svg   class="w-6 h-6 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.008-3.018a1.502 1.502 0 0 1 2.522 1.159v.024a1.44 1.44 0 0 1-1.493 1.418 1 1 0 0 0-1.037.999V14a1 1 0 1 0 2 0v-.539a3.44 3.44 0 0 0 2.529-3.256 3.502 3.502 0 0 0-7-.255 1 1 0 0 0 2 .076c.014-.398.187-.774.48-1.044Zm.982 7.026a1 1 0 1 0 0 2H12a1 1 0 1 0 0-2h-.01Z" clip-rule="evenodd"/></svg>
                 
                </span>
              </td>
            </tr>
            <tr className="text-center">
              <td>
                
                <button
                            type="button"
                            title="Reporte de Premios"
                            onClick={() => {
                              enviarmensaje("r");
                            }}
                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-1.5 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                            <svg class="w-[18px] h-[18px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M8 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1h2a2 2 0 0 1 2 2v15a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h2Zm6 1h-4v2H9a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2h-1V4Zm-6 8a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm1 3a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Z" clip-rule="evenodd"/>
</svg>

                          </button>
              </td>
              <td className="flex flex-row">
                <span className="text-lg italic font-medium text-red-700">Resultados Agencia</span>&nbsp;
                <span id="iconw" title="Se tomara la Fecha (Desde) para el Dia" className="cursor-pointer">
                <svg   class="w-6 h-6 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.008-3.018a1.502 1.502 0 0 1 2.522 1.159v.024a1.44 1.44 0 0 1-1.493 1.418 1 1 0 0 0-1.037.999V14a1 1 0 1 0 2 0v-.539a3.44 3.44 0 0 0 2.529-3.256 3.502 3.502 0 0 0-7-.255 1 1 0 0 0 2 .076c.014-.398.187-.774.48-1.044Zm.982 7.026a1 1 0 1 0 0 2H12a1 1 0 1 0 0-2h-.01Z" clip-rule="evenodd"/></svg>
                 
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
    
    
  
  }
  if (usernivel == "caja") {
    return (
      <div>
        <table className="table table-dark table-sm">
          <thead>
            <tr className="text-center roboto-medium">
              <th colSpan="3" id="fondolote">
                CONSULTE SU REPORTE
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <td>
                
                <button
                            type="button"
                            onClick={() => {
                                enviarmensaje("h");
                              }}
                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-1.5 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                            <svg class="w-[18px] h-[18px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M8 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1h2a2 2 0 0 1 2 2v15a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h2Zm6 1h-4v2H9a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2h-1V4Zm-6 8a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm1 3a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Z" clip-rule="evenodd"/>
</svg>

                          </button>
              </td>
              <td className="flex flex-row">
                <span className="text-lg italic font-medium text-red-700">Cuadre de Caja</span>&nbsp;
                <span id="iconw" title="Se tomara la Fecha (Desde) para el Dia" className="cursor-pointer">
                <svg   class="w-6 h-6 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.008-3.018a1.502 1.502 0 0 1 2.522 1.159v.024a1.44 1.44 0 0 1-1.493 1.418 1 1 0 0 0-1.037.999V14a1 1 0 1 0 2 0v-.539a3.44 3.44 0 0 0 2.529-3.256 3.502 3.502 0 0 0-7-.255 1 1 0 0 0 2 .076c.014-.398.187-.774.48-1.044Zm.982 7.026a1 1 0 1 0 0 2H12a1 1 0 1 0 0-2h-.01Z" clip-rule="evenodd"/></svg>
                 
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
};

export default Listagenerador;
