import React, { useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";

const Listausers = ({ myuser, activarchequeo, addvalor, addtipo }) => {
  const [listalo, setListalo] = useState([]);
  const [editid, setEditid] = useState(0);

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);

  console.log(myuser);
  var datali = new Object();

  const mostraredit = (id) => {
    setEditid(id);

    // for (let i = 0; i < listalo.length; i++) {
    //   if (listalo[i].id == id) {
    //     console.log(listalo[i].username);
    //     document.getElementById("musuario").value = listalo[i].username;
    //     document.getElementById("mnombres").value = listalo[i].name;
    //     document.getElementById("mtelefono").value = listalo[i].telefono;
    //     document.getElementById("mdireccion").value = listalo[i].direccion;
    //     document.getElementById("memail").value = listalo[i].correo;
    //   }
    // }
    console.log(id);
    setModal2(true);
    //$("#example3").click();
  };

  const obtenerusuario = (id, tipo) => {
    for (let i = 0; i < listalo.length; i++) {
      if (listalo[i].id == id) {
        if (tipo == "usuario") {
          return listalo[i].username;
        }
        if (tipo == "nombres") {
          return listalo[i].name;
        }
        if (tipo == "telefono") {
          return listalo[i].telefono;
        }
        if (tipo == "direccion") {
          return listalo[i].direccion;
        }
        if (tipo == "correo") {
          return listalo[i].correo;
        }
      }
    }
  };

  const mostrar3 = () => {
    //$("#cerrarmodal3").click();
  };

  const limpiarcampos = () => {
    document.getElementById("musuario").value = "";
    document.getElementById("mnombres").value = "";
    document.getElementById("mclave1").value = "";
    document.getElementById("mclave2").value = "";
    document.getElementById("memail").value = "";
    document.getElementById("mdireccion").value = "";
    document.getElementById("mtelefono").value = "";
  };

  const limpiarcampos2 = () => {
    document.getElementById("campousuario").value = "";
    document.getElementById("camponombres").value = "";
    document.getElementById("campoclave1").value = "";
    document.getElementById("campoclave2").value = "";
    document.getElementById("campoemail").value = "";
    document.getElementById("campodireccion").value = "";
    document.getElementById("campotelefono").value = "";
  };

  const getusers = () => {
    Axios.get(`${process.env.REACT_APP_API}/listausers/`).then((response) => {
      datali = response.data;

      setListalo(response.data);
    });
  };

  const guardarusuario = () => {
    var wusuario = document.getElementById("campousuario").value;
    var wnombres = document.getElementById("camponombres").value;
    var wclave1 = document.getElementById("campoclave1").value;
    var wclave2 = document.getElementById("campoclave2").value;
    var wtelefono = document.getElementById("campotelefono").value;
    var wemail = document.getElementById("campoemail").value;
    var wdireccion = document.getElementById("campodireccion").value;

    if (wclave1 != wclave2) {
      document.getElementById("erroru").innerHTML = "Las Claves no Coinciden";
      document.getElementById("erroru").className = "text-red-500 text-[16px]";
    } else {
      if (
        wclave1 == "" ||
        wclave2 == "" ||
        wnombres == "" ||
        wtelefono == "" ||
        wusuario == "" ||
        wdireccion == "" ||
        wemail == ""
      ) {
        document.getElementById("erroru").innerHTML = "Hay Campos Vacios";
        document.getElementById("erroru").className =
          "text-red-500 text-[16px]";
      } else {
        const data = {
          wusuario: wusuario,
          wnombres: wnombres,
          wclave1: wclave1,
          wclave2: wclave2,
          wtelefono: wtelefono,
          wemail: wemail,
          wdireccion: wdireccion,
        };
        //enviar datos
        fetch(`${process.env.REACT_APP_API}/guardarusuario`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((result) => {
            if (result) {
              if (result.message == "existe") {
                document.getElementById("erroru").innerHTML =
                  "El Usuario (" +
                  document.getElementById("campousuario").value +
                  ") Ya existe, intente con otro";
                document.getElementById("erroru").className =
                  "text-red-500 text-[16px]";
              }
              if (result.message == "guardado") {
                document.getElementById("erroru").innerHTML =
                  "Datos Guardados con Exito";
                document.getElementById("erroru").className =
                  "text-green-500 text-[16px]";

                setTimeout(() => {
                  //mostrar();
                  //addmensaje("n");
                  document.getElementById("erroru").innerHTML = "";
                  limpiarcampos2();
                  getusers();
                  setModal(false);
                }, 2000);
              }
              if (result.message == "error") {
                document.getElementById("erroru").innerHTML = "";
                document.getElementById("erroru").innerHTML =
                  "ERROR DE CONEXION";
              }

              //ACTUAL USUARIOS
            } else {
              console.log("ERROR DE CONEXION");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    //fin de envio
  };

  const confirmardeluser = (id) => {
    Swal.fire({
      title: "Eliminar Usuario/Agencia",
      text: "Deseas Eliminar este Usuario/Agencia! Advertencia: Se eliminaran todas las cajas correspondientes",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Eliminando Usuario/Agencia",
          text: "Eliminacion Exitosa",
          icon: "success",
        });
        deletemuser(id);
      }
    });
  };

  const modificarusuario = () => {
    var wusuario = document.getElementById("musuario").value;
    var wnombres = document.getElementById("mnombres").value;

    var wclave1 = document.getElementById("mclave1").value;
    var wclave2 = document.getElementById("mclave2").value;
    var wtelefono = document.getElementById("mtelefono").value;
    var wemail = document.getElementById("memail").value;
    var wdireccion = document.getElementById("mdireccion").value;
    var wid = editid;

    if (wclave1 != wclave2) {
      document.getElementById("errore").innerHTML = "Las Claves no Coinciden";
      document.getElementById("errore").className = "text-red-500 text-[16px]";
    } else {
      if (
        wnombres == "" ||
        wtelefono == "" ||
        wdireccion == "" ||
        wemail == ""
      ) {
        document.getElementById("errore").innerHTML = "Hay Campos Vacios";
        document.getElementById("errore").className =
          "text-red-500 text-[16px]";
      } else {
        const data = {
          wusuario: wusuario,
          wnombres: wnombres,
          wclave1: wclave1,
          wclave2: wclave2,
          wtelefono: wtelefono,
          wemail: wemail,
          wdireccion: wdireccion,
          wid: editid,
        };
        console.log(wclave1);
        //enviar datos
        fetch(`${process.env.REACT_APP_API}/modificarusuario`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((result) => {
            if (result) {
              if (result.message == "guardado") {
                document.getElementById("errore").innerHTML =
                  "Modificacion Realizada con Exito";
                document.getElementById("errore").className =
                  "text-green-500 text-[16px]";
                setTimeout(() => {
                  mostrar3();
                  getusers();

                  limpiarcampos();
                  setModal2(false);
                }, 2000);
              }
              if (result.message == "error") {
                document.getElementById("errore").innerHTML =
                  "ERROR DE CONEXION";
                document.getElementById("errore").className =
                  "text-red-500 text-[16px]";
              }

              //ACTUAL USUARIOS
            } else {
              console.log("ERROR DE CONEXION");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    //fin de envio
  };

  const deletemuser = (id) => {
    console.log("EJECUTANDOSE DELETE" + id);

    const data = {
      wid: id,
    };
    //enviar datos
    fetch(`${process.env.REACT_APP_API}/delusers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          getusers();
          addtipo("n");
        } else {
          console.log("ERROR DE CONEXION");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //fin de envio
  };

  useEffect(() => {
    getusers();
  }, [activarchequeo]);

  return (
    <>
      <div>
        <button
          type="button"
          onClick={(e) => {
            setModal(true);
          }}
          class="text-white mt-2 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-4 py-1 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
        >
          <svg
            class="w-[30px] h-[30px] text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M9 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H7Zm8-1a1 1 0 0 1 1-1h1v-1a1 1 0 1 1 2 0v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 0 1-1-1Z"
              clip-rule="evenodd"
            />
          </svg>
          Nuevo Usuario
        </button>

        <div className="flex flex-row">
          <div class="relative pt-2 w-full shadow-md sm:rounded-lg flex flex-col flex-wrap">
            <div
              id="fondolote"
              className="pl-2 font-semibold text-blue-950 bg-slate-400 w-full text-sm"
            >
              REGISTRO DE USUARIOS/AGENCIA
            </div>
            <div className="">
              <table class="w-[100%] table1 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <tbody>
                  <tr className="w-full">
                    <th scope="col" class="px-6 py-1">
                      Acciones
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Usuario
                    </th>
                    <th scope="col" class="px-6 py-1">
                      Nombres
                    </th>
                  </tr>

                  {listalo.map((val, key) => {
                    return (
                      <tr key={val.id} className="text-center">
                        <td>
                          <button
                            type="button"
                            onClick={() => {
                              confirmardeluser(val.id);
                            }}
                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-1.5 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                            <svg
                              class="w-[18px] h-[18px] text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button>

                          <button
                            type="button"
                            onClick={() => {
                              mostraredit(val.id);
                            }}
                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-1.5 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                            <svg
                              class="w-[18px] h-[18px] text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M5 8a4 4 0 1 1 7.796 1.263l-2.533 2.534A4 4 0 0 1 5 8Zm4.06 5H7a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h2.172a2.999 2.999 0 0 1-.114-1.588l.674-3.372a3 3 0 0 1 .82-1.533L9.06 13Zm9.032-5a2.907 2.907 0 0 0-2.056.852L9.967 14.92a1 1 0 0 0-.273.51l-.675 3.373a1 1 0 0 0 1.177 1.177l3.372-.675a1 1 0 0 0 .511-.273l6.07-6.07a2.91 2.91 0 0 0-.944-4.742A2.907 2.907 0 0 0 18.092 8Z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button>

                          <button
                            type="button"
                            onClick={() => {
                              addvalor(val.id);

                              addtipo("c");
                            }}
                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-1.5 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                            <svg
                              class="w-[18px] h-[18px] text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M7.58209 8.96025 9.8136 11.1917l-1.61782 1.6178c-1.08305-.1811-2.23623.1454-3.07364.9828-1.1208 1.1208-1.32697 2.8069-.62368 4.1363.14842.2806.42122.474.73509.5213.06726.0101.1347.0133.20136.0098-.00351.0666-.00036.1341.00977.2013.04724.3139.24069.5867.52125.7351 1.32944.7033 3.01552.4971 4.13627-.6237.8375-.8374 1.1639-1.9906.9829-3.0736l4.8107-4.8108c1.0831.1811 2.2363-.1454 3.0737-.9828 1.1208-1.1208 1.3269-2.80688.6237-4.13632-.1485-.28056-.4213-.474-.7351-.52125-.0673-.01012-.1347-.01327-.2014-.00977.0035-.06666.0004-.13409-.0098-.20136-.0472-.31386-.2406-.58666-.5212-.73508-1.3294-.70329-3.0155-.49713-4.1363.62367-.8374.83741-1.1639 1.9906-.9828 3.07365l-1.7788 1.77875-2.23152-2.23148-1.41419 1.41424Zm1.31056-3.1394c-.04235-.32684-.24303-.61183-.53647-.76186l-1.98183-1.0133c-.38619-.19746-.85564-.12345-1.16234.18326l-.86321.8632c-.3067.3067-.38072.77616-.18326 1.16235l1.0133 1.98182c.15004.29345.43503.49412.76187.53647l1.1127.14418c.3076.03985.61628-.06528.8356-.28461l.86321-.8632c.21932-.21932.32446-.52801.2846-.83561l-.14417-1.1127ZM19.4448 16.4052l-3.1186-3.1187c-.7811-.781-2.0474-.781-2.8285 0l-.1719.172c-.7811.781-.7811 2.0474 0 2.8284l3.1186 3.1187c.7811.781 2.0474.781 2.8285 0l.1719-.172c.7811-.781.7811-2.0474 0-2.8284Z"
                              />
                            </svg>
                          </button>

                          <button
                            type="button"
                            onClick={() => {
                              addvalor(val.id);

                              addtipo("h");
                            }}
                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-1.5 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                            <svg
                              class="w-[18px] h-[18px] text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H4Zm0 8v6h7v-6H4Zm16 6h-7v-6h7v6Z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button>

                          <button className="btn btn-primary">
                            <i
                              class="fa-solid fa-pen-to-square"
                              title="Editar este Registro"
                              onClick={() => {
                                mostraredit(val.id);
                              }}
                            ></i>
                          </button>

                          <button className="btn btn-primary">
                            <i
                              class="fa-solid fa-wrench"
                              title="Configurar"
                              onClick={() => {
                                addvalor(val.id);

                                addtipo("c");
                              }}
                            ></i>
                          </button>

                          
                        </td>
                        <td>
                          <span id={"loter" + val.id} className=" text-[16px]">
                            {" "}
                            {val.username}
                          </span>
                        </td>
                        <td className=" text-[16px]">{val.name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {modal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-12 bg-black rounded-t-lg place-content-center flex flex-grow text-white text-md font-semibold">
                  <span className="mt-2 pl-2">Registro de Usuario</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1">
                    <div className="flex flex-row flex-wrap">
                      <div className="grid grid-cols-2 gap-2 text-blue-950 text-md font-bold">
                        <div className="p-2">
                          <span className="text-sm mt-1">Usuario:</span>
                          <input
                            type="text"
                            id="campousuario"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            defaultValue=""
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Nombres:</span>
                          <input
                            type="text"
                            id="camponombres"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            defaultValue=""
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Clave:</span>
                          <input
                            type="password"
                            id="campoclave1"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            defaultValue=""
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Confirmar Clave:</span>
                          <input
                            type="password"
                            id="campoclave2"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            defaultValue=""
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Telefono:</span>
                          <input
                            type="text"
                            id="campotelefono"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            defaultValue=""
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Email:</span>
                          <input
                            type="text"
                            id="campoemail"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            defaultValue=""
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Direccion:</span>
                          <input
                            type="text"
                            id="campodireccion"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            defaultValue=""
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row place-content-end mt-2 mr-[-10px]">
                      <button
                        type="button"
                        id="opcionb12"
                        onClick={guardarusuario}
                        class="text-white mr-4 pl-2 w-18 h-8 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
                      >
                        <svg
                          class="w-[18px] h-[18px] text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        &nbsp; Guardar Usuario
                      </button>
                    </div>
                  </div>
                </div>
                <div className="h-14 bg-black rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono items-center">
                  <span
                    id="erroru"
                    className="text-green-500 text-[16px]"
                  ></span>
                  <button
                    type="button"
                    onClick={() => {
                      setModal(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {modal2 ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-12 bg-black rounded-t-lg place-content-center flex flex-grow text-white text-md font-semibold">
                  <span className="mt-2 pl-2">
                    Modificar Registro de Usuario
                  </span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1">
                    <div className="flex flex-row flex-wrap">
                      <div className="grid grid-cols-2 gap-2 text-blue-950 text-md font-bold">
                        <div className="p-2">
                          <span className="text-sm mt-1">Usuario:</span>
                          <input
                            type="text"
                            id="musuario"
                            disabled
                            defaultValue={obtenerusuario(editid,"usuario")}
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Nombres:</span>
                          <input
                            type="text"
                            id="mnombres"
                            defaultValue={obtenerusuario(editid,"nombres")}
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Clave:</span>
                          <input
                            type="password"
                            id="mclave1"
                           
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            defaultValue=""
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Confirmar Clave:</span>
                          <input
                            type="password"
                            id="mclave2"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            defaultValue=""
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Telefono:</span>
                          <input
                            type="text"
                            id="mtelefono"
                            defaultValue={obtenerusuario(editid,"telefono")}
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Email:</span>
                          <input
                            type="text"
                            id="memail"
                            defaultValue={obtenerusuario(editid,"correo")}
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                          />
                        </div>

                        <div className="p-2">
                          <span className="text-sm mt-1">Direccion:</span>
                          <input
                            type="text"
                            id="mdireccion"
                            defaultValue={obtenerusuario(editid,"direccion")}
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[100%] h-8 pl-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row place-content-end mt-2 mr-[-10px]">
                      <button
                        type="button"
                        id="opcionb12"
                        onClick={modificarusuario}
                        class="text-white mr-4 pl-2 w-18 h-8 bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-2 py-0 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
                      >
                        <svg
                          class="w-[18px] h-[18px] text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        &nbsp; Modificar Usuario
                      </button>
                    </div>
                  </div>
                </div>
                <div className="h-14 bg-black rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono items-center">
                  <span
                    id="errore"
                    className="text-green-500 text-[16px]"
                  ></span>
                  <button
                    type="button"
                    onClick={() => {
                      setModal2(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Listausers;
